<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 20%; min-width: 500px">
                <div class="modal-header">
                    <h3 class="form-header">Prescriber Details</h3>
                    <button @click="closeModal()" class="red-close-button">
                        <i class="pi pi-times" style='padding: 0'></i>
                    </button>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px; padding: 0">
                                <div style="text-align: left">
                                    <p style="font-size: 1em;"><b>First Name:</b>  {{ doctor?.first_name }}</p>
                                    <p style="font-size: 1em;"><b>Last Name: </b> {{ doctor?.last_name }}</p>
                                    <p style="font-size: 1em;"><b>Phone:</b>  {{ doctor?.phone }}</p>
                                    <p style="font-size: 1em;"><b>Email:</b>  {{ doctor?.email }}</p>
                                    <p style="font-size: 1em;"><b>Address:</b>  {{ doctor?.address }}</p>
                                    
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>

                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;" >
                    <slot name="footer" style="display: flex;">
                        
                        <button class="red-button" @click="closeModal()">
                            Close
                        </button>
                    </slot>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    props: [ 'doctor'],
    emits:['close'],
    components: {
        
    },
    data() {
        return {
        }
    },
    methods: {
        closeModal() {
            console.log('hitting this closemodal function');
            this.$emit('close', true);
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.merge-patient-card{
    width: 200px;
    background-color: #f3f4f4;
    margin-right: 10px;
    p{
        font-weight: bold;
    }
}

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>
