<template>
    <div>
        <div class="panel">

            <div class="panel-content-container" style="box-shadow: none !important; padding: 25px" v-if="$store.state.user.role.permissions.includes('can_do_resident') && $store.state.user.role.permissions.includes('can_view_resident')">
                <div v-if="!loadingPatient">
                    <div>
                        <div class="patient-form">
                            <div class="patient-form-navigation">

                                <h3 style="margin: 0; text-align: left; color: #0870DC; font-size: 18px">{{ personalInformation.first_name }} {{ personalInformation.last_name }}</h3>
                                <div class="patient-form-image-container">
                                    <label v-if="previewImage" for="patient-image-upload" :v-model="previewImage" class="uploading-image-container">
                                        <img :src="previewImage" class="uploading-image" />
                                        <i class="pi pi-pencil edit-icon"></i>
                                        <p class="edit-icon-text">
                                            Edit Image
                                        </p>
                                    </label>
                                    
                                    <div
                                        v-if="!previewImage"
                                        style="height: 250px; display: flex; border-radius: 8px; flex-direction: column; align-items: center; justify-content: space-around; background-color: #F3F4F4;"
                                    >
                                        <i class="pi pi-user" style="font-size: 5em !important; color: #D8DCDC"></i>
                                        <p>Resident photo to display here</p>
                                    </div>
                                    <label
                                        v-if="!previewImage"
                                        for="patient-image-upload"
                                        class="image-file-upload"
                                        style="width: 100%; margin: 0 auto; font-weight: bold;  padding: 5px 10px; border-radius: 4px; cursor: pointer; color: white; background-color: #0870DC; margin-top: 0" 
                                        :v-model="previewImage"
                                    >
                                        Upload Resident Image
                                    </label>
                                    <input type="file" id="patient-image-upload" accept="image/jpeg" @change=uploadImage style=" display: none; ">
                                    <button
                                        v-if="previewImage"
                                        class="image-file-upload green-button"
                                        style="width: 220.92px; margin: 0 auto; padding: 5px 10px; border-radius: 4px; cursor: pointer; color: white; background-color: #0870DC; margin-top: 0"
                                        @click="submitImage()"
                                    >
                                        Save Image
                                    </button>
                                </div>
                                <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px; align-items: center">
                                    <Dropdown v-model="selectedStatus" :options="statusOptions" optionLabel="label" optionValue="value"
                                    :placeholder="'Set Status'" />
                                    <button class="button-spaced" style="background-color: #00AD50" @click="saveStatus()">Save Status</button>
                                </div>
                                <div class="patient-form-navigation-items" style="margin-top: 15px;">
                                    <div v-for="(item, index) in navigationItems" :key="index" class="patient-form-navigation-item" :class="selectedNavigation == item.link ? 'selected-patient-form-navigation-item':''">
                                        
                                        <!-- :class="[item.completed == true ? 'patient-form-navigation-label-completed' : '']" -->
                                        <div class="patient-form-navigation-label" :class="selectedNavigation == item.link ? 'selected-patient-form-navigation-label':''"
                                            @click="selectedNavigation = item.link">
                                            <p style="text-align: left">{{ item.name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="patient-form-content">
                                <div class="patient-form-content-section" v-if="selectedNavigation == 'personal_information'">
                                    <div class="personal-info-form-content">
                                        <h3 style="margin: 0;">Personal Information</h3>
                                        <div class="form-fields">
                                            <div class="form-field text-field">
                                                <label for="first_name">First Name <span style="color: red">*</span></label>
                                                <input type="text" name="first_name" :disabled="$store.state.user.integration_id" v-model="personalInformation.first_name">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Last Name <span style="color: red">*</span></label>
                                                <input type="text" name="first_name" :disabled="$store.state.user.integration_id" v-model="personalInformation.last_name">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Preferred Name</label>
                                                <input type="text" name="first_name" :disabled="$store.state.user.integration_id" v-model="personalInformation.preferred_name">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Sex <span style="color: red">*</span></label>
                                                <input type="text" name="first_name" :disabled="$store.state.user.integration_id" v-model="personalInformation.sex">
                                            </div>
                                            <div class="form-field date-field">
                                                    <!-- - {{ personalInformation.dob }} -->
                                                <label for="first_name">Date of Birth <span style="color: red">*</span></label>
                                                <input type="date" name="first_name" :disabled="$store.state.user.integration_id" v-model="personalInformation.dob">
                                                <!-- <InputMask style="background-color: #f3f4f4" mask="99/99/9999"
                                                        v-model="editDob" placeholder="01/01/1920"/> -->
                                            </div>
                                            <div class="form-field text-field" v-for="(item, index) in personal_regulatory_numbers" 
                                                :key="index">
                                                <label for="first_name">{{ item.regulatory_type }}</label>
                                                <input type="text" name="first_name" :placeholder="item.data" v-model="item.data">
                                            </div>
                                        </div>
                                        <div style="display: flex;">
                                            <button
                                                class="button green-button" style="margin-top: 1em; font-weight: bold"
                                                @click="validateDOB()"
                                            >
                                                Save Personal Information
                                            </button>
                                        </div>
                                        <p style="text-align: left; font-size: 1em; color: red;" v-if="errorDOB">
                                            DOB is incorrectly entered, DOB must be DD / MM / YYYY e.g. 10/12/1950.
                                        </p>
                                        <div>
                                            <div class="field-checkbox" style="display: flex; margin-top: 2em;">
                                                <Checkbox :binary="true" v-model="personalInformation.aslr_site_consent" /> 
                                                <label style="font-size: 1em; padding-left: 10px; font-weight: bold" for="binary">
                                                    Resident consents to healthcare provider view access of their Active Script List (if they are registered).
                                                </label>
                                            </div>
                                        </div>
                                        <div class="personal-info-form-content" style="margin-bottom: 1em;"  v-if="$store.state.user.eprescribing_active">
                                            <h3>IHI Number <span style="font-size: 0.9em; font-weight: normal; color: green" v-if="!ihiDetails">[Please wait while we retrieve the IHI Number...]</span></h3>
                                            <p style="margin: 0; font-size: 0.9em; text-align: left">
                                                Fill in the resident's name, sex, DOB, Medicare/DVA Number to retrieve IHI Number.
                                            </p>
                                            <div class="form-fields">
                                                
                                                <div class="form-field text-field">
                                                    <label for="first_name">IHI Number</label>
                                                    <!-- <input style="background-color: #f3f4f4"  v-model="ihiDetails.number" placeholder="9999 9999 9999 9999"/> -->
                                                    <InputMask style="background-color: #f3f4f4" mask="9999 9999 9999 9999"
                                                        v-model="ihiDetails.number" placeholder="9999 9999 9999 9999"/>
                                                </div>
                                                <div class="form-field text-field" >
                                                    <label for="first_name">IHI Number Status</label>
                                                    <inputText class="grey-input-color" type="text" name="first_name" :value="ihiDetails.ihi_status" />
                                                </div>
                                                <div class="form-field text-field">
                                                    <label for="first_name">IHI Record Status</label>
                                                    <inputText class="grey-input-color" type="text" name="first_name" :value="ihiDetails.ihi_record_status" />
                                                </div>
                                            </div>
                                            <div style="display: flex; align-items: center">
                                                <button
                                                    class="button green-button" style="font-weight: bold; margin-right: 10px;"
                                                    @click="validateIHINumber(true)"
                                                >
                                                    Validate IHI Number
                                                </button>

                                                <button @click="displayIHIHistoryModal = true" class="button outline-button" >View IHI Number History</button>
                                            </div>
                                        </div>

                                        <!-- Prescribers and pharmacists should not be allowed to change resident locations -->
                                        <div class="personal-info-form-content">
                                            <h3>Location</h3>
                                            <p style="text-align: left; font-size: 1em;"
                                                v-if="patient.physical_location && patient.physical_location.location">Current
                                                Location: {{ patient.physical_location.location.facility ? patient.physical_location.location.facility.name + ' -' : '' }} <b>{{ patient.physical_location.location.name }}</b></p>
                                            <div class="form-fields">
                                                <div class="form-field wide-text-field" style="margin-top: 0;" v-if="facilities && facilities.length">
                                                    <label for="first_name">Facility</label>
                                                    <Dropdown v-model="selectedFacility" :options="facilities" optionLabel="label"
                                                        @change="retrieveSelectedFacilityLocations()"
                                                        :placeholder="selectedFacility ? selectedFacility.name : 'Select facility'"
                                                        :disabled="($store.state.user.type === 'prescriber' || $store.state.user.type === 'pharmacist') ? true : false" />
                                                </div>
                                                <div class="form-field wide-text-field" v-if="nodes && nodes.length">
                                                    <label for="first_name" >Location</label>
                                                    <div v-if="loadingLocations">
                                                        <i class="pi pi-spin pi-spinner"
                                                            style="font-size: 2rem !important;margin-top: 10px;"></i>
                                                    </div>
                                                    <TreeSelect v-model="selectedNodeKey" :options="nodes"
                                                        ariaLabelledBy="name" placeholder="Select Item" />

                                                </div>
                                            </div>
                                        </div>
                                        <div style="display: flex;">
                                            <button v-if="$store.state.user.type === 'nurse'" class="button complete-button" style="margin-top: 1em;font-weight: bold"
                                                @click="confirmFacilityLocation()">
                                                Update Location
                                            </button>
                                            <button v-else disabled style="margin-top: 1em;font-weight: bold;cursor: not-allowed;">
                                                Update Location
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="patient-form-content-section" v-if="selectedNavigation == 'contact_details'">
                                    <div class="contact-info-form-content">
                                        <h3 style="margin: 0;">Contact Details</h3>
                                        <div class="form-fields">
                                            <div class="form-field text-field">
                                                <label for="first_name">
                                                    Address {{patient.address ? ': '+patient.address.street_address+', '+patient.address.suburb+', '+patient.address.postcode : ''}}
                                                </label>
                                                <div class="form-field text-field">
                                                    <vue-google-autocomplete id="contactmap" classname="form-control"
                                                        :placeholder="patient.address? patient.address.street_address :''" v-on:placechanged="getPersonalAddressData"
                                                        :country="$store.state.country">
                                                    </vue-google-autocomplete>
                                                </div>
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Phone</label>
                                                 <input type="text" name="first_name" v-model="untitled.phone">
                                                <!-- <InputMask  v-model="untitled.phone" unmask=unmask style="background-color: #f3f4f4" mask="(99) 9999 9999" 
                                         placeholder="(03) 9999 9999"/> -->
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Email</label>
                                                <input type="text" name="first_name" v-model="untitled.email">
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <button
                                            class="button green-button" style="margin-top: 1em;"
                                            @click="editContactDetails()"
                                        >
                                            Update Contact Details
                                        </button>
                                    </div>
                                    <div class="contact-info-form-content">
                                        <h3>Next of Kin Details</h3>
                                        <div class="form-fields">
                                            <div class="form-field text-field">
                                                <label for="first_name">Next of Kin - First name</label>
                                                <input type="text" name="first_name" v-model="kin.first_name">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Next of Kin - Last name</label>
                                                <input type="text" name="first_name" v-model="kin.last_name">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Next of Kin - Relationship</label>
                                                <input type="text" name="first_name" v-model="kin.relationship">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Next of Kin - Phone</label>
                                                <input type="text" name="first_name" v-model="kin.phone">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Next of Kin - Email</label>
                                                <input type="text" name="first_name" v-model="kin.email">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if='validationError'
                                        style="display: flex; justify-content: center; flex-direction: column;">
                                        <p style="font-size: 1em; text-align: left; color:red" v-if='validationError'>{{ validationErrorMessage }}</p>
                                    </div>
                                    <div style="display: flex">
                                        <button class="button green-button" style="margin-top: 1em;" @click="editNextOfKin()">Update
                                            Next of Kin</button>
                                    </div>
                                    <div class="contact-info-form-content">
                                        <h3>Entitlement & Regulatory Numbers</h3>
                                        <div class="form-fields">
                                            <div class="form-field text-field" v-for="(item, index) in grouped_regulatory_numbers" 
                                                :key="index">
                                                <label for="first_name">{{ item.regulatory_type }}</label>
                                                <input type="text" name="first_name" :placeholder="item.data" v-model="item.data">
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <button class="button green-button" style="margin-top: 1em;"
                                            @click="editEntitlementNumbers()">Update Entitlement & Regulatory Numbers</button>
                                    </div>
                                </div>
                                <div class="patient-form-content-section" v-if="selectedNavigation == 'prescriber_details'">

                                    <div class="prescriber-info-form-content" style="display: grid;" >
                                        <div style="display: flex;gap: 20px;flex-direction: column;">
                                            <!-- left -->
                                            <div>
                                                <h3 style="margin: 0;">Primary Prescriber</h3>
                                                <p v-if="selectedPrescriber" style="font-size: 1em; text-align: left;">
                                                    Current Primary Prescriber: <b>{{ selectedPrescriber.name }}</b></p>
                                                <div v-if="prescribers && prescribers.length" style="display: flex; flex-direction: column">
                                                    <p v-if="!selectedPrescriber" style="font-size: 1em; text-align: left; margin: 0;">
                                                    Select prescriber to link to resident:</p>
                                                    <p v-else style="font-size: 1em; text-align: left; margin: 0;">Change primary prescriber:</p>
                                                    <div style="display:flex;gap:20px;align-items: center;">
                                                        <Dropdown v-model="selectedPrescriber" :options="prescribers" optionLabel="name"
                                                        placeholder="Select Prescriber" style="width: fit-content; margin-top: 10px;" />
                                                        <div v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">
                                                            <button style="margin-top: 10px;" class="button green-button"
                                                            @click="submitPrescriber(false)">Update Primary GP</button>
                                                        </div>
                                                    </div>

                                                </div>
                                               
                                            </div>
                                            <!-- right -->
                                            <div>
                                                <h3 style="margin: 0;">Additional Prescribers</h3>
                                                <div>
                                                    <div v-if="prescribers && prescribers.length" style="display: flex; flex-direction: column">
                                                        <div style="display:flex;gap:20px;align-items: center;">
                                                            <Dropdown v-model="additional_prescriber" :options="additionalPrescribers" optionLabel="name"
                                                            placeholder="Select Prescriber" style="width: fit-content; margin-top: 10px;" />
                                                            <div v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">
                                                                <button style="margin-top: 10px;" class="button green-button"
                                                                @click="submitPrescriber(true)">Add additional GP</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <p style="text-align: left; font-size: 1em; font-weight: bold">Currently linked prescribers:</p>
                                                    <div style="overflow: scroll; height: 170px;width: 400px; border: 1px solid black; padding: 0 10px; border-radius: 8px;">
                                                    <div v-if="facilitiesPrescribers?.length > 0">
                                                        <table>
                                                        <tr v-for="(gp, index) in facilitiesPrescribers" :key="index">
                                                            <!-- name -->
                                                            <td style="width: 300px;">{{gp.personal_information.first_name}} {{gp.personal_information.last_name}}</td>
                                                            <!-- primary presiber tag -->
                                                            <td style="display:flex; justify-content: right;">
                                                                <div> <button class="button red-button" @click="unlinkPrescriber(gp)">Unlink</button> </div> 
                                                                <!-- <div v-if="(!(patient.prescribers.map(item => item.uuid)).includes(gp.uuid)) && (patient.primary_prescriber.uuid != gp.uuid)"><button class="button green-button" @click="linkPrescriber(gp)">Link</button> </div> -->
                                                            </td> 
                                                        </tr>
                                                        </table>
                                                    </div>
                                                    <div v-else>
                                                        <p>No additional prescribers linked to this patient.</p>
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>


                                    <div class="prescriber-info-form-content" style="display: grid; margin-top: 1em;">
                                        <h3>Pharmacy Details</h3>
                                        <p v-if="selectedPharmacy" style="font-size: 1em; text-align: left;">Current Pharmacy:
                                            <b>{{ selectedPharmacy.name }}</b></p>
                                        <div  v-if="pharmacies && pharmacies.length" style="display: flex; flex-direction: column">
                                            <p v-if="!selectedPharmacy" style="font-size: 1em; text-align: left; margin: 0;">Select
                                            pharmacy to link to resident:</p>
                                            <p v-else style="font-size: 1em; text-align: left; margin: 0;">Change pharmacy:</p>
                                            <div style="display:flex;gap:20px;align-items: center;">
                                                <Dropdown  v-model="selectedPharmacy" :options="pharmacies" optionLabel="name"
                                                placeholder="Select Pharmacy" style="width: fit-content;" />
                                                <button class="button green-button" @click="submitPharmacy()" 
                                                 v-if="$store.state.userType !='prescriber' && $store.state.userType !='pharmacy' ">Update Pharmacy</button>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div class="prescriber-info-form-content" v-for="(prescriber, pi) in untitled.prescribers"
                                        :key="pi">
                                        <h3>Prescriber Details (not Primary GP)</h3>
                                        <div style="display: flex; align-items: center;">
                                            <div class="text-search-container search-container text-field"
                                                style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px; position: relative;">
                                                <label for="first_name" style="text-align: left; padding-left: 10px">Search by
                                                    Prescriber Number</label>
                                                <input v-model="nonGP" v-on:keyup="gpSearch(nonGP)"
                                                    placeholder="Search Prescriber No."
                                                    style="position: relative; border: 3px solid rgb(202, 202, 202); background-color: white;" />
                                                <div class="drug-search-result-container" v-if="nonGP"
                                                    style="position: absolute; margin-top: 55px; background-color: white; width: 20em; border: 2px solid #f6f6f6">
                                                    <p class="drug-search-result"
                                                        style="text-align: left; padding-left: 20px; cursor:pointer"
                                                        v-for="(item, index) in prescriberResults" :key="index"
                                                        @click="selectPrescriber(item)">{{ item.prescriber_number }}</p>
                                                </div>
                                            </div>
                                            <p style="text-align: left; margin-left: 20px; cursor:pointer; width: fit-content; margin: 0; margin-top: 20px;
                                                    border-bottom: 1px solid grey; " v-if="prescriber.uuid"
                                                @click="clearPrescriber">Clear Selected Prescriber</p>
                                        </div>
                                        <div class="form-fields">
                                            <div class="form-field text-field">
                                                <label for="first_name">Prescriber Number</label>
                                                <input v-if="prescriber.uuid" type="text" v-model="prescriber.prescriber_number"
                                                    disabled>
                                                <input v-else type="text" name="first_name" v-model="prescriber.prescriber_number">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Name</label>
                                                <input v-if="prescriber.uuid" type="text"
                                                    :value="prescriber.first_name + ' ' + prescriber.last_name" disabled>
                                                <input v-else type="text" name="first_name" v-model="prescriber.name">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Phone</label>
                                                <input v-if="prescriber.uuid" type="text" v-model="prescriber.phone" disabled>
                                                <input v-else type="text" name="first_name" v-model="prescriber.phone">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">After Hours Phone</label>
                                                <input v-if="prescriber.uuid" type="text" v-model="prescriber.after_hours" disabled>
                                                <input v-else type="text" name="first_name" v-model="prescriber.after_hours">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Address</label>
                                                <vue-google-autocomplete v-if="!prescriber.uuid" id="prescriberaddress"
                                                    classname="form-control" placeholder=""
                                                    v-on:placechanged="getAddressData('prescriber')"
                                                    :country="$store.state.country">
                                                </vue-google-autocomplete>
                                                <input v-else type="text" v-model="prescriber.address" disabled>
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Email</label>
                                                <input v-if="prescriber.uuid" type="text" v-model="prescriber.email" disabled>
                                                <input v-else type="text" name="first_name" v-model="prescriber.email">
                                            </div>
                                            <div class="form-field text-field">
                                                <label for="first_name">Fax Number</label>
                                                <input v-if="prescriber.uuid" type="text" v-model="prescriber.fax" disabled>
                                                <input v-else type="text" name="first_name" v-model="prescriber.fax">
                                            </div>
                                        </div>
                                        <div style="display: flex;">
                                            <button class="button green-button" style="margin-top: 1em;"
                                                @click="removeAndReplaceGP()">Update Prescriber</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="patient-form-content-section" v-if="selectedNavigation == 'allergies'">
                                    <div class="adr-info-form-content">
                                        <h3 style="margin: 0;">Allergies & Adverse Drug Reactions</h3>
                                        <p style="text-align: left;">Enter any applicable allergies/drugs below:</p>
                                        <button class="green-button" style="display: flex; align-items: center" @click="submitADR()">
                                            Save List
                                        </button>
                                        <p v-if="adrErrorMessage" style="color: red; text-align: left; font-weight: bold;">
                                            {{ adrErrorMessage }}
                                        </p>
                                        <div style="max-height: 38em; overflow-y: auto;">
                                            <table>
                                                <tr>
                                                    <th>Drug Name or Substance</th>
                                                    <th style="width: 30em">Reaction Description</th>
                                                    <th>Type of Reaction</th>
                                                    <th>Date Reaction Last Occured</th>
                                                    <th></th>
                                                </tr>
                                                <tr v-for="(item, index) in untitled.adr" :key="index">
                                                    <td><input type="text" v-model="item.custom" style="width: 95%;"></td>
                                                    <td><input type="text" v-model="item.description" style="width: 95%;">
                                                    </td>
                                                    <td><input type="text" v-model="item.reaction_type" style="width: 95%;"></td>
                                                    <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                                    <td>
                                                        <i
                                                            @click="removeADR(index)"
                                                            style="cursor: pointer; font-size: 1.2em !important"
                                                            class='pi pi-trash'>
                                                        </i>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="button outline-button" @click="addNewADR">
                                            <p>Add Another ADR &nbsp; &nbsp; &nbsp; <span style="font-size: 1.2em;">+</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="patient-form-content-section" v-if="selectedNavigation == 'considerations'">
                                    <h3 style="margin: 0;">Special Considerations</h3>
                                    <div class="considerations-info-form-content"
                                        style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; width: 80%">
                                        <div>
                                            <div class="special-considerations-form-container">
                                                <h4 style="text-align: left;font-weight: 500;">Medication Method</h4>
                                                <div class="special-considerations-form">
                                                    <div class="spec-cons-items">
                                                        <div class="spec-cons-item" v-for="(item, index) in medication_methods"
                                                            :key="index">
                                                            <input type="checkbox" name="inactive" @change="saveSelectedItem(item)"
                                                                :checked="untitled.special_considerations.includes(item)">
                                                            <label for="inactive">{{ item }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="special-considerations-form-container">
                                                <h4 style="text-align: left;font-weight: 500;">Medication Administration</h4>
                                                <div class="special-considerations-form">
                                                    <div class="spec-cons-items">
                                                        <div class="spec-cons-item"
                                                            v-for="(item, index) in medication_administration" :key="index">
                                                            <input type="checkbox" name="inactive" @change="saveSelectedItem(item)" :checked="untitled.special_considerations.includes(item)">
                                                            <label for="inactive">{{ item }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="special-considerations-form-container">
                                                <h4 style="text-align: left;font-weight: 500;">Patient Considerations</h4>
                                                <div class="special-considerations-form">
                                                    <div class="spec-cons-items">
                                                        <div class="spec-cons-item" v-for="(item, index) in patient_considerations"
                                                            :key="index">
                                                            <input type="checkbox" name="inactive" @change="saveSelectedItem(item)" :checked="untitled.special_considerations.includes(item)">
                                                            <label for="inactive">{{ item }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display: flex; flex-direction: column; justify-content: space-between;">
                                            <div class="special-considerations-form-container">
                                                <h4 style="text-align: left;font-weight: 500;">Medication Delivery</h4>
                                                <div class="special-considerations-form">
                                                    <div class="spec-cons-items">
                                                        <div class="spec-cons-item" v-for="(item, index) in medication_delivery"
                                                            :key="index">
                                                            <input type="checkbox" name="inactive"
                                                                @change="saveSelectedItem(item)" :checked="untitled.special_considerations.includes(item)">
                                                            <label for="inactive">{{ item }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="special-considerations-form-container">
                                                <h4 style="text-align: left;font-weight: 500; margin-bottom: 0;">Specific
                                                    Instructions/Additional Comments</h4>
                                                <p style="margin-top: 0; text-align: left">Note here if other medication
                                                    methods/administration/delivery or patient considerations.</p>
                                                <div class="special-considerations-form">
                                                    <textarea name="spec-cons-notes" id="spec-cons-notes" v-model="untitled.special_considerations_notes"
                                                        style="max-width: 95%; box-sizing: border-box" cols="60"
                                                        rows="10"></textarea>
                                                </div>
                                            </div>
                                            <button class="green-button" style="width: fit-content; align-self: flex-end" @click="submitSpecCons()">Save Special Considerations</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="patient-form-content-section" v-if="selectedNavigation == 'vaccinations'">
                                    <div class="vac-weight-info-form-content">
                                        <h3>Vaccination History</h3>
                                        <div class="vac-weight-table">
                                            <table>
                                                <tr>
                                                    <th>Vaccine</th>
                                                    <th>Date Last Given</th>
                                                    <th></th>
                                                </tr>
                                                <tr v-for="(item, index) in untitled.vaccinations" :key="index">
                                                    <td><input type="text" v-model="item.vaccine" style="width: 95%;"></td>
                                                    <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                                    <td><i @click="removeVaccine(index)"
                                                            style="cursor: pointer; font-size: 1.2em !important"
                                                            class='pi pi-trash'></i></td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div class="button outline-button" @click="addNewVaccine">
                                            <p>Add Another Vaccination Record &nbsp; &nbsp; &nbsp; <span
                                                    style="font-size: 1.2em;">+</span></p>
                                        </div>
                                    </div>
                                    <div class="vac-weight-info-form-content">
                                        <h3>Weights</h3>
                                        <div class="vac-weight-table">
                                            <table>
                                                <tr>
                                                    <th>Weights</th>
                                                    <th>Date</th>
                                                    <th></th>
                                                </tr>
                                                <tr v-for="(item, index) in untitled.weights" :key="index">
                                                    <td><input type="text" v-model="item.weight" style="width: 95%;"></td>
                                                    <td><input type="date" v-model="item.date" style="width: 95%;"></td>
                                                    <td><i @click="removeWeight(index)"
                                                            style="cursor: pointer; font-size: 1.2em !important"
                                                            class='pi pi-trash'></i></td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="button outline-button" @click="addNewWeight">
                                            <p>Add Another Weight Record &nbsp; &nbsp; &nbsp; <span
                                                    style="font-size: 1.2em;">+</span></p>
                                        </div>
                                    </div>
                                </div>
                                <!-- FORM BUTTONS -->
                                <!-- <div style="display:flex; align-items: center; justify-content: flex-end; align-self: flex-end">
                                    <button class="button" @click="nextFormPage" v-if="selectedNavigation != 'considerations' && (!this.$store.state.user.enmrc_eprescribing_active ? selectedNavigation != 'contact_details' : true)"
                                        style="margin: 0; padding: 0 10px; width: 10em; background-color: #0870DC; color: white;">Next</button>
                                </div> -->
                            </div>
                        </div>
                    <div v-if="loadingPatient" class="panel-content-container patient-form" style="display: flex">
                        <div style="width: 100%; margin-top: 5em;">
                            <p style="font-size: 1em; font-weight: bold;">Loading Resident...</p>
                            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
                        </div>
                    </div>
                </div>
                <SuccessModal v-if="displaySuccessModal" @close="close" :loading="loading" :statusMessage="statusMessage"
                    :message="successMessage"
                    :first_spec_line="errActions ? errActions : first_spec_line"
                    :second_spec_line="second_spec_line" :style="{ color : errActions ? 'red' : '' }" />
                <IHIHistoryModal v-if="displayIHIHistoryModal" @close="close" :patientID="$route.params.id" />
                <IHIConflictModal v-if="displayIHIConflictModal" @close="close" :personalInformation ="personalInformation" :conflictPatients="ihiDetails.conflicts && ihiDetails.conflicts.length ? ihiDetails.conflicts : ''" />
            </div>
            <div v-else style="grid-template-columns: 1fr">
                <div>
                    <h3 style="padding-top: 3em !important;">Loading Resident...</h3>
                    <div style="margin: 0 auto; width: 100%">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                    </div>
                </div>            
            </div>
        </div>
        <div v-else>
                <div class="drug-register-table table-container" style="padding-top: 5em; width: fit-content; height: 20em; box-shadow: none; margin: 0 auto">
                    <h4 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                    You do not have permission to access this feature. Please talk to your admin if you require access.</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import IHIHistoryModal from '@/components/modals/IHIHistoryModal.vue';
import IHIConflictModal from '@/components/modals/IHIConflictModal.vue';

export default {
    components: {
        SuccessModal,
        IHIHistoryModal,
        IHIConflictModal
    },
    data() {
        return {
            validationError: false,
            validationErrorMessage: '',
            statusOptions:[
                {label:'Active',
                 value:'active'
                },
                {label:'Inactive',
                 value:'inactive'
                },
                {label:'Onboarding',
                 value:'onboarding'
                }
            ],
            selectedStatus:'',
            additionalPrescribers: [],
            errorDOB: false,
            loadingPatient: false,
            loadingLocations: false,
            selectedFacility: '',
            successMessage:'',
            facilities: [],
            facilitiesPrescribers: [],
            additional_prescriber:'',
            nodes: [],
            selectedNodeKey: '',
            adrErrorMessage: '',
            errActions:'',
            statusMessage: 'Updating Patient',
            displaySuccessModal: false,
            displayIHIHistoryModal: false,
            displayIHIConflictModal: false,
            loading: false,
            first_spec_line: '',
            second_spec_line:'',
            somelocation: '',
            selectedNavigation: 'personal_information',
            gp: '',
            nonGP: '',
            patientUUID: '',
            pharmacySearchInput: '',
            validForm: false,
            prescriberResults: [],
            pharmacyResults: [],
            navigationItems: [
                {
                    name: 'Personal Information',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Contact Info & Regulatory Numbers',
                    link: 'contact_details',
                    completed: false,
                },
                {
                    name: 'Prescriber and Pharmacy Details',
                    link: 'prescriber_details',
                    completed: false,
                },
                {
                    name: 'Allergies & Adverse Drug Reactions',
                    link:'allergies',
                    completed:false,
                },
                {
                    name: 'Special Considerations',
                    link:'considerations',
                    completed:false,
                },
                // {
                //     name: 'Vaccinations and Weights',
                //     link:'vaccinations',
                //     completed:false,
                // },
                // {
                //     name: 'Diabetes Action Plan',
                //     link:'complex',
                //     completed:false,
                // },
                // {
                //     name: 'Additional Comments',
                //     link:'additional',
                //     completed:false,
                // },
                // {
                //     name: 'New Patient Sign Off',
                //     link:'sign_off',
                //     completed:false,
                // },
            ],
            adrForm: {
                custom: '',
                description: '',
                reaction_type: '',
                date: ''
            },
            personalInformation: {
                first_name: '',
                last_name: '',
                preferred_name: '',
                sex: '',
                dob: '',
                tag: [],
                aslr_site_consent: true,
            },
            regulatory_numbers: [
            ],
            address: {
                street_address: '',
                country: '',
                postcode: '',
                suburb: '',
                city: ''
            },
            facility_location: {
                room_uuid: '',
            },
            kin: {
                first_name: '',
                last_name: '',
                relationship: '',
                phone: '',
                email: ''
            },
            untitled: {
                commencement_date: '',
                expiry_date: '',
                phone: '',
                phone_uuid: '',
                email: '',
                email_uuid: '',
                gp: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: true
                },
                pharmacy: {
                    name: '',
                    address: '',
                    contact: '',
                    phone: '',
                    email: '',
                    fax: '',
                    uuid: ''
                },
                prescriber: {
                    prescriber_number: '',
                    uuid: '',
                    name: '',
                    phone: '',
                    after_hours: '',
                    address: '',
                    email: '',
                    fax: '',
                    primary_gp: false
                },
                
                prescribers: [],
                adr: [
                    {
                        custom: '',
                        description: '',
                        reaction_type: '',
                        date: ''
                    }
                ],
                special_considerations: [],
                special_considerations_notes: '',
                vaccinations: [
                    {
                        vaccine: '',
                        date: ''
                    }
                ],
                weights: [
                    {
                        weight: '',
                        date: ''
                    }
                ]
            },
            // SPECIAL CONSIDERATION OPTIONS
            medication_methods: [
                'Whole',
                'Quartered',
                'Dissolved',
                'Halved',
                'Capsule opened',
                'Crushed and mixed with...',
            ],
            medication_administration: [
                'Water',
                'Thickened fluids',
            ],
            patient_considerations: [
                'Swallowing difficulty',
                'Cognitive impairment',
                'Resistive to medication',
                'Self administers',
                'Crush medications',
                'Dexterity issues',
                'Nil by mouth',
            ],
            medication_delivery: [
                'Teaspoon - staff guide',
                'Medication cup',
                'Resident/patient hand',
                'PEG administration',
            ],
            // LOCATION DETAILS
            parentLocation: '',
            locationName: [],
            childLocations: [],
            previewImage: null,
            originalGPID: '',
            patient: {

            },
            pharmacies: [],
            selectedPharmacy: '',
            prescribers: [],
            selectedPrescriber: '',
            ihiDetails:{
                number:''
            },
            editDob:'',
            currMedicare:'',
            currDVA:'',
        }
    },
    methods: {
        saveStatus(){
            if(this.selectedStatus){
                this.displaySuccessModal = true;
                this.loading = true;
                axios.post(`/person/${this.patientUUID}/status`, {status: this.selectedStatus}, this.$store.state.header).then(res=>{
                    console.log('updated status', res);
                    this.loading = false;
                    this.successMessage = 'Updated resident status.';
                    this.$emit('changeStatus', this.selectedStatus);
                }).catch(err=>{
                    this.loading = false;
                    this.successMessage = `Error: ${err.response.data}`;
                })
            }
        },
        validateIHINumber(clicked) {
            let payload = {};
            if(this.medicare_num && this.medicare_num.length && (this.currMedicare != this.medicare_num[0].data)){
                console.log('this is the medicare match', this.currMedicare, this.medicare_num[0].data);
                payload = {
                    type: 'medicare',
                    number: this.medicare_num[0].data,
                    patient_id: this.patientUUID
                }
            } else if(this.dva_num && this.dva_num.length && (this.currDVA != this.dva_num[0].data)){
                console.log('this is the dva match', this.currDVA, this.dva_num[0].data);
                payload = {
                    type: 'dva',
                    number: this.dva_num[0].data,
                    patient_id: this.patientUUID
                }
            } else if(this.ihiDetails && this.ihiDetails.number){
                console.log('using ihi details')
                payload = {
                    type: 'ihi',
                    number: this.ihiDetails.number ? this.ihiDetails.number.split(' ').join('') : '',
                    patient_id: this.patientUUID
                }
            } else{
                if(this.medicare_num && this.medicare_num.length && this.medicare_num[0].data){
                    console.log('validating against medicare, no change')
                    payload = {
                        type: 'medicare',
                        number: this.medicare_num[0].data,
                        patient_id: this.patientUUID
                    }
                } else if(this.dva_num && this.dva_num.length && this.dva_num[0].data){
                    console.log('validating against dva, no change')
                    payload = {
                        type: 'dva',
                        number: this.dva_num[0].data,
                        patient_id: this.patientUUID
                    }
                } else{
                    console.log('NO NUMBER TO VALIDATE AGAINST')
                }
            }

            axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                console.log('this is the response for validate IHI', response.data);
                this.validIHI(response, clicked);
            }).catch(err => {
                console.log('/validate-ihi failed with the following error:', err, err.response);
                // CHECK IF THE RESPONSE IS RESOLVED BUT FOR A DIFFERENT IHI
                if (err.response && err.response.data && err.response.data.error && err.response.data.error.includes("duplicate")) {
                    console.log('Resolved and different IHI number returned', err.response.data.data);
                    payload = {
                        type: 'ihi',
                        number: err.response.data.data.ihi_number,
                        patient_id: this.patientUUID
                    }
                    axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                        console.log("This is the response for the second validate IHI", response.data);
                        this.validIHI(response, clicked);
                    }).catch(err => {
                        this.invalidIHI(err, clicked);
                    })
                }
                this.invalidIHI(err, clicked);
            })
        },
        validIHI(response, clicked) {
            this.ihiDetails = response.data.data;
            this.currMedicare = this.medicare_num[0].data;
            this.currDVA = this.dva_num[0].data;
            if (clicked) {
                axios.post(`/person/${this.patientUUID}/ihi`, {}, this.$store.state.header).then(res=>{
                    this.ihiDetails = res.data;
                })
            }
        },
        invalidIHI(err, clicked) {
            this.displaySuccessModal = false;
            if (!err?.response?.data?.error?.includes('unique')) {
                this.ihiDetails.number = '';
                this.ihiDetails.ihi_record_status = '';
                this.ihiDetails.ihi_status = '';
            }
            if (clicked) {
                axios.post(`/person/${this.patientUUID}/ihi`, {}, this.$store.state.header).then(res=>{
                    this.ihiDetails = res.data;
                })
            }
            this.errActions =  err.response.data.data ? err.response.data.data.service_message.reason : 'Please update information or try again later.';
            this.displaySuccessModal = true;
            this.successMessage = err.response.data.error ? err.response.data.error : err.response.data.exception ? 'Error.' : err.response.data;
            this.second_spec_line = 'If this is incorrect, please check the personal information and try again.';
        },
        retrieveSelectedFacilityLocations() {
            this.loadingLocations = true;
            this.locationDirectory = '';
            console.log('this is the selected facility', this.selectedFacility);
            axios.post(`/facility/${this.selectedFacility.value}/locations`, {}, this.$store.state.header).then(res => {
                console.log('these are the facilitys locations', res.data);
                this.nodes = res.data;
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
                this.loadingLocations = false;
            }).catch(err=>{
                console.log('this is the locations error', err.response.data);
                this.displaySuccessModal = true;
                this.successMessage = err.response.data;
            })
        },
        getFacilities() {
            axios.post('/get-facilities', {}, this.$store.state.header).then(res => {
                this.facilities = res.data;
            }).catch(err => {
                console.log('err issss', typeof err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data, err.response.data);
            })
        },
        getFacilityPrescibers() {
            const endpoint = '/facility/' + this.$store.state.currentLocation.facility.uuid + '/prescribers'
            axios.post(endpoint, {}, this.$store.state.header).then(res => {
                this.facilitiesPrescribers = res.data;
                this.facilitiesPrescribers.forEach((prescriber, index)=>{
                    if(this.patient.primary_prescriber?.uuid && (prescriber.uuid == this.patient.primary_prescriber?.uuid)){
                        this.facilitiesPrescribers.splice(index, 1);
                    }
                })
            }).catch(err => {
                console.log('err issss', typeof err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
                console.log('err is', err.response.data, err.response.data);
            })
        },
        getPharmacies() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/pharmacies`, {}, this.$store.state.header).then(res => {
                console.log('these are the pharmacies', res.data);
                this.pharmacies = res.data;
            })
        },

        submitPharmacy() {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            axios.post(`/person/${this.patientUUID}/link-pharmacy`, { pharmacy_id: this.selectedPharmacy.uuid }, this.$store.state.header).then(res => {
                console.log('patient pharmacy submitted.', res);
                this.first_spec_line = 'Patient updated!';
            })
        },
        getPrescribers() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/prescribers`, {}, this.$store.state.header).then(res => {
                console.log('these are the prescribers', res.data);
                this.prescribers = [];
                res.data.forEach(item=>{
                    if(item.name && item.name != ' '){
                        this.prescribers.push(item);
                    }
                })
                this.prescribers = this.prescribers.filter((v, i, a) => a.indexOf(v) === i);
                // remove primary if there is one
                if(this.patient.primary_prescriber != null) {
                    console.log("1")
                    this.additionalPrescribers = this.prescribers.filter(prescriber => this.patient.primary_prescriber.uuid != prescriber.uuid)
                } else {
                    console.log("2")
                    this.additionalPrescribers = this.prescribers
                }
            })
        },
        submitPrescriber(additionalPrescriber) {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            axios.post(`/person/${this.patientUUID}/link-prescriber`, { prescriber_id: additionalPrescriber ? this.additional_prescriber.uuid : this.selectedPrescriber.uuid, primary: additionalPrescriber ? false : true }, this.$store.state.header).then(res => {
                console.log('patient prescriber submitted.', res);
                this.first_spec_line = 'Patient updated!';
                if(!additionalPrescriber){
                    // update primary prescriber locally
                    this.patient.primary_prescriber = this.selectedPrescriber
                    // update additonal prescribers
                    this.additionalPrescribers = this.prescribers.filter(prescriber => this.patient.primary_prescriber.uuid != prescriber.uuid)
                } else{
                    console.log('adding linked prescriber', this.additional_prescriber);
                    this.facilitiesPrescribers.push({
                        personal_information:{
                            first_name: this.additional_prescriber.name.split(' ')[0],
                            last_name: this.additional_prescriber.name.split(' ')[1],
                        },
                        uuid: this.additional_prescriber.uuid
                    });
                    this.additional_prescriber = ""
                }

            })
        },
        unlinkPrescriber(gp) {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            axios.post(`/person/${this.patientUUID}/unlink-prescriber`, {prescriber_id: gp.uuid}, this.$store.state.header).then(res => {
                console.log('patient prescriber submitted.', res);
                this.first_spec_line = 'Patient updated!';
                this.facilitiesPrescribers.forEach((prescriber, index)=>{
                    if(prescriber.uuid == gp.uuid){
                        console.log('matched unlinked prescriber', this.facilitiesPrescribers, prescriber, index);
                        // this.patient.prescribers.splice(index,1);
                        this.facilitiesPrescribers.splice(index,1);
                    }
                })
                // update fe data to reflect changes 
                this.patient.prescribers = this.patient.prescribers.filter(prescriber => prescriber.uuid != gp.uuid)
            }).catch(err=>{
                this.first_spec_line = 'Error: '+err.response;
            })
        },
        linkPrescriber(gp) {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            axios.post(`/person/${this.patientUUID}/link-prescriber`, {prescriber_id: gp.uuid, primary: false}, this.$store.state.header).then(res => {
                console.log('patient additonal prescriber submitted.', res);
                this.first_spec_line = 'Patient updated!';
                // update fe data to reflect changes 
                this.patient.prescribers.push(gp)
                
            })
        },
        // UPLOAD IMAGE
        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.previewImage = e.target.result;
                console.log(this.previewImage);
            };
        },
        nextFormPage() {
            if (this.selectedNavigation == 'personal_information') {
                console.log('hitting the if, personal_information')
                this.selectedNavigation = this.navigationItems[3].link;
            } else {

                switch (this.selectedNavigation) {
                    case 'prescriber_details':
                        this.selectedNavigation = this.navigationItems[4].link;
                        break;
                    case 'allergies':
                        this.selectedNavigation = 'considerations';
                        break;
                    case 'considerations':
                        this.selectedNavigation = this.navigationItems[6].link;
                        break;
                    case 'vaccinations':
                        this.selectedNavigation = this.navigationItems[7].link;
                        break;
                    case 'complex':
                        break;

                }

            }
        },
        // FORM SUBMIT PROCESS

        // REGISTER PATIENT
        editPersonalPatientInfo() {
            this.loading = true;
            this.displaySuccessModal = true;
            // this.personalInformation.dob = this.personalInformation.dob.toLocaleDateString();
            axios.post('/person/' + this.$route.params.id + '/edit', this.personalInformation, this.$store.state.header).then(res => {
                console.log('patient registered.', res);
                this.loading = false;
                this.first_spec_line = 'Successfully updated!';
                this.editEntitlementNumbers();
                this.personalInformation.dob = `${res.data.personal_information.dob.split('/')[2]}-${res.data.personal_information.dob.split('/')[1]}-${res.data.personal_information.dob.split('/')[0]}`;
                // this.personalInformation.dob = `${this.patient.personal_information.dob.split('/')[2]}-${this.patient.personal_information.dob.split('/')[1]}-${this.patient.personal_information.dob.split('/')[0]}`;
            }).catch(err => {
                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired');
                    this.$router.push('/login');
                }
            })
        },
        close(value) {
            if (value) {
                this.displaySuccessModal = false;
                this.displayIHIHistoryModal = false;
                this.displayIHIConflictModal = false;
                this.first_spec_line = '';
                this.second_spec_line = '';
                this.errActions = '';
                this.successMessage = '';
            }
        },
        ValidateInput(validation){
            this.validationError = false;
            this.validationErrorMessage = "";
            switch (validation) {
                case 'editNextOfKin':
                    //check name if alpha nummeric
                    //check phone number between 8 and 11 digits
                    //check email if valid format (not deliverability)
                    this.validationErrorMessage = (!this.ValidateRegex(this.kin.first_name, /^[0-9a-zA-Z _-]+$/) && (this.validationErrorMessage == '')) ? 'Please make sure the first name is filled in and does not contain any special characters.' : this.validationErrorMessage;
                    this.validationErrorMessage = (!this.ValidateRegex(this.kin.last_name, /^[0-9a-zA-Z _-]+$/) && (this.validationErrorMessage == '')) ? 'Please make sure the last name is filled in and does not contain any special characters.' : this.validationErrorMessage;
                    this.validationErrorMessage = (!this.ValidateRegex(this.kin.phone, /^[0-9]{8,11}$/) && (this.validationErrorMessage == '')) ? 'Please make sure the phone number only contains digits and is a valid number (8-11 digits).': this.validationErrorMessage;
                    this.validationErrorMessage = (!this.ValidateRegex(this.kin.email, /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) && (this.validationErrorMessage == '')) ? 'Please make sure the email address is valid.' : this.validationErrorMessage;
                    return ((this.validationErrorMessage == '') ? true : false);
            }
            return true;
        },
        // check if input is valid - regex
        ValidateRegex(input, regex){
            if(!input){
                return false;
            }
            return regex.test(input);
        },
        editNextOfKin() {
            // validate the input based on next of kin validation rules
            if(!this.ValidateInput('editNextOfKin')){
                this.validationError = true;
                return false;
            }
            // resetting the modal message
            this.first_spec_line = '';
            this.errActions = '';

            this.loading = true;
            this.displaySuccessModal = true;
            console.log('this is the kin', this.kin);
            axios.post(`/patient/${this.patientUUID}/kin${this.kin.uuid ? '/' + this.kin.uuid : ''}`, this.kin, this.$store.state.header).then(res => {
                console.log('kin has been updated', res.data);
                this.loading = false;
                this.errActions = '';
                this.first_spec_line = 'Successfully updated!';
            }).catch(err => {
                // adding error handling
                this.loading = false;
                this.errActions = err.response.data;

                console.log('this is the err', err);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        editEntitlementNumbers() {
            this.first_spec_line = '';
            this.loading = true;
            this.displaySuccessModal = true;
            let payload = [];
            this.regulatory_numbers.forEach(num => {
                console.log('reg num: ', num);
                if (num.uuid) {
                    axios.post(`/person/${this.patientUUID}/remove-regulatory/${num.uuid}`, {}, this.$store.state.header).then(res => {
                        console.log(res);
                    })
                }
                if (num.data) {
                    if(num.expiry){
                        num.expiry = new Date(num.expiry).toLocaleDateString('en-AU')
                    }
                    payload.push(num);
                }
                delete num.uuid;
                delete num.number;
            })
            if(payload.length){
                axios.post(`/person/${this.patientUUID}/regulatory`, { regulatory_numbers: payload }, this.$store.state.header).then(res => {
                    console.log('updated ent numbers', res);
                    this.loading = false;
                    this.first_spec_line = 'Successfully updated!';
                    this.regulatory_numbers.forEach(number=>{
                        res.data.forEach(resNum=>{
                            if(resNum.regulatory_type == number.regulatory_type){
                                
                                number = resNum;
                                number.uuid = resNum.uuid;
                                console.log('this is the regulatory number uuid', resNum.uuid, number)
                            }
                        })
                    })
                    setTimeout(()=>{console.log('this is the reg number after 2 secs', this.regulatory_numbers)}, 2000)
                    axios.post(`/person/${this.patientUUID}/ihi`, {}, this.$store.state.header).then(res=>{
                        this.ihiDetails = res.data;
                        if(res.data.conflicted){
                            this.displayIHIConflictModal = true;
                        }
                        this.validateIHINumber(true);
                    })
                    
                }).catch(err => {
                    console.log('this is the err', err);
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                })
            }
            
        },
        // SUBMIT IMAGE
        submitImage() {
            if (this.previewImage) {
                axios.post(`/aws/patient/${this.patientUUID}/image`, {
                    image: this.previewImage,
                    item:['profile', 'thumbnail']
                }, this.$store.state.header).then(res => {
                    console.log('uploaded patient image', res);
                    this.displaySuccessModal = true;
                    this.first_spec_line = 'Successfully uploaded image';
                    this.$store.dispatch('getRefreshToken');
                }).catch(err=>{
                    this.displaySuccessModal = true;
                    this.second_spec_line = '';
                    this.first_spec_line = err.response && err.response.data ? err.response.data : err;
                })
            } else {
                console.log('No image has been uploaded');
            }

        },
        // CONFIRM FACILITY LOCATION
        // confirmFacilityLocation(){
        //     if(this.facility_location.room_uuid){
        //         axios.post(`/patient/${this.patientUUID}/move-location/${this.facility_location.room_uuid}`, {}, this.$store.state.header).then(res=>{
        //             console.log('facility location submitted.', res)
        //         })
        //     } else{
        //         console.log('no facility location confirmed.')
        //     }
        // },
        // CONFIRM FACILITY LOCATION
        confirmFacilityLocation() {
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            this.successMessage = '';
            if (Object.keys(this.selectedNodeKey)[0]) {
                axios.post(`/person/${this.patientUUID}/location`, { status: 'resident', location: Object.keys(this.selectedNodeKey)[0] }, this.$store.state.header).then(res => {
                    this.completedActions += 1;
                    console.log('facility location submitted.', res);
                    this.first_spec_line = 'Patient updated!';
                }).catch(err => {
                    this.completedActions += 1;
                    // Keeping tab on error count for possibly more detailed error management
                    this.errActionsCount += 1;
                    this.errActions += ' Error assigning resident to location.'
                    console.log('this is the link resident error', err, err.response.data);
                    this.first_spec_line = 'Error: ' + err.response.data;
                })
            } else {
                console.log('no facility location confirmed.');
            }
        },
        // REGULATORY INFO
        submitRegulatoryInfo() {
            let regulatory_numbers = [];
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            for (let key in this.regulatory_numbers) {
                console.log('this is the key', key);
                if (this.regulatory_numbers[key].number) {
                    regulatory_numbers.push({
                        number: this.regulatory_numbers[key].number,
                        regulator_type: this.regulatory_numbers[key].regulator_type,
                        date: this.regulatory_numbers[key].expiry ? this.regulatory_numbers[key].expiry : ''
                    })
                }

            }
            if (this.regulatory_numbers.length) {
                axios.post(`/patient/${this.patientUUID}/regulatory`, regulatory_numbers, this.$store.state.header).then(res => {
                    console.log('regulatory information submitted.', res);
                    this.first_spec_line = 'Patient updated!';
                })
            } else {
                console.log('no regulatory numbers filled in or submitted.')
            }


        },
        // PATIENT ADDRESS
        setPatientAddress() {
            this.$store.dispatch('getRefreshToken');
            if (this.address.street_address) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/address`, this.address, this.$store.state.header).then(res => {
                    console.log('patient address submitted.', res);
                    this.patient.address = this.address;
                    this.first_spec_line = 'Patient address updated!';
                })
            } else {
                console.log('no address has been added or submitted');
            }
        },
        editContactMethods() {
            this.$store.dispatch('getRefreshToken');
            this.displaySuccessModal = true;
            this.loading= true;
            

            let telephoneNumberFormatted = this.untitled.phone;
            
            telephoneNumberFormatted= telephoneNumberFormatted.replace(" ",'');

            let phoneName = "phone";
            if (this.untitled.phone) {
                const countryprefix = "+61"

                if(!telephoneNumberFormatted.startsWith("+61")){
                    telephoneNumberFormatted = countryprefix + telephoneNumberFormatted;
                }


            }

            if (this.untitled.phone_uuid) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/contact-method/${this.untitled.phone_uuid}/edit`, { data: telephoneNumberFormatted }, this.$store.state.header).then(res => {
                    console.log('phone has been updated', res.d);
                    this.loading = false;
                    this.first_spec_line = 'Patient phone number updated!';
                })
            } else {
                if (this.untitled.phone) {
                    let phone = {
                        name: phoneName,
                        data: telephoneNumberFormatted,
                        type: 'phone'
                    };
                    this.displaySuccessModal = true;
                    this.first_spec_line = 'Updating patient...';
                    axios.post(`/person/${this.patientUUID}/contact-method`, {contact_methods: [phone]}, this.$store.state.header).then(res => {
                        console.log('contact method has been saved (new)', res);
                        this.loading = false;
                        this.first_spec_line = 'Patient phone number updated!';
                    })
                }
            }

            if (this.untitled.email_uuid) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/contact-method/${this.untitled.email_uuid}/edit`, { data: this.untitled.email }, this.$store.state.header).then(res => {
                    console.log('phone has been updated', res.data);
                    this.loading = false;
                    this.first_spec_line = 'Patient email updated!';
                })
            } else {
                let email = {
                    name: 'Email',
                    data: this.untitled.email,
                    type: 'email'
                };
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/person/${this.patientUUID}/contact-method`, {contact_methods: [email]}, this.$store.state.header).then(res => {
                    console.log('contact method has been saved (new)', res);
                    this.loading = false;
                    this.first_spec_line = 'Patient email updated!';
                })
            }

        },
        editContactDetails() {
            this.setPatientAddress();
            this.editContactMethods();
        },
        // CREATE KIN
        createKin() {
            if (this.kin.first_name) {
                let names = this.kin.split(' ');
                this.kin.first_name = names.shift();
                this.kin.last_name = names.join(' ');
                axios.post(`/patient/${this.patientUUID}/kin`, this.kin, this.$store.state.header).then(res => {
                    console.log('patient kin submitted.', res);
                    this.errActions = '';
                    this.first_spec_line = 'Successfully saved kin.';
                    this.displaySuccessModal = true;
                })
            } else {
                console.log('first name of kin not entered, so submission not made for kin')
            }

        },
        // ADD PHONE AND EMAIL CONTACT METHODS
        addContactMethods() {
            this.$store.dispatch('getRefreshToken');
            let contactMethods = [];
            if (this.untitled.phone) {
                contactMethods.push({
                    name: 'Phone',
                    data: this.untitled.phone,
                    type: 'phone'
                });
            }
            if (this.untitled.email) {
                contactMethods.push({
                    name: 'Email',
                    data: this.untitled.email,
                    type: 'email'
                });
            }
            if (this.contactMethods.length) {
                this.displaySuccessModal = true;
                this.first_spec_line = 'Updating patient...';
                axios.post(`/patient/${this.patientUUID}/contact-method`, { contact_methods: contactMethods }, this.$store.state.header).then(res => {
                    console.log('patient kin submitted.', res);
                    this.first_spec_line = 'Patient updated!';
                })
            } else {
                console.log('no contact methods were entered, therefore not submitted.')
            }
        },
        // SUBMIT ADR ARRAY
        submitADR() {
            this.$store.dispatch('getRefreshToken');
            this.adrErrorMessage = '';
            this.displaySuccessModal = true;
            this.loading = true;
            let adrPayload = [];

            this.untitled.adr.forEach(item=>{
                if(item.uuid){
                    axios.post(`/patient/${this.patientUUID}/adr/${item.uuid}/delete`, {}, this.$store.state.header)
                    .then(res => {
                        console.log('deleted adr', res.data);
                    })
                }
            })
            this.untitled.adr.forEach((item)=>{
                if(item.custom){
                    let citem = {...item};
                    if(citem.date){
                        citem.date = new Date(citem.date).toLocaleDateString('en-AU');
                    }
                    adrPayload.push(citem);
                }
            })
            axios.post(`/patient/${this.patientUUID}/adr`, {adr:adrPayload}, this.$store.state.header)
            .then(res => {
                console.log('ADR submitted.', res);
                res.data['0'].forEach(item=>{
                    this.untitled.adr.forEach(adrItem=>{
                        if(item.custom == adrItem.custom){
                            adrItem.uuid = item.uuid;
                        }
                    })
                })
                this.loading = false;
                this.first_spec_line = 'Successfully updated ADR list!'; 
            })
            .catch(err => {
                console.log('Error submitting adr', err);
                this.adrErrorMessage = err.response.data.message;
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })

        },
        // SUBMIT SPECIAL CONSIDERATIONS
        submitSpecCons() {
            this.$store.dispatch('getRefreshToken');
            this.displaySuccessModal = true;
            this.first_spec_line = 'Updating patient...';
            let specCons = {
                special_considerations: this.untitled.special_considerations,
                comments: this.untitled.special_considerations_notes
            }
            if (specCons.special_considerations.length || specCons.comments) {
                axios.post(`/patient/${this.patientUUID}/special-considerations`, specCons, this.$store.state.header).then(res => {
                    console.log('spec cons submitted.', res);
                    this.first_spec_line = 'Patient updated!';
                })
            } else {
                console.log('no special cons or notes made, not submitted.')
            }

        },
        // SUBMIT VAX
        submitVx() {
            if (this.untitled.vaccinations[0].vaccine != '') {
                axios.post(`/patient/${this.patientUUID}/vaccinations`, this.untitled.vaccinations, this.$store.state.header).then(res => {
                    console.log('vaccinations submitted.', res)
                })
            } else {
                console.log('no vaccinations recorded')
            }
        },
        // SUBMIT WEIGHTS
        submitWeights() {
            if (this.untitled.weights[0].weight != '') {
                axios.post(`/patient/${this.patientUUID}/weights`, this.untitled.weights, this.$store.state.header).then(res => {
                    console.log('weights submitted.', res)
                })
            } else {
                console.log('no weights recorded')
            }
        },
        submitForm() {
            // this.patientRegistration();
            this.submitImage();
            this.editRegulatoryInfo();
            this.setPatientAddress();
            this.addContactMethods();
            this.submitPrescribers();
            this.submitPharmacy();
            this.submitADR();
            this.submitSpecCons();
            this.submitVx();
            this.submitWeights();
        },
        getLocations() {
            let facilityID = this.$store.state.currentLocation.facility && this.$store.state.currentLocation.facility.uuid ? this.$store.state.currentLocation.facility.uuid : '175f2a90-b5f8-4079-8ea0-07367b0c2db8';
            axios.post(`/facility/${facilityID}/locations`, {}, this.$store.state.header).then(res => {
                console.log('this is the facility location', res);
                this.$store.dispatch('getRefreshToken');
                this.nodes = res.data;
                this.nodes.forEach(node => {
                    node.label = node.name;
                    if (node.children.length) {
                        this.getChildLocation(node);
                    }
                })
            }).catch(err=>{
                console.log('this is the locations error', err.response.data);
                this.displaySuccessModal = true;
                this.successMessage = err.response.data;
            })
        },
        getChildLocation(location) {
            console.log('GET CHILD LOCATION LOOPING', location);
            location.label = location.name;
            if (location.children.length) {
                location.children.forEach(child => {
                    this.getChildLocation(child);
                })
            }
        },
        addNewADR() {
            let newEntry = {
                
                custom: '',
                description: '',
                reaction_type: '',
                date: ''
            };
            this.untitled.adr.unshift(newEntry);
        },
        removeADR(index) {
            if(this.untitled.adr[index].uuid){
                axios.post(`/patient/${this.patientUUID}/adr/${this.untitled.adr[index].uuid}/delete`, {}, this.$store.state.header).then(res=>{
                    console.log('deleted adr', res.data);
                })
            }
            this.untitled.adr.splice(index, 1);
        },
        addNewVaccine() {
            let newVaccine = {
                vaccine: '',
                date: ''
            };
            this.untitled.vaccinations.unshift(newVaccine);
        },
        removeVaccine(index) {
            this.untitled.vaccinations.splice(index, 1);
        },
        addNewWeight() {
            let newWeight = {
                weight: '',
                date: ''
            };
            this.untitled.weights.unshift(newWeight);
        },
        removeWeight(index) {
            this.untitled.weights.splice(index, 1);
        },
        saveSelectedItem(item) {
            console.log('cacheSelectedItem is working', item);
            if (this.untitled.special_considerations.includes(item)) {
                console.log('this item exists in array', this.untitled.special_considerations);
                this.untitled.special_considerations.forEach((alreadySavedItem, index) => {
                    if (item == alreadySavedItem) {
                        this.untitled.special_considerations.splice(index, 1);
                    }
                })
            } else {
                console.log('this item does not exist in array', this.untitled.special_considerations);
                this.untitled.special_considerations.push(item);
            }
        },
        getPersonalAddressData(addressData, propertyData, id) {

            this.address.state = addressData.administrative_area_level_1;
            this.address.city = addressData.administrative_area_level_2;
            this.address.street_address = `${document.getElementById(id).value.split(' ')[0]} ${addressData.route}`;
            this.address.country = addressData.country;
            this.address.postcode = addressData.postal_code;
            this.address.suburb = addressData.locality;
            console.log('this is the address now', addressData, propertyData, this.address);
        },
        clearGP() {
            this.untitled.gp = {};
            this.gp = '';
        },
        getAge(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        validateDOB(){
            if(this.personalInformation.dob){
                this.personalInformation.dob = new Date(this.personalInformation.dob);
                this.personalInformation.dob = this.personalInformation.dob.toLocaleDateString('en-au')
                if(this.personalInformation.dob.split('/').length < 3 || this.personalInformation.dob.split('/')[2].length < 4){
                    console.log('DOB IS INCORRECT', this.personalInformation.dob);
                    this.errorDOB = true;
                } else{
                    this.errorDOB = false;
                    this.editPersonalPatientInfo();
                    console.log('DOB IS CORRECT', this.personalInformation.dob);
                }
            } else{
                this.errorDOB = true;
            }
        },
    },
    mounted() {
        
        this.getFacilities();
        // this.getFacilityPrescibers();
        this.patientUUID = this.$route.params.id;
        if(!this.$store.state.user.enmrc_eprescribing_active){
            this.navigationItems = [
                {
                    name: 'Personal Information',
                    link: 'personal_information',
                    completed: false,
                },
                {
                    name: 'Contact Details',
                    link: 'contact_details',
                    completed: false,
                },
                {
                    name: 'Entitlement Numbers',
                    link: 'contact_details',
                    completed: false,
                }
            ]
        } else{
            
            this.getPharmacies();
  
        }
        // let tempDobStorage = '';
        this.loadingPatient = true;
        axios.post('/retrieve-patient', { patient_id: this.patientUUID, location: this.$store.state.currentLocation.uuid }, this.$store.state.header).then(res => {
            this.patient = res.data;
            this.loadingPatient = false;
            this.selectedStatus = this.patient.status.toLowerCase();
            this.patient.personal_information.age = this.getAge(this.patient.personal_information.dob);
            this.patient.personal_information.image = '';
            if(this.patient.considerations && this.patient.considerations.length){
                console.log('PATIENT HAS SPECIAL CONSIDERATIONS', this.patient.considerations);
                if(this.patient.considerations[this.patient.considerations.length-1].special_considerations.length){
                    this.patient.considerations[this.patient.considerations.length-1].special_considerations.forEach(item=>{
                        this.untitled.special_considerations.push(item.name.slice(0,1).toUpperCase()+item.name.slice(1));
                    })
                }
                this.untitled.special_considerations_notes = this.patient.considerations[this.patient.considerations.length-1].comments
            }
            this.patient.prescribers.forEach((prescriber, index)=>{
                if(this.patient.primary_prescriber?.uuid && (prescriber.uuid != this.patient.primary_prescriber?.uuid)){
                    this.facilitiesPrescribers.push(prescriber);
                }
            })
            this.personalInformation = {
                first_name: this.patient.personal_information.first_name ? this.patient.personal_information.first_name : '',
                last_name: this.patient.personal_information.last_name ? this.patient.personal_information.last_name : '',
                preferred_name: this.patient.personal_information.preferred_name ? this.patient.personal_information.preferred_name : '',
                sex: this.patient.personal_information.sex ? this.patient.personal_information.sex : '',
                dob: `${this.patient.personal_information.dob.split('/')[2]}-${this.patient.personal_information.dob.split('/')[1]}-${this.patient.personal_information.dob.split('/')[0]}`,
            }
            
            // this.patient.personal_information.image = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyn74IpFH6FKnyy1JRAL2UTgIsxdByOVUIA&usqp=CAU';
            console.log('this is the res data for retrieve patient', res.data, this.patient);

            // IMAGE
            this.previewImage = this.patient && this.patient.image_information &&  this.patient.image_information.url ? this.patient.image_information.url : '';
            this.personalInformation.dob = `${this.patient.personal_information.dob.split('/')[2]}-${this.patient.personal_information.dob.split('/')[1]}-${this.patient.personal_information.dob.split('/')[0]}`;

        }).then(() => {
            // get the prescribers of the location
            this.getPrescribers();
            // IHI number
            axios.post(`/person/${this.patientUUID}/ihi`, {}, this.$store.state.header).then(res2=>{
                this.ihiDetails = res2.data;
                if(res2.data.conflicted){
                    this.displayIHIConflictModal = true;
                }
                this.validateIHINumber(true);
            }).catch(err=>{
                console.log('this is the error of the ihi call', err.response.data);
            })

            // tempDobStorage = new Date(this.patient.personal_information.dob);
            // console.log('THIS IS THE TEMP STORAGE DOB', tempDobStorage);
            // this.personalInformation.dob = tempDobStorage.toISOString().split('T')[0];
            // console.log('THIS IS THE PERSONAL INFORMATION DOB', this.personalInformation.dob, tempDobStorage.toISOString().split('T')[0]);

            // PRESCRIBERS
            if (this.patient.prescribers.length) {
                this.selectedPrescriber = this.patient.primary_prescriber
                this.selectedPrescriber.name = `${this.patient.primary_prescriber.personal_information.first_name} ${this.patient.primary_prescriber.personal_information.last_name}`
            }

            // PHARMACY
            if (this.patient.pharmacy) {
                this.selectedPharmacy = this.patient.pharmacy
            }

            // NEXT OF KIN
            if (this.patient.emergency.length) {
                this.kin = this.patient.emergency[0];
                this.kin.first_name = this.patient.emergency[0].first_name;
                this.kin.last_name = this.patient.emergency[0].last_name;
            }

            if (this.patient.adr && this.patient.adr.length) {
                this.untitled.adr = this.patient.adr;
                this.patient.adr.forEach(item=>{
                    if(item.date){
                        item.date = new Date(item.date).toLocaleString("en-au").slice(0,10);
                        console.log('this is item before assigning', item.date);
                        let b = item.date.split('/');
                        console.log('this is b at split', b);
                        b = `${b[2]}-${b[1]}-${b[0]}`
                        console.log('this is b after concating', b);
                        item.date = b
                    }
                    
                })
            }

            // CONTACT METHODS
            this.patient.contact_methods.forEach(method => {
                if (method.type == 'phone') {
                    this.untitled.phone = method.data;
                    this.untitled.phone_uuid = method.uuid;
                }
                if (method.type == 'email') {
                    this.untitled.email = method.data;
                    this.untitled.email_uuid = method.uuid;
                }
            })

            // ENTITLEMENT/REGULATORY NUMBERS
            // , 'Medicare Expiry'
            let reg_types = ['RAC ID', 'URN/MRN Number', 'Pharmaceutical Entitlement Number', 'Medicare Number', 'Safety Net Number', 'Safety Net Number 2', 'DVA Number', 'Concession Number']
            let included_reg = [];
            this.patient.regulatory_information.forEach(ent_number => {
                if (reg_types.includes(ent_number.regulatory_type)) {
                    this.regulatory_numbers.push(ent_number);
                    included_reg.push(ent_number.regulatory_type);
                    console.log('this is what is included so far', included_reg);
                    if(ent_number.regulatory_type == 'Medicare Number'){
                        this.currMedicare = ent_number.data;
                    }
                    if(ent_number.regulatory_type == 'DVA Number'){
                        this.currDVA = ent_number.data;
                    }
                }
            })
            let not_included = reg_types.filter(x => !included_reg.includes(x));
            console.log('this is what was not included yet', not_included);
            not_included.forEach(excl => {
                this.regulatory_numbers.push(
                    {
                        regulatory_type: excl,
                        expiry: '',
                        data: ''
                    }
                )
            })
        }).catch(err => {
            console.log('this is the err', err);
            if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                console.log('yes, it does include expired')
                this.$router.push('/login');
            }
        })
    },
    computed:{
        grouped_regulatory_numbers(){
            return this.regulatory_numbers.filter(item=>item.regulatory_type != 'Medicare Number' && item.regulatory_type != 'DVA Number')
        },
        personal_regulatory_numbers(){
            return this.regulatory_numbers.filter(item=>item.regulatory_type == 'Medicare Number' || item.regulatory_type == 'DVA Number')
        },
        medicare_num(){
            return this.regulatory_numbers.filter(item=>item.regulatory_type == 'Medicare Number')
        },
        dva_num(){
            return this.regulatory_numbers.filter(item=>item.regulatory_type == 'DVA Number')
        }
    }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.edit-icon{
    display: none !important;
}

.edit-icon-text{
    display: none;
}








.form-fields {
    display: flex;
    flex-wrap: wrap;
}

.form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    margin-right: 20px;
    margin-top: 15px;

    label {
        padding-left: 10px;
    }

    input,
    label {
        display: block;
    }
}

.text-field {
    width: 20em;
}

.date-field {
    width: 20em;
}

.address-field {
    width: 12em;
}

.special-considerations-form {
    background-color: $grey;
    padding: 10px;
    border-radius: 8px;
}

.spec-cons-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.spec-cons-item {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.vac-weight-table {
    max-height: 300px;
    overflow-y: scroll;
}

// .form-control{
//     width: 20em;
// }

.prescriber-info-form-content {
    h3 {
        margin-top: 12px;
    }
}

#monthpicker {
    background-color: #F3F4F4 !important
}
</style>