<template>
    <div class="drug-register-view view">
        <!-- <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
        <h3 class="panel-header">Actions</h3>
        <button @click="displayDeliveryModal = true" class="actions-menu-item">New Order</button>
      </div> -->

        <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_deliveries')"
            style="width: 100%;">
            <h3 class="panel-header">Client Profiles</h3>
            <div class="drug-register-table table-container"
                style="display: flex;flex-direction: column;width: 98%;padding: 0px;">
                <div class="filters"
                    style="display: flex; flex-wrap: wrap; align-items: end; height: max-content; justify-content: space-between;padding: 23px;border-bottom: 2px solid #dfdfdf;">
                    <div style="display: flex; align-items: end;gap: 12px;flex-wrap: wrap">
                        <!-- SEARCH SECTION -->

                            <div class="text-search-container search-container calenderdatelabel">
                                <label for="client">Client</label>
                                <div class="calendar-input-container" style="width:43em">
                                    <i class="pi pi-search" style="color: #0569d1;"></i>
                                    <input id="client" placeholder="Search a Client..." v-model="form.filters.party"  @input="debouncedLoadEntries()" style="position: relative;width:45em"/>
                                </div>
                            </div>

                            <!-- <div class="text-search-container search-container calenderdatelabel">
                                <label for="location">Location</label>
                                <div class="calendar-input-container" style="width: 75vh;">
                                    <input id="location" placeholder="Search a Location..."
                                        style="position: relative" />
                                    <i class="pi pi-chevron-down"></i>
                                </div>
                            </div> -->
                            <div class="calenderdatelabel">
                                <label for="location">Location</label>
                                <div class="calendar-input-container" style="width:34em">
                                    <i class="pi pi-search" style="color: #0569d1;"></i>
                                    <input id="location" placeholder="Search a Location..." v-model="form.filters.location"  @input="debouncedLoadEntries()"  style="position: relative;width:34em" />
                                </div>
                                <!-- <MultiSelect class="multiselectstatus" id="location"
                                    placeholder="Search a Location..." /> -->
                            </div>

                        <div class="calenderdatelabel">
                            <label for="presciptionType">Client Type</label>
                                <div style="display: flex; align-items: center">
                                <button :class="[!form.filters.show_active ? 'active-select-filter types-button pendingborder' : 'outline-button s8-button pendingborder']" id="presciptionType" @click="() => { form.filters.show_active = false; loadEntries(); }" style="min-width: 175px;">Current Clients</button>

                                <button :class="[form.filters.show_active ? 'active-select-filter types-button destructionborder' : 'outline-button s8-button destructionborder']" @click="() => { form.filters.show_active = true; loadEntries(); }" style="min-width: 175px;">Past Clients</button>
                                </div>
                        </div>
                        <button class="button clear-button" @click="clearEntriesFilters()" style="margin: 0px;">Clear</button>
                        <button class="button submit-button" @click="loadEntries()" style="margin: 0px;">Search</button>
                        <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && !$store.state.syncUUIDSucces" class="button submit-button" style="background-color: #b5a248;margin: 0px; min-width: 56px"  @click="$store.dispatch('syncEntries', {endpoint: '/integration/sync-clients', type: 'import_clients',value: true, sync: 'clients'})" :disabled="$store.state.syncLoader || syncButtonDisable"><i v-if="$store.state.syncLoader" style="font-size: 1.3rem !important;margin: auto;padding: 0px;" class="pi pi-spin pi-spinner" ></i>
                            <span v-else>{{getCount ? 'Check Again' : 'Sync'}}</span></button>
                            <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && $store.state.syncUUIDSucces" class="button submit-button" style="background-color: #a0b302 ;margin-left: 10px;margin:0px" @click="refreshList()">
              Refresh
            </button>
            <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && $store.state.syncError" class="button submit-button" style="background-color: #e04f39 ;margin-left: 10px; max-width: 56px; min-width: 56px;margin: 0px" @click="displaySuccessModal = true">
              Error
            </button>
                        <!-- Uncomment when sync function for updates is ready -->
                        <!-- <p v-if="$store.state.loadingClientData" style="margin: 5px;">Syncing data, please wait...</p> -->
                        <p style="margin: 5px;">Last synced: {{ getCount ?  this.$store.state.syncLoader ? '--' : "The request is still in progress." : $store.getters.getClientsLastSynced }}</p>

                    </div>

                </div>
                <div v-if="!$store.state.loadingClientData" style="display: flex;flex-direction: column;gap: 20px; align-items: start; overflow-x: hidden;padding: 23px;">
                    <div v-for="(item, index) in getDrugEntries"  :key="index" class="profileMaindiv">
                        <div class="profileDetail">
                            <img :src="require('@/assets/images/avatarIcon.png')" alt="img" class="prfileImage"/>
                            <div>
                              <p style="font-size: 16px;"><b>{{item.name || '-'}}</b></p>
                                    <!-- City and location data property are one and the same in the FE -->
                                    <p><b>Address:&nbsp;</b>{{item.location || '-'}}</p>
                                    <p><b>Phone:&nbsp;</b>{{item.phone || '-'}}</p>
                                    <p><b>Email:&nbsp;</b>{{item.email || '-'}}</p>
                            </div>
                        </div>
                        <button class="button types-button" @click="gotoClient(item.uuid)">View Profile</button>
                    </div>
                </div>
                <div v-else>
                    <h3 v-if="$store.state.clientError">{{$store.state.clientError}}</h3>
                    <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
                 </div>
            <div class="pagination-links" v-if="$store.state.drugEntries && $store.state?.drugEntries?.meta?.current_page" style="padding: 20px;">
              <p class="pagination-arrow" v-show="$store.state?.drugEntries?.meta?.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
              <p>Page {{$store.state?.drugEntries?.meta?.current_page}} of {{$store.state?.drugEntries?.meta?.last_page}}</p>
              <p class="pagination-arrow" v-show="$store.state?.drugEntries?.meta?.current_page < $store.state?.drugEntries?.meta?.last_page" @click="displayPage( $store.state?.drugEntries?.meta?.current_page + 1)"> &rarr; </p>
            </div>
            </div>

        </div>
        <div v-else class="drug-register-table table-container"
            style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];">
            <h3
                style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
        </div>
        <SuccessModal @close="closeSuccesModal" v-if="displaySuccessModal" :message="$store.state.syncError" :first_spec_line="$store.state.syncError?.response?.data?.errors ? $store.state.syncError?.response?.data?.message + ' ' + Object.values($store.state.syncError?.response?.data?.errors).flat()[0] : $store.state.syncError" statusMessage="Sync Clients" />
    </div>
</template>


<script>
import axios from 'axios';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import debounce from "debounce"
export default {
    components: {
        SuccessModal
    },
    data() {
        return {
            form:{
                page:1,
                location_type: ['client'],
                filters:{
                    party:'',
                    location:'',
                },
            
            },
            facility_id: this.$store.state.currentLocation.uuid,
            displaySuccessModal: false,
            syncButtonDisable: false
        }
    },
     computed: {
        getDrugEntries (){
            return this.$store.getters.getDrugEntries
        },
        getCount(){
            return (this.$store.state.LastSync?.clientsLastSynced?.count >= 12 || this.$store.state.syncError) ? true : false
        },
    },
    methods: {
        gotoClient(uuid){
            this.$router.push(`client/${uuid}`);
        },
      
        clearEntriesFilters(){
            this.form={
                location_type: ['client'],
                filters:{
                    party:'',
                    location:'',
                },

            }
            this.$store.dispatch('getClients',this.form)
        },
        loadEntries(){
            if(this.date && this.date.length > 0){
  
                this.$store.dispatch('getClients',this.form)
            } else{
   
                this.$store.dispatch('getClients',this.form)
            }
        },
        debouncedLoadEntries: debounce(function() {
            this.loadEntries();
        }, 500),
      closeSuccesModal(){
       this.displaySuccessModal = false
      },
        displayPage(page) {
            this.form.page = page;
            this.$store.dispatch('getClients', this.form);
        },
    refreshList(){
        this.$store.dispatch('refreshSyncList', 'clients')
        this.loadEntries()
        clearInterval(this.$store.state.syncInterval)
    },
    },
    
    mounted() {
          // Code for autosyncing through pulling (loadEntries() function call should be commented)
            this.loadEntries();

            // this.$store.dispatch('getClients',this.form)

    // Code for autosyncing through pulling
    // Call the sync function if the user stays on the page for 2 seconds after the page is rendered
    if (this.$store.state.user.integration_type === 'carton-cloud' || this.$store.state.user.integration_type === 'microsoft') {
        this.syncButtonDisable = true
        this.syncTimeoutId =  setTimeout(() => {
            this.$store.dispatch('syncEntries', {endpoint: '/integration/sync-clients', type: 'import_clients',value: false, sync: 'clients'})
            this.syncButtonDisable = false
        }, 2000)
      }
    },
    beforeUnmount() {
        // Clear the polling interval and timeout when navigating to another page
       clearInterval(this.$store.state.syncInterval)
       this.$store.commit('setSyncStatus', { syncError: '', syncUUIDSucces: false, syncLoader:false });
       if (this.syncTimeoutId) {
         clearTimeout(this.syncTimeoutId);
       }
    },
    watch:{
        date:{
            handler(newValue){
                if (newValue?.length > 0) {
                    this.debouncedLoadEntries()
                }
            },
        deep: false
        }
    }
}
</script>

<style lang="scss" scoped>
.p-checkbox-icon {
    line-height: normal !important;
}

input.p-inputtext::placeholder {
    color: #282828 !important;
    font-weight: normal !important;
    font-size: 15px !important;
}
.profileMaindiv{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 15px 30px;
    background-color: #F3F4F4;
    border: 1px solid #d5d7d7;
    border-radius: 8px;
    cursor: pointer;
}
.profileDetail{
    display: flex;
    gap: 40px;
    align-items: center;
}
.profileDetail>div{
    text-align: start;
}
.profileDetail>div>p>b{
    color: black !important;
}
.profileMaindiv:hover{
    background-color: #0870dc1f;
    border: 1px solid #077fffb5;
}
.filters {
  div {
    margin-right: 0px !important;
  }
}
.submit-button {
    margin-right: 0px !important;
}
.clear-button {
    margin-right: 0px !important;
}
.pendingborder{
border-radius: 8px 0px 0px 8px !important;
}
.destructionborder{
border-radius: 0px 8px 8px 0px !important;
}
.calendar-input-container{
    background-color: #F3F4F4;
    border: 1px solid #ced4da;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
input {
    max-height: 100% 
  }
</style>