<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel">
      <h3 class="panel-header">Actions</h3>
      <ActionsMenu />
    </div>
    <div class="panel">
      <!-- <h3 class="panel-header">Stocktake | <span style="color: darkslategray">{{ $store.state.currentLocation.label }}</span></h3> -->
      <h3 class="panel-header">Stocktake  | <span style="color: darkslategray">{{ $store.state.currentLocation.label }}</span></h3>
      <div class="drug-form-table table-container" style="padding: 23px">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center;">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center; margin-right: 1em">
            <div class="text-search-container search-container">
            <input v-model="form.filters.product_name" placeholder="Medication..." style="position: relative"  @input="debouncedLoadEntries()"/>
            <input v-model="form.filters.patient" v-if="$store.state.company.type != 'warehouse'" placeholder="Resident..." style="position: relative; margin-left: 10px;" />
            <div class="drug-search-result-container" v-if='form.filters.drug'  style="position: absolute; background-color: white; width: 196px; border: 2px solid #f6f6f6">
                <p class="drug-search-result" v-for="(item, index) in drugNames" :key="index" @click="selectDrugName(item)">{{item}}</p>
              </div>
              
            </div>
            <div class="date-search-container search-container">
              <Calendar v-model="date" selectionMode="range" placeholder="Last Count Date..."  dateFormat="dd/mm/yy" @input="debouncedLoadEntries()"/>
            </div>
            <button class="button submit-button" @click="loadStockTake()">Search</button>
            <button class="button clear-button" @click="clearStockTakeFilters()">Clear</button>
          </div>
          <!-- SELECT FILTER SECTION -->
          <div style="display: flex; align-items: center;">
            <div class="select-filters-container">

              <!-- <p @click="()=>{filters.all = true; filters.in_stock = false; filters.s8 = false;}" 
                  v-bind:class="[filters.all == true ? 'active-select-filter' : '']" class="select-filter-item">All</p> -->

              <!-- <p @click="()=>{filters.is_active = !filters.is_active; filters.all = false; autoAll()}" 
              v-bind:class="[filters.is_active == true && filters.all == false  ? 'active-select-filter' : '']" class="select-filter-item">Active</p> -->

              <!-- <p @click="()=>{filters.s8 = !filters.s8; autoAll()}" 
              v-bind:class="[filters.s8 == true ? 'active-select-filter' : '']" class="select-filter-item">Include S8</p> -->

              <p @click="()=>{form.filters.in_stock = !form.filters.in_stock;debouncedLoadEntries()}" 
              v-bind:class="[form.filters.in_stock == true ? 'active-select-filter' : '']" class="select-filter-item">Display In Stock Only</p>

              <p @click="()=>{form.filters.due_stocktake = !form.filters.due_stocktake;debouncedLoadEntries()}" 
              v-bind:class="[form.filters.due_stocktake == true ? 'active-select-filter' : '']" class="select-filter-item">Due for Stocktake</p>

              <p v-if="$store.state.company.type != 'warehouse'" @click="()=>{form.filters.imprest_only = !form.filters.imprest_only;debouncedLoadEntries() }" 
              v-bind:class="[form.filters.imprest_only == true ? 'active-select-filter' : '']" class="select-filter-item">Display Imprest Only</p>

              <p v-if="$store.state.company.type != 'warehouse'" @click="()=>{form.filters.residents_only = !form.filters.residents_only;debouncedLoadEntries()}" 
              v-bind:class="[form.filters.residents_only == true ? 'active-select-filter' : '']" class="select-filter-item">Display Residents Only</p>

              <p @click="()=>{form.filters.show_inactive = !form.filters.show_inactive;debouncedLoadEntries()}" 
              v-bind:class="[form.filters.show_inactive == true ? 'active-select-filter' : '']" class="select-filter-item">Include inactive products</p>

              <p @click="()=>{form.filters.s8 = !form.filters.s8;debouncedLoadEntries()}" 
              v-bind:class="[form.filters.s8 === true ? 'active-select-filter' : '']" class="select-filter-item">S8 Only</p>

              <p @click="()=>{form.filters.non_s8 = !form.filters.non_s8;debouncedLoadEntries()}" 
              v-bind:class="[form.filters.non_s8 === true ? 'active-select-filter' : '']" class="select-filter-item">Non S8 Only</p>
            </div>
            <!-- <div>
              <input type="checkbox" id="inactive" name="inactive" v-model="form.filters.show_inactive">
              <label for="inactive">Include inactive products</label><br>
            </div> -->
          </div>
          <!-- Sync -->
          <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && !$store.state.syncUUIDSucces" class="button submit-button" style="background-color: #b5a248;margin-left: 10px; min-width: 56px" @click="$store.dispatch('syncEntries', {endpoint: '/integration/sync-items', type: 'import_drug_items',value: true, sync: 'stocktakes'})" :disabled="$store.state.syncLoader"><i v-if="$store.state.syncLoader" style="font-size: 1.3rem !important;margin: auto;padding: 0px;" class="pi pi-spin pi-spinner" ></i>
            <span v-else>{{getCount ? 'Check Again' : 'Sync'}}</span>
          </button>
          <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && $store.state.syncUUIDSucces" class="button submit-button" style="background-color: #a0b302 ;margin-left: 10px" @click="refreshList()">
            Refresh
          </button>
          <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && $store.state.syncError" class="button submit-button" style="background-color: #e04f39 ;margin-left: 10px; max-width: 56px; min-width: 56px" @click="displaySuccessModal = true">
            Error
          </button>
          <p style="margin-left: 10px;" v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft')">Last synced: {{ getCount ? this.$store.state.syncLoader ? '--' : "The request is still in progress." : $store.getters.getProductItemsLastSynced }}</p>
        </div>
        <div v-if="!$store.state.loadingStockTakeData" style="display: flex; align-items: start; overflow-y: scroll">
        <table v-if='$store.state.user.role.permissions.includes("can_view_stock_take")' class="data-table" cellspacing="0" >
          <tr class="table-headers" >
            <th height=40px style="width: 20em">
              <h3 @click="sortBy('drug_cards.concat_name', 'desc')">Medication</h3>
              <div v-if="form.col == 'drug_cards.concat_name'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort drug-sort" v-if="form.col =='drug_cards.concat_name' && form.sort_direction =='asc'" @click="sortBy('drug_cards.concat_name', 'desc')"></i>
                <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug_cards.concat_name', 'asc')"></i>
              </div>
            </th>
            <th height=40px style="min-width: 100px" v-if="$store.state.company.type != 'warehouse'">
              <h3 @click="sortBy('personal_information.firstname', 'desc')">Resident</h3>
              <div v-if="form.col == 'personal_information.firstname'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort patient-sort" v-if="form.col =='personal_information.firstname' && form.sort_direction =='asc'" @click="sortBy('personal_information.firstname', 'desc')"></i>
                <i class="pi pi-sort patient-sort" v-else @click="sortBy('personal_information.firstname', 'asc')"></i>
              </div>
              
            </th>
            <th style="min-width: 100px">
              <h3 @click="sortBy('stocktake_items.created_at', 'desc')">Last Count</h3>
              <div v-if="form.col == 'stocktake_items.created_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  date-sort" v-if="form.col =='stocktake_items.created_at' && form.sort_direction =='asc'" @click="sortBy('stocktake_items.created_at', 'desc')"></i>
              <i class="pi pi-sort date-sort" v-else @click="sortBy('stocktake_items.created_at', 'asc')"></i>
              </div>
            </th>
            <th style="min-width: 90px">
              <h3 @click="sortBy('quantity', 'desc')">Expected</h3>
              <div v-if="form.col == 'quantity'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  expected-sort" v-if="form.col =='quantity' && form.sort_direction =='asc'" @click="sortBy('quantity', 'desc')"></i>
              <i class="pi pi-sort expected-sort" v-else @click="sortBy('quantity', 'asc')"></i>
              </div>
            </th>
            <th style="min-width: 100px">
              <h3>Counted</h3>
            </th>
            <th style="width: 80px">
              <h3>Diff</h3>
            </th>
            <th style="min-width: 100px">
              <h3>Status</h3>
            </th>

          </tr>
          <tr
            class="table-data"
            v-for="(item, index) in getDrugEntries"
            :key="index"
            :class="[item.count >=0 && item.count == item.qty && item.count != '' ? 'correct-status-color' : (item.count && item.count != item.qty? 'mismatch-status-color' : '')]"
          >
            <td height="40px">
              <span style="margin-right: 5px" v-if="item.alias?.tags && item.alias?.tags.includes('S8')" class="s8-tag">S8</span>
              <span style="margin-right: 5px" v-if="item.alias?.deprecated && $store.state.company.type == 'warehouse'" class="s8-tag">Deprecated</span>
              <b>{{item.type == 'pending destruction' ? `[PENDING ${this.$store.state.company.type == 'warehouse' ?  'DESTRUCTION' : 'DISPOSAL'}]`:''}}</b> 
              {{item.alias ? item.alias.name.charAt(0).toUpperCase() + item.alias.name.slice(1) : ''}}
            </td>
            <td v-if="$store.state.company.type != 'warehouse'">
              {{item.patient}}
            </td>
            <td>
              {{item.last_counted}}
            </td>
            <td v-if="item.alias && item.alias.form">
              {{item.qty}} {{!item.alias ? '' : item.alias.form + '(s)' }}
            </td>
            <td>
              <input type="number" min="0" @change="addToStockTake(item.uuid, $event.target.value, item), displayDiff(item, $event.target.value)" placeholder='Enter count'
              :disabled="$store.state.currentLocation.state == 'WA' && toCache.length && toCache[0].uuid != item.uuid" v-if="($store.state.currentLocation.state == 'WA' && (!toCache.length || toCache[0].uuid == item.uuid)) || $store.state.currentLocation.state != 'WA'" :value="item.count" style="width: 80%">
              <!-- <div  v-for="(cacheItem, key) in toCache" :key="key">
                <input v-if="cacheItem.uuid == item.uuid" type="number" @change="addToStockTake(item.uuid, $event.target.value)" :value="cacheItem.count" placeholder='Enter count'>
              </div> -->
            </td>
            <td>{{item.count ? (item.count - item.qty).toFixed(2) : ''}}</td>
            <td>
              <p :class="[item.status == 'Overdue' ? 'mismatch-status-color' : (item.status == 'N/A' && item.last_counted == 'N/A') ?  'warning-status-color' : 'blue-status-color']"
              style="font-weight: bold !important"
              :style="item.status == 'Overdue' ? {color: '#E04F3 !important'}:{}">{{item.status == 'Overdue' ? 'Stocktake Now' : (item.status == 'N/A' && item.last_counted == 'N/A') ? 'Never Counted': 'Counted'}}</p>
            </td>
          </tr>
        </table>
        <div class="drug-register-table table-container" style="padding-top: 10em;" v-else >
          <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
        </div>
        </div>
        <div v-else>
          <h3 v-if="$store.state.stockError">{{$store.state.stockError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links" v-if="$store.state.drugEntries.current_page">
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state.drugEntries.current_page}} of {{$store.state.drugEntries.page_count}}</p>
            <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count" @click="displayPage( $store.state.drugEntries.current_page + 1)"> &rarr; </p>
          </div>
        <NewStockTake :toCache="toCache" @clear="clearStockValues" @reload="reload" />
       </div>
       <SuccessModal @close="closeSuccesModal" v-if="displaySuccessModal" :message="$store.state.syncError" :first_spec_line="$store.state.syncError?.response?.data?.errors ? $store.state.syncError?.response?.data?.message + ' ' + Object.values($store.state.syncError?.response?.data?.errors).flat()[0] : $store.state.syncError" statusMessage="Sync ProductItems" />
    </div>
  </div>
</template>


<script>
// @ is an alias to /src
import ActionsMenu from '@/components/actions/ActionsMenu.vue';
import NewStockTake from '@/components/actions/NewStockTake.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import debounce from "debounce";
import axios from 'axios';

export default {
  components: {
    ActionsMenu,
    NewStockTake,
    SuccessModal
},
  data(){
    return{
      displaySuccessModal: false,
      loadingError:'',
      drugNames: [],
      registerData: [],
      filters:{
          s8: true
      },
      toCache:[],
      date:[],
      clearForm:{
        sort_direction: '',
        col: '',
        page: 1,
          location: this.$store.state.uuid,
        filters:{
          tags:[],
          show_inactive: false,
          imprest_only: false,
          residents_only: false,
          in_stock: true,
          due_stocktake: false,
          date:{
            start:'',
            end:''
          },
          product_name:'',
          patient:''
        }
      },
      form:{
        sort_order: [],
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          tags:[],
          show_inactive: false,
          imprest_only: false,
          residents_only: false,
          in_stock: true,
          due_stocktake: false,
          s8: false,
          non_s8: false,
          date:{
            start:'',
            end:''
          },
          product_name:'',
          patient:''
        }
      },
      drugEntries:[
        
      ],
    }
  },
  methods:{
    reload(value){
      if(value){
        this.toCache=[];
        this.$store.commit('updateCount', this.toCache);
        this.loadStockTake();
        
      }
    },
    clearStockValues(value){
      console.log('clear has been requested')
      if(value == true){
        this.toCache = [];
      }
      this.$store.commit('updateCount', this.toCache);
      this.$store.dispatch('getStockTake', this.form);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      let order = this.form.sort_order.find(order => order.col === col);
      if (order) {
        order.sort_direction = order.sort_direction == 'asc' ? 'desc' : 'asc';

        this.form.col = order.col;
        this.form.sort_direction = order.sort_direction;
      } else {
        this.form.sort_order.push({ col: col, sort_direction: 'asc' });
        
        this.form.col = col;
        this.form.sort_direction = 'asc';
      }

      this.$store.dispatch('getStockTake', this.form)
    },
    debouncedLoadEntries: debounce(function() {
      this.loadStockTake();
    }, 1000),

    addToStockTake(id, countValue, stock){
        // console.log('this is the drug and count', id, countValue);
        if(!this.toCache.length){
          // console.log('there is nothing in the cache array right now');
          console.log('this is the count and qty', countValue, stock.qty);
          this.toCache.push({uuid: id, count: Number(countValue), expected:stock.qty, type: stock.type, name: stock.alias.name, adjustmentRequired: countValue != stock.qty, tags: stock.alias.tags});
        } else{
          let listOfIds = [];

          this.toCache.forEach((item)=>{
            listOfIds.push(item.uuid);
          })
          if(!listOfIds.includes(id) && countValue >= 0){
            console.log('this is the count and qty', countValue, stock.qty);
            this.toCache.push({uuid: id, count: Number(countValue), expected:stock.qty, type: stock.type, name: stock.alias.name, adjustmentRequired: countValue != stock.qty, tags: stock.alias.tags})
          } else{
            this.toCache.forEach((item,index)=>{
              if(item.uuid == id){
                if(countValue === 0 || countValue > 0){
                  item.count = Number(countValue);
                } else{
                  this.toCache.splice(index, 1);
                }
              }
              if(isNaN(item.count)){
                this.toCache.splice(index, 1);
              }
            })
          }
        }
        this.$store.commit('updateCount', this.toCache);
        // this.$store.dispatch('getStockTake', this.form);
    },
    displayDiff(item, countValue){
      item.count = countValue;
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadStockTake(){
      console.log('this is the date', this.date);
      if(this.date.length){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString('en-AU');
          this.form.filters.date.end = this.date[1] ? new Date(this.date[1]).toLocaleDateString('en-AU') : new Date(this.date[0]).toLocaleDateString('en-AU');
        }
        this.$store.dispatch('getStockTake', this.form);
      } else{
        this.form.filters.date.start ='';
        this.form.filters.date.end = '';
        this.$store.dispatch('getStockTake', this.form);
      }
      console.log('request: ', this.$store.state.latestDrugEntriesMutationRequest);
    },
    closeSuccesModal(){
       this.displaySuccessModal = false
      },
    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getStockTake', this.form);
    },
    autoAll(){
        this.form.filters.tags = [];
        for (const [key, value] of Object.entries(this.filters)) {
            if(value == true){
                this.form.filters.tags.push(key)
            }
        }
        console.log('this is what is inside the form tags now', this.form.filters.tags);
    },
    clearStockTakeFilters(){
      this.form = {
        sort_order: [],
        sort_direction: '',
        col: '',
        page: 1,
        filters:{
          tags:[],
          show_inactive: false,
          imprest_only: false,
          residents_only: false,
          in_stock: true,
          due_stocktake: false,
          s8: false,
          non_s8: false,
          product_name:'',
          patient:'',
          date:{
            start:'',
            end:''
          },
          syncButtonDisable: false
        }
      };
      this.date = [];
      this.$store.dispatch('getStockTake', this.form);
    },
    refreshList(){
      this.$store.dispatch('refreshSyncList', 'stocktakes')
     this.loadStockTake()
     clearInterval(this.$store.state.syncInterval)
    },
  },
   mounted(){
      this.loadStockTake();
      this.toCache = [];
      this.$store.dispatch('emptyStockCache');
   // Call the sync function if the user stays on the page for 2 seconds after the page is rendered
   if (this.$store.state.user.integration_type === 'carton-cloud' || this.$store.state.user.integration_type === 'microsoft') {
     this.syncButtonDisable = true
     this.syncTimeoutId = setTimeout(() => {
       this.$store.dispatch('syncEntries', {endpoint: '/integration/sync-items', type: 'import_drug_items',value: false, sync: 'stocktakes'})
       this.syncButtonDisable = false
     }, 2000)
   }
  },
  beforeUnmount(){
    // Clear the polling interval and timeout when navigating to another page
      clearInterval(this.$store.state.syncInterval)
      this.$store.commit('setSyncStatus', { syncError: '', syncUUIDSucces: false, syncLoader:false });
      if (this.syncTimeoutId) {
        clearTimeout(this.syncTimeoutId);
      }
  },
  computed:{
    getDrugEntries (){
      return this.$store.getters.getDrugEntries
    },
    getDrugNames(){
      return this.$store.getters.getDrugNames
    },
    getCount(){
        return (this.$store.state.LastSync?.productItemsLastSynced?.count >= 12 || this.$store.state.syncError) ? true : false
    },
  },
  watch:{
    date:{
     handler(newValue){
      if (newValue?.length > 0) {
        this.debouncedLoadEntries()
      }
     },
     deep: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-data:nth-child(odd){
    background-color: #f9f9f9;
  }
</style>