<template>
    <div class="two-row-container table-container" style="width: 81.5vw; border-radius: 0; box-shadow: none; padding: 10px 0px 10px 0px;">
        <div class="name-filters-section" style="display: flex; justify-content:space-between; align-items: center">
            <div style="display: flex; align-items: center;">
                <h3 style="color: black; text-align: left; margin: 0; padding: 0; display: inline; margin-right: 10px;">
                    Medications</h3>
                

            </div>
            <div class="filters">
                <div style="display: flex; align-items: center; margin-right: 20px">
                    <div style="display: flex; align-items: center;" v-if="requiringAction > 0">
                        <p class="outline-button"
                            style="background-color: #E04F39; border: 2px solid #E04F39; font-weight: bold; color: white; margin-right: 5px;">
                            {{ requiringAction }}</p>
                        <h4 style="color: #E04F39;">Requiring Action</h4>
                    </div>
                    <button  class="button blue-button"
                        v-if="!chartReview && expiredCharts && expiredCharts.length && ($store.state.userType == 'prescriber' || $store.state.user?.role?.permissions?.includes('can_dismiss_expired_charts'))"
                        @click="dismissExpiredCharts()"
                        style="font-weight: bold; margin: 0; margin-left: 20px; background-color: #E04F39 !important; color: white;">
                        Dismiss All Expired Charts ({{ expiredCharts.length }})</button>
                    <button  class="button blue-button"
                        v-if="!chartReview && $store.state.user.role.permissions.includes('can_create_draft_prescription')"
                        @click="displayPrescriptionForm()"
                        style="font-weight: bold; margin: 0; margin-left: 20px; background-color: #0870DC; color: white;">
                        + Create New Chart</button>
                    <button  class="blue-button"
                        v-if="!chartReview && $store.state.userType == 'prescriber'"
                        @click="chartReview = true, chartReviewList()"
                        style="font-weight: bold; margin: 0; margin-left: 20px; background-color: #0870DC; color: white;">
                        Chart Review</button>
                    <div v-if="chartReview && $store.state.userType == 'prescriber'"
                        style="display: flex; margin-left: 10px; align-items: center;">
                        <button class="red-button" @click="chartReview = false; emptyReviewPrescriptionList()"
                            style="font-weight: bold; margin: 0; margin-left: 20px; background-color: #0870DC; color: white;">
                            Cancel Review</button>
                        <button class="blue-button" v-if="unreviewedCharts.length"
                            @click="selectAllPrescriptionsForReview()"
                            style="font-weight: bold; margin: 0; margin-left: 20px; background-color: #0870DC; color: white;">
                            Select All</button>
                        <button class="green-button" v-if="!unreviewedCharts.length"
                            @click="signChartReview()"
                            style="font-weight: bold; margin: 0; margin-left: 20px; background-color: #0870DC; color: white;">
                            Sign Off Review</button>
                    </div>
                </div>
                <!-- <div class="text-search-container search-container">
                    <Dropdown @change="sortMedicationCharts()" v-model="form.filters.drug" :options="getDrugsFilter"
                        placeholder="Medication" class="medication-search-dropdown" style="position: relative; width: 12em" />
                </div> -->
                <!-- <div class="text-search-container search-container">
                    <Dropdown @change="sortMedicationCharts()" v-model="form.filters.frequency"
                        :options="getFrequenciesFilter" placeholder="Frequency" style="position: relative" />
                </div>
                <div class="text-search-container search-container">
                    <Dropdown @change="sortMedicationCharts()" v-model="form.filters.route" :options="getRoutesFilter"
                        placeholder="Route" style="position: relative" />
                </div> -->
                <!-- <div class="text-search-container search-container" style="margin-right: 0;">
                    <Dropdown v-model="form.filters.type" :options="getTypesFilter" optionValue="chart_name"
                        optionLabel="name" @change="sortMedicationCharts()" placeholder="Type"
                        style="position: relative" />
                </div> -->
                <button v-if="form.filters.frequency || form.filters.drug || form.filters.route || form.filters.type"
                         class="button" @click="emptyFilters()"
                    style="background-color: transparent; border: 2px solid #0870DC; color: #0870DC; margin:0px;">Clear
                    <i class="pi pi-filter-slash"></i></button>
                <button v-tooltip.bottom="'Download'" class="button" @click="saveResident()"
                    style="background-color: transparent; color: white; margin-top: 0px;"><i
                        class="pi pi-download"></i></button>
                <button v-tooltip="'Print'" class="button" @click="printResident()"
                    style="margin-right: 1em; background-color: transparent; color: white"><i class="pi pi-print"
                        style="font-size: 1.1em !important; border-radius: 50%; padding: 8px; background-color: #0870DC; margin-top: -4px;"></i></button>

            </div>
        </div>

        <div style="overflow-y: scroll">
            <div class="drug-register-table table-container" style="padding-top: 10em;"
                v-if='!$store.state.user.role.permissions.includes("can_view_prescription")'>
                <h3
                    style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
                    You do not have permission to access this feature. Please talk to your admin if you require access.
                </h3>
            </div>
            <div v-else>
                <div v-if="loadingCharts">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                </div>
                <table v-else class="data-table audit-table" id="transaction-table" cellspacing="0">
                    <!-- LOADING SPINNER -->

                    <tr class="table-headers">
                        <th style="width: 1em" class="expand-column"></th>
                        <th style="width: 1em"></th>
                        <th style="width: 4.5em">
                            <h3>Start-Stop Date</h3>

                        </th>
                        <th style="width: 13em">
                            <h3>Medication</h3>
                        </th>
                        <th style="width: 3em">
                            <h3>Dose</h3>
                        </th>

                        <th style="width: 5em">
                            <h3>Frequency</h3>
                        </th>
                        <th style="width: 3em">
                            <h3 @click="sortBy('action', 'desc')">Route</h3>
                            <div v-if="form.col == 'action'" style="display: inline; cursor: pointer">
                                <i class="pi pi-sort action-sort"
                                    v-if="form.col == 'action' && form.sort_direction == 'asc'"
                                    @click="sortBy('action', 'desc')"></i>
                                <i class="pi pi-sort action-sort" v-else @click="sortBy('action', 'asc')"></i>
                            </div>
                        </th>
                        <!-- <th style="width: 9.5em">
                            <h3 @click="sortBy('login', 'desc')">Prescriber</h3>
                            <div v-if="form.col == 'login'" style="display: inline; cursor: pointer">
                                <i class="pi pi-sort  login-sort"
                                    v-if="form.col == 'login' && form.sort_direction == 'asc'"
                                    @click="sortBy('login', 'desc')"></i>
                                <i class="pi pi-sort login-sort" v-else @click="sortBy('login', 'asc')"></i>
                            </div>
                        </th> -->
                        <th style="width: 3em">
                            <h3 @click="sortBy('signature', 'desc')">Status</h3>
                            <div v-if="form.col == 'signature'" style="display: inline; cursor: pointer">
                                <i class="pi pi-sort  signature-sort"
                                    v-if="form.col == 'signature' && form.sort_direction == 'asc'"
                                    @click="sortBy('signature', 'desc')"></i>
                                <i class="pi pi-sort signature-sort" v-else @click="sortBy('signature', 'asc')"></i>
                            </div>
                        </th>
                        <th style="width: 5em">
                            <h3 @click="sortBy('location', 'desc')">Type</h3>
                            <div v-if="form.col == 'location'" style="display: inline; cursor: pointer">
                                <i class="pi pi-sort  location-sort"
                                    v-if="form.col == 'location' && form.sort_direction == 'asc'"
                                    @click="sortBy('location', 'desc')"></i>
                                <i class="pi pi-sort location-sort" v-else @click="sortBy('location', 'asc')"></i>
                            </div>
                        </th>
                        <th style="width: 4em"><h3>Dispense</h3></th>
                        <th style="width: 4em" v-if="$store.state.userType == 'prescriber'">Renew</th>
                    
                    </tr>
                    <tbody class="table-data" v-for="(item, index) in allCharts" :key="index" >

                        <tr :class="[item.display && (item.display == true) ? 'open-transfer-row' : '']"
                            @click="() => { showContent(item, index); item.checkHeight = true; }"
                            :style="chartReview && (!reviewPayload?.prescriptions[index]?.expired && (reviewPayload?.prescriptions[index]?.status == 'continue' || reviewPayload?.prescriptions[index]?.status == 'reviewed' || reviewPayload?.prescriptions[index]?.status == 'edited')) ? { backgroundColor: 'rgba(0, 173, 80, 0.3)' } : (chartReview && reviewPayload?.prescriptions[index]?.status == 'cancelled') || item.expired ? { backgroundColor: 'rgba(224, 79, 57, 0.2)' }:{}">
                            <td style="min-width: 30px;" class="expand-column" :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important', borderLeft:'1.5px solid #0870DC !important', borderBottomLeftRadius:'6px', borderTopLeftRadius:'6px'}:{}">
                                <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                                <i v-else class="pi pi-angle-right"></i>
                            </td>
                            <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >
                                <div style="display:flex; place-items: center">
                                    <div v-if="(!item.expired) && (!item.expired && !(!item.signatures || !item.signatures.length))">
                                        <div style="height: 20px; width: 20px; border-radius: 10px; !important;" 
                                            v-tooltip.right="
                                                item.expiring_in == '14D' ? 'Expiring within 14 days' :
                                                item.expiring_in == '3D'  ? 'Expiring within 3 days' :
                                                item.expiring_in == '24H' ? 'Expiring within 24 hours' : ''
                                            "
                                            :style="
                                                item.expiring_in == '14D' ? {backgroundColor: '#f3c400'} :
                                                item.expiring_in == '3D'  ? {backgroundColor: '#f38600'} :
                                                item.expiring_in == '24H' ? {backgroundColor: '#f33100'} : {}
                                            ">
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >
                                {{ item.dateRange }}
                            </td>

                            <!-- <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" ><span style="margin-right: 5px;" :class="item.authority_prescription_number ? 'red-tag' : item.pbs_code ? 'orange-tag' : 'blue-tag'">{{ item.authority_prescription_number ? 'AUTH' : item.pbs_code ? 'PBS' : 'NON-PBS' }}</span>{{ item.alias && item.alias.name ? `${item.alias.name}` : 'N/A' }}</td> -->
                            <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >
                                <span style="margin-right: 5px;" :class="item.authority_prescription_number || item.pbs_authority_code ? 'red-tag' : item.pbs_code ? 'orange-tag' : 'blue-tag'">{{ item.pbs_type == 'A' ? 'AUTH' : item.pbs_type == 'S' ? 'S/LINE' : item.pbs_code ? 'PBS' : 'NON-PBS' }}</span>
                                <span style="margin-right: 5px" v-if="item.phone_order" class="green-tag">PHONE</span>
                                <!-- Formats drug names to: "(Generic name) Brand name" OR just "Brand name" if brand and generic are the same -->
                                <span style="margin-right: 5px" v-if="item.alias.tags && item.alias.tags.includes('S8')" class="s8-tag">S8</span>
                                {{ item.alias && item.alias.generic_name && !(item.alias.name.toLowerCase().includes(item.alias.generic_name.toLowerCase())) ? `(${item.alias.generic_name.slice(0,1).toUpperCase() + item.alias.generic_name.slice(1)}) ` : null }}
                                {{ item.alias && item.alias.name ? `${item.alias.name.slice(0,1).toUpperCase() + item.alias.name.slice(1)}` : 'N/A' }}
                            </td>
                            <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" class="truncate-text" >x{{ modifyDose(item) }} {{ modifyUnit(item) }}</td>
                            <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >
                                {{ item.frequency_shorthand }}
                                
                                <span v-if="item.chart_type != 'prn' && item.dose_times.length > 0" style="font-size: 13px">
                                    <b>({{ item.dose_times[0].times.map(time => time.time).join(', ') }})</b>
                                </span>
                            </td>
                            <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" class="truncate-text"  >{{ item.route }}</td>
                            <!-- <td style="width: 15em">{{ item.doctor && item.doctor.personal_information &&
                                    item.doctor.personal_information.first_name ?
                                    item.doctor.personal_information.first_name + ' \
                                                            '+ item.doctor.personal_information.last_name : ''
                            }}</td> -->
                            <td :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}" >
                                <!-- SIGNED ORDER -->
                                <p v-if='!item.expired && item.signatures && item.signatures.length && (item.phone_order ? (item.prescriber_signed ? true : false) : true)'
                                    style="margin: 0;display: flex; align-items: center; font-weight: bold; width: 6em; justify-content: center; color: white; border-radius: 6px; padding: 3px 0; background-color: #00AD50">SIGNED</p>
                                <!-- UNSIGNED PHONE ORDERS -->
                                <p v-if='(item.signatures && item.signatures.length && (item.phone_order ? (!item.prescriber_signed ? true : false) : false)) || item.expired'
                                    style="margin: 0;display: flex; align-items: center; font-weight: bold; width: 6em; justify-content: center; color: white; border-radius: 6px; padding: 3px 0; background-color: #E04F39">{{ item.expired ? 'EXPIRED' : 'REQ. SIGN' }} </p>
                                <!-- DRAFT ORDERS -->
                                <p v-if="!item.expired && (!item.signatures || !item.signatures.length)" 
                                    style="margin: 0;display: flex; align-items: center; font-weight: bold; width: 6em;justify-content: center; color: white; border-radius: 6px; padding: 3px 0; background-color: #E04F39">DRAFT</p>
                            </td>

                            <td style="color: white; font-weight: bold; padding: 0px 5px; margin-right: 5px;" :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}">
                                <p style="padding:5px; border-radius: 8px; text-align: center; width: 10em"
                                    :style="{ backgroundColor: item.color }">{{ item.chart_name && item.chart_name != 'Once Only Meds (Stat)' ?  item.chart_name.split(' ')[0] :  item.chart_name == 'Once Only Meds (Stat)' ? 'Stat': '' }}</p>
                            </td>
                            <td style="color: white; font-weight: bold; padding: 0px 5px; margin-right: 5px;" :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important'}:{}">
                                <p v-if='(item.signatures && item.signatures.length && (item.phone_order ? (item.prescriber_signed ? true : false) : true)) || item.expired' 
                                @click="item.expired && (!item.dispensed_items || !item.dispensed_items.length) ? false: (displayQuickDispenseModal = true, reviewedPrescription = item)" :class="item.expired ? 'grey-button' : !item.dispensed_items || !item.dispensed_items.length ? 'blue-button' : 'green-button'" 
                                style="width: 7.5em">{{ item.dispensed_items && item.dispensed_items.length ? 'Dispensed' : 'Dispense' }}</p>
                            </td>
                            <!-- @click.stop="item.expired && item.pbs_type != 'A' && (item.chart_name && item.chart_name != 'Intermittent') && !item.phone_order ? 
                            ()=>{item.renewExpired = true; showContent(item, index); item.checkHeight = true}  
                            : ''"  -->
                            <td v-if="$store.state.userType == 'prescriber'" 
                            
                            :style="item.display == true ? {borderBottom:'1.5px solid #0870DC !important', borderTop:'1.5px solid #0870DC !important', borderRight:'1.5px solid #0870DC !important', borderBottomRightRadius:'6px', borderTopRightRadius:'6px'}:{}">
                                
                                <span class="green-button renewing-expired" ref="child" @click.stop="() => { !item.display ? showContent(item, index) : ''; item.checkHeight = true; item.renewExpired ? item.renewExpired = !item.renewExpired :  item.renewExpired = true} " v-if="item.expired && item.pbs_type != 'A' && (item.chart_type && item.chart_type != 'intermittent') && !item.phone_order" ><span class="pi pi-refresh"></span></span>
                            </td>

                        </tr>

                        <tr :ref="'expand-' + index" :id="'expand-' + index" class="expanded-content" >
                            
                            <div class="expanded-transfer-container"
                                style="width: 81.5vw; display: grid; padding: 10px;"
                                :ref="'prescriptionchart-' + index" >
                                <div style="display: flex; gap: 10px">
                                    <div style="display: flex; padding: 10px; padding-bottom: 0px; margin-bottom: -20px; cursor: pointer" @click="displayPrescriberDetailsModal = true" v-if="item.doctor">
                                        <button class="blue-button" >Prescriber Details</button>
                                    </div>
                                    <div style="display: flex; padding: 10px; padding-bottom: 0px; margin-bottom: -20px; cursor: pointer" @click="displayNurseDetailsModal = true, nurses = {primary: item.nurse_primary, witness: item.nurse_witness}" v-if="item.phone_order && item.nurse_signatures?.length" >
                                        <button class="blue-button" >Nurses' Details</button>
                                    </div>
                                </div>
                                
                                <!-- <div style="display: flex; gap: 10px; padding-left: 10px;">
                                    <div>
                                        <div>
                                            <p style="margin: 0; text-align: left; margin-left: 10px; font-size: 1em; font-weight: 500; ">
                                                Prescriber Name
                                            </p>
                                        </div>
                                        <span class="p-input-icon-right">
                                            <InputText disabled
                                                style="width: 100%; border: 1px solid #ced4da; background-color: white"
                                                type="text"
                                                :placeholder="item.doctor ? `${item.doctor.personal_information.first_name} ${item.doctor.personal_information.last_name}` : 'N/A'"
                                            />
                                        </span>
                                    </div>

                                    <div>
                                        <div>
                                            <p style="margin: 0; text-align: left; margin-left: 10px; font-size: 1em; font-weight: 500; ">
                                                Prescriber Number
                                            </p>
                                        </div>
                                        <span class="p-input-icon-right">
                                            <InputText disabled
                                                style="width: 100%; border: 1px solid #ced4da; background-color: white"
                                                type="text"
                                                :placeholder="item.doctor && (item.doctor?.regulatory_information.length > 0) ? `${item.doctor.regulatory_information[0].data}` : 'N/A'"
                                            />
                                        </span>
                                    </div>
                                </div> -->
                                <div>
                                    
                                    <PrescriptionChart
                                        :key="index"
                                        v-if="frequencyOptions.length && item.activePresChart && item.activePresChart.prescription_payload"
                                        :ref="'chartcomponent' + index"
                                        :parentFunction="parentFunction"
                                        :parentIndex="index"
                                        :checkHeight="item.checkHeight"
                                        :activePresChart="item.activePresChart"
                                        :expired="item.expired"
                                        @updateHeightCheck="updateHeightCheck"
                                        :chartReview="chartReview"
                                        @addToReview="addToReview"
                                        @removeFromReview="removeFromReview"
                                        :reviewed="reviewedPrescriptions.includes(item.uuid)"
                                        :draftDrug="item.alias ? item.alias : ''" 
                                        :patientId="$route.params.id"
                                        :patient="patient"
                                        :displayDropDown="false"
                                        :signNow="item.signNow"
                                        @isSuccessful="isSuccessful"
                                        @dismissExpiredScript="dismissExpiredScript"
                                        @displayItem="displayItem"
                                        :activeSelectedChart="item.selectedChart"
                                        :isDraft="item.signatures && item.signatures.length ? false : true"
                                        :frequencyOptions="frequencyOptions"
                                        :administration_history="item.administration"
                                        :disableAll="(($store.state.user.type == 'nurse' && item.phone_order && item.prescriber_signed) || ($store.state.user.type == 'prescriber' && item.pbs_type == 'A' && item.phone_order) || ($store.state.user.type == 'prescriber' && item.pbs_type == 'A')) || item.expired ? true: false"
                                        :phoneOrder="item.phone_order"
                                        :prescriberSigned="item.prescriber_signed"
                                        :nextReviewDate="nextReviewDate"
                                        :renewExpired="item.renewExpired"
                                    />
                                </div>
                            </div>
                            <div class="transfer-actions-container" style="width: 88vw" v-if="item.expandedInfo">

                                <button @click="showContent(item, index)"
                                    style="background-color: white; padding: 0;"><i class="pi pi-times"></i></button>
                            </div>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div v-if="$store.state.reportError">
                <h3 style="color: #E04F39">{{ $store.state.reportError }}</h3>
            </div>
        </div>
        <!-- @isSuccessful="isSuccessful" -->
        <ExportCSVModal v-if="displayExportCSVModal" @close="close" :exportForm="form" />
        <QuickDispenseModal v-if="displayQuickDispenseModal" @close="close" :reviewedPrescription="reviewedPrescription" />
        <!-- <PatientSummaryModal v-if="expandPatientSummaryModal" @close="close" :patientId="$route.params.id" /> -->
        <PrescriberDetailsModal v-if="displayPrescriberDetailsModal" :doctor="doctor" @close="closePrescriberDetailsModal" />
        <NurseDetailsModal v-if="displayNurseDetailsModal" :nurses="nurses" @close="closeNurseDetailsModal" />
        <SignatureModal v-if="displaySignatureModal" :payload="payload" @close="close" :endpoint="endpoint"
            @isSuccessful="isSuccessful" />
        <SuccessModal v-if="displaySuccessModal" @close="close" :message="successMessage" />
        <ChartReviewSummaryModal v-if="displayChartReviewSummaryModal" @close="close" @isSuccessful="isSuccessful" :action="true" :actionType="'sign'" @successfulReview="successfulReview"
        :signature_endpoint="`/person/${$route.params.id}/submit-chart-review`" :payload="reviewPayload" :numSigned="signedPrescriptions.length" :numDraft="draftPrescriptions.length" :numCancelled="cancelledPrescriptions.length" />
        <!-- <SuccessModal :loading="loading" @close="close" v-if="displaySuccessModal" :action="signature" actionType='sign'
            :payload="prescription_sign_payload" :statusMessage="displayMessage ? 'Completed':'Processing...'" 
            :first_spec_line="displayMessage" :second_spec_line="displayMessage2" signature_endpoint="/prescription/sign" /> -->
    </div>
</template>


<script>
// @ is an alias to /src
import ExportCSVModal from '@/components/modals/ExportCSVModal.vue';
import PrescriberDetailsModal from '@/components/modals/PrescriberDetailsModal.vue';
import NurseDetailsModal from '@/components/modals/NurseDetailsModal.vue';
import ChartReviewSummaryModal from '@/components/modals/ChartReviewSummaryModal.vue';
import QuickDispenseModal from '@/components/modals/QuickDispenseModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
import PrescriptionChart from '../PrescriptionChart.vue';
import axios from 'axios';


export default {
    components: {
        PrescriptionChart,
        PrescriberDetailsModal,
        NurseDetailsModal,
        ExportCSVModal,
        ChartReviewSummaryModal,
        QuickDispenseModal,
        SignatureModal,
        SuccessModal
    },
    props: ['charts', 'patientId', 'patient', 'frequencies', 'drugs', 'routes', 'types', 'loadingCharts', 
    'reviewedPrescriptions', 'facility_id', 'expiredCharts', 'nextReviewDate', 'dismissAllCharts'],
    data() {
        return {
            displayQuickDispenseModal: false,
            displayChartReviewSummaryModal: false,
            displayPrescriberDetailsModal: false,
            displayNurseDetailsModal: false,
            displaySignatureModal: false,
            displaySuccessModal:false,
            reviewPayload:{
                prescriptions:[],
                chart_review_id:''
            },
            successMessage:'',
            payload: {},
            endpoint: '',
            chartReview: false,
            expandPatientSummaryModal: false,
            requiringAction: 0,
            displayExportCSVModal: false,
            drugNames: [],
            clearForm: {
                filters: {
                    drug: '',
                    frequency: '',
                    route: '',
                    type: ''
                }
            },
            form: {
                filters: {
                    drug: '',
                    frequency: '',
                    route: '',
                    type: ''
                }
            },
            testData: [],
            frequencyOptions:[],
            prescriberResults:[],
            doctor:null,
            nurses: null,
            timeout: null
        }
    },
    methods: {
        parentFunction(){
            console.log('childTriggerRenew - parent function is being called');
        },
        dismissExpiredScript(id){
            if(id){
                console.log('dismissing an expired script', id);
                this.charts.forEach((item, index)=>{
                    console.log('comparing ids', item.uuid, id);
                    if(item.uuid == id){
                        console.log('this is the expired index', index);
                        item.hidden = true;
                        this.$emit('dismissExpiredChart', index);
                        
                    }
                })
            }
        },
        closeNurseDetailsModal(value){
            if(value){
                this.displayNurseDetailsModal = false;
            }
        },
        modifyDose(item) {

            if (item.max_admin_dose > 1 && item.max_admin_dose > item.dose) {
                return "(" + item.dose + "-" + item.max_admin_dose + ")";
            } else {
                return item.dose.toString();
            }
        },
        modifyUnit(item) {
            if((item.dose > 1) || (item.max_admin_dose > 1))
            {
                return item.dose_unit + "(s)"
            }
            else
            {
                return item.dose_unit
            }
        },
        gpSearch(prescriber) {
            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }
            // let results = [];
            this.prescriberResults = [];
            console.log('this is the prescriber', prescriber);
            // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
            axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/*/queries`,
                // params: `query=${prescriber}&hitsPerPage=5`,
                {
                    requests: [
                        { "indexName": `${this.$store.state.subdomain}_prescribers`, "params": `query=${prescriber}` },
                    ]
                },
                { headers }).then(res => {
                    console.log('this is the algolia res', res.data.results);

                    res.data.results.forEach(indexResult => {
                        console.log('these are the results for this particular index', indexResult);
                        if (indexResult.hits.length) {
                            this.doctor = indexResult.hits[0];
                            if (this.doctor.contact_methods.length) {
                                this.doctor.contact_methods.forEach(method => {
                                    if (method.name == 'Phone') {
                                        this.doctor.phone = method.data;
                                    }
                                    if (method.name == 'Email') {
                                        this.doctor.email = method.data;
                                    }
                                })
                            }

                        } else {
                            // fallback for when algolia sometimes returns no hits;
                            const { street_address, postcode, suburb, city, state, country } = this.patient.primary_prescriber.address;
                            this.doctor = {
                                first_name: this.patient.primary_prescriber.personal_information.first_name,
                                last_name: this.patient.primary_prescriber.personal_information.last_name,
                                address: `${street_address}, ${suburb}, ${city}, ${state}, ${postcode}, ${country}`
                            };
                            this.patient.primary_prescriber.contact_methods.forEach(method => {
                                if (method.name == 'Phone') {
                                    this.doctor.phone = method.data;
                                }
                                if (method.name == 'Email') {
                                    this.doctor.email = method.data;
                                }
                            });
                        }

                    })
                })

        },
        successfulReview(value) {
            if (value) {
                console.log('this is a successful review', value);
                this.chartReview = false;
            }
        },
        chartReviewList() {
            if (this.chartReview) {
                this.reviewPayload.prescriptions = [];
                this.$store.dispatch('getRefreshToken');
                axios.post(`/person/${this.$route.params.id}/create-chart-review`, {}, this.$store.state.header).then(res => {
                    this.reviewPayload.chart_review_id = res.data.chart_review_id;
                })
                this.$emit('removeExpiredCharts', true)
                console.log('generating chart review list')
                this.charts.forEach(chart => {
                    console.log('chart has authority prescription number', chart.authority_prescription_number ? true : false);
                    let isS8 = false;
                    if(chart.alias.tags.includes('S8')){
                        isS8 = true;
                    }
                    
                    if(!chart.pbs_type != 'A' && !chart.expired){
                        this.reviewPayload.prescriptions.push({
                            prescription_id: chart.uuid,
                            pbs_type: chart.pbs_type,
                            chart_type: chart.chart_type,
                            status: '',
                            phone_order: chart.phone_order,
                            draft: chart.signatures && chart.signatures.length ? false: true,
                            authority: chart.pbs_type == 'A' ? true: false,
                            is_s8: isS8,
                        })
                    }
                    
                })
            }
        },
        displayPrescriptionForm() {
            this.$emit('displayPrescriptionForm', true);
        },
        addToReview(value) {
            if (value) {
                this.$emit('addingToReview', value);
                this.reviewPayload.prescriptions.forEach(prescription => {
                    if (prescription.prescription_id == value.prescription_id) {
                        prescription.status = value.status;
                    }
                })
                if (value.new) {
                    this.reviewPayload.prescriptions.push({
                        prescription_id: value.prescription_id,
                        chart_type: value.chart_type,
                        status: value.status
                    })
                }
            }
        },
        removeFromReview(value) {
            if (value) {
                this.$emit('removingFromReview', value)
            }
        },
        selectAllPrescriptionsForReview() {
            this.charts.forEach(chart => {
                this.$emit('addingToReview', chart.uuid);
            })
            this.reviewPayload.prescriptions.forEach(prescription => {
                if (!prescription.status && !prescription.expired){
                    if(prescription.draft){
                        if(prescription.authority != 'A'){
                            prescription.status = 'edited';
                        } else{
                            prescription.status = 'do_not_review';
                        }
                    } else{
                            if (prescription.chart_type != 'stat' && prescription.chart_type != 'short_term' && !prescription.phone_order && prescription.is_s8 == false ) {
                                prescription.status = 'continue';
                            } else {
                                prescription.status = 'reviewed';
                            }
                    }
                }
            })
        },
        emptyReviewPrescriptionList() {
            this.reviewPayload.prescriptions = [];
            this.$emit('emptyReviewPrescription', true);
            console.log('testing retrieveallcharts --- refreshlist - emptyReviewPrescriptionList');
            this.refreshList();
        },
        displayItem(value) {
            if (value) {
                console.log('HITTING DISPLAYITEM', value);
                
                this.showContent(this.charts[value], value);
                //  document.getElementById(`expand-${value}`).style.display = 'none';
            }
        },
        signChartReview() {
            console.log('these are the reviewed prescriptions', this.reviewedPrescriptions);
            this.displayChartReviewSummaryModal = true;
            // this.endpoint = `/person/${this.$route.params.id}/submit-chart-review`;
            // this.payload = this.reviewPayload;
            // this.displaySignatureModal = true;
        },
        isSuccessful(value) {
            if (value) {
                console.log('hitting issuccessful in predmedtable');
                this.displaySignatureModal = false;
                this.chartReview = false;
                console.log('testing retrieveallcharts --- refreshlist - isSuccessful');
                
                this.displaySignatureModal = false;
                this.$emit('successfullySubmitted', true);
                this.refreshList();
            }
        },
        refreshList() {
            console.log('testing retrieveallcharts --- refreshlist');
            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.displaySignatureModal = false;
                    console.log('testing retrieveallcharts --- refreshlist - successmodal closemodal isSuccessful');
                    this.$emit('retrieveAllCharts', this.form.filters);
            }, 1500); // delay
            // this.$emit('retrieveAllCharts', this.form.filters);
        },
        dismissExpiredCharts(){
            let expiredIDs=[]
            this.expiredCharts.forEach(chart=>{
                expiredIDs.push(chart.uuid);
                // this.dismissExpiredScript(chart.uuid);
            })
            
            axios.post('/dismiss-expired-prescriptions', {prescription_ids:expiredIDs}, this.$store.state.header).then(res=>{
                console.log('dismissed all expired meds', res.data);
                console.log('testing retrieveallcharts --- refreshlist - dismissexpiredcharts');
                this.$emit('dismissAllCharts', true);
                // this.expiredCharts.forEach(chart=>{
                //     this.dismissExpiredScript(chart.uuid);
                // })
            }).catch(err=>{
                console.log('error submitting', err);
            })
        },
        signChart(item, index) {
            this.showContent(item, index);

            this.$emit('signChart', index);
            console.log('CAN SIGN THIS NOW ', index);
        },
        sortMedicationCharts() {
            console.log('this is the filter', this.form.filters);
            console.log('testing retrieveallcharts --- sortmedicationcharts');
            this.$emit('retrieveAllCharts', this.form.filters);
        },
        emptyFilters() {
            this.$store.dispatch('getRefreshToken');
            this.form.filters = {
                drug: '',
                frequency: '',
                route: '',
                type: ''
            }
            console.log('testing retrieveallcharts --- emptyfilters');
            this.$emit('retrieveAllCharts', this.form.filters);
        },
        updateHeightCheck(value) {
            console.log('UPDATE HEIGHT CHECK HAS BEEN HIT', value, this.$refs[`prescriptionchart-${value.index}`].style);
        },
        displayheight(index) {
            console.log('this is the HEIGHT', this.$refs['prescriptionchart-' + index]);
        },
        closePrescriberDetailsModal(value){
            if(value){
                this.displayPrescriberDetailsModal = false;
            }
           
        },
        close(value) {
            this.$store.dispatch('getRefreshToken');
            if (value && !this.displayQuickDispenseModal) {
                console.log('hitting close in predmedtable');
                
                this.displayExportCSVModal = false;
                this.displaySignatureModal = false;
                this.displaySuccessModal = false;
                
                
                this.expandPatientSummaryModal = false;
                console.log('testing retrieveallcharts --- close if');
                if(this.displayChartReviewSummaryModal){
                    console.log('hitting the close chart review summary modal', true);
                    this.displayChartReviewSummaryModal = false;
                    this.$emit('retrieveAllCharts', this.form.filters, true);
                } else{
                    this.$emit('retrieveAllCharts', this.form.filters);
                }
                
            } else{
                this.displayQuickDispenseModal = false;
                console.log('testing retrieveallcharts --- close else');
                this.$emit('retrieveAllCharts', this.form.filters);
            }
        },
        showContent(transfer, index) {
            this.$store.dispatch('getRefreshToken');
            this.$emit('getAdministration', index);
            this.$emit('remapPresChart', transfer);
            if (transfer.display && (transfer.display === true)) {
                document.getElementById(`expand-${index}`).style.display = 'none';
                transfer.display = !transfer.display;
            } else {
                transfer.display = true;
                console.log('expanding range for this object', transfer);
                document.getElementById(`expand-${index}`).style.display = 'table-row';
                if(transfer.doctor?.uuid) {
                    console.log('retrieving doctor', transfer.doctor);
                    this.gpSearch(transfer.doctor.uuid);
                }
            }
        },
        selectDrugName(name) {
            this.form.filters.drug = name;
            this.drugNames = [];
        },
        aliasSearch(alias) {
            this.form.filters.drug = alias;
        },
        clearFilteredDrugRegister() {
            this.$store.dispatch('getRefreshToken');
            this.form = {
                filters: {
                    drug: '',
                    frequency: '',
                    route: '',
                    type: ''
                }
            };
        },
        printElem(elem) {
            console.log('this is the eleme', elem);
            window.print();
        },
        getFrequencies(){
            this.$store.dispatch('getRefreshToken');
            axios.post('/prescription/frequencies', {facility_id: this.facility_id}, this.$store.state.header).then(res=>{
                console.log('THESE ARE THE PRESCRIPTION FREQUENCIES', res.data);
                this.frequencyOptions = res.data;
                this.frequencyOptions.push({ 
                    frequency_name: "Variable",
                    interval: 1,
                    period: 'variable',
                    times: ['0600', '0800', '1000', '1200', '1700', '1800', '2000', '2200'],
                    days_of_week:['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
                })
                this.frequencyOptions.push({ 
                    frequency_name: "Custom",
                    interval: 1,
                    period: 'weekly',
                    times: ['0600', '0800', '1000', '1200', '1700', '1800', '2000', '2200'],
                    days_of_week:['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
                })
            })
        },
        b64toBlob(b64Data, contentType = '', sliceSize = 512){
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize),
                    byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },
        // Adding loading effect to pushed buttons
        loadingButtonToggle(classname){
            var button = document.querySelector('.' + classname);
            button.classList.toggle('pi-spin');
            button.classList.toggle('pi-spinner');
        },
        // Generate a pdf file and save the resident medication overview
        saveResident(){
            this.$store.dispatch('getRefreshToken');

            this.loadingButtonToggle('pi-download');

            let filename = this.patient?.personal_information?.first_name && this.patient?.personal_information?.last_name ? this.patient.personal_information.first_name + this.patient.personal_information.last_name : 'Resident';

            axios.post('/generate-pdf/charts', { patient_id: this.patientId, location: this.$store.state.currentLocation.uuid }, this.$store.state.header)
            .then(async res => {
                this.loadingButtonToggle('pi-download');
                let res_data = res.data.body.replace('"','');
                const linkSource = `data:application/pdf;base64,${res_data}`;
                const downloadLink = document.createElement("a");
                const fileName = filename + "_Medication_Chart.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                
                URL.revokeObjectURL(downloadLink)

            })
            .catch(err => {
                this.loadingButtonToggle('pi-download');
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })

        },
        // Generate a pdf file and print the resident medication overview
        printResident() {
            this.$store.dispatch('getRefreshToken');

            this.loadingButtonToggle('pi-print');

            axios.post('/generate-pdf/charts', { patient_id: this.patientId, location: this.$store.state.currentLocation.uuid }, this.$store.state.header)
            .then(async res => {
                this.loadingButtonToggle('pi-print');
                const linkSource = res.data.body;

                let blob = this.b64toBlob(linkSource, "application/pdf");
                const objectUrl = URL.createObjectURL(blob);
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = objectUrl;
                document.body.appendChild(iframe);
                iframe.contentWindow.print();
                URL.revokeObjectURL(objectUrl);

            })
            .catch(err => {
                this.loadingButtonToggle('pi-print');
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        },
        getConsiderations(patient) {
            let considerations = '';

            // Push each of the special_considerations
            if (patient.considerations) {
                patient.considerations.forEach(consideration => {
                    consideration.special_considerations.forEach(special_consideration => {
                        considerations += (special_consideration.name + '\n');
                    })
                    // Push any other notes
                    considerations += (considerations + 'Other: ' + `${consideration.comments}`);
                })
            }
            
            return considerations;
        },
        getPrimaryGPDetails(patient) {
            var primary_gp_details = '';

            if (patient.primary_prescriber) {
                var phone_numbers = '';
                var fax_numbers = '';
                var email_addresses = '';
                var prescriber_number = '';
                patient.primary_prescriber.contact_methods.forEach(contact_method => {
                    if (contact_method.type === 'phone') {
                        phone_numbers += (contact_method.data + '; ');
                    } else if (contact_method.type === 'fax') {
                        fax_numbers += (contact_method.data + '; ');
                    } else if (contact_method.type === 'email') {
                        email_addresses += (contact_method.data + '; ');
                    }
                });
                patient.primary_prescriber.regulatory_information.forEach(regulatory => {
                    if (regulatory.regulatory_type === 'Prescriber Number') {
                        prescriber_number = regulatory.data;
                    }
                });

                primary_gp_details += ('Name: ' + `${patient.primary_prescriber.personal_information ? patient.primary_prescriber.personal_information.first_name + ' ' + patient.primary_prescriber.personal_information.last_name : ''}`);
                primary_gp_details += ('\nAddress: ' + `${patient.primary_prescriber.address ? patient.primary_prescriber.address.street_address + ', ' + patient.primary_prescriber.address.city + ', ' + patient.primary_prescriber.address.state + ' ' + patient.primary_prescriber.address.postcode : ''}`);
                primary_gp_details += ('\nPhone: ' + `${phone_numbers}`);
                primary_gp_details += ('\nFax: ' + `${fax_numbers}`);
                primary_gp_details += ('\nEmail: ' + `${email_addresses}`);
                primary_gp_details += ('\nPrescriber Number: ' + `${prescriber_number}`);
            }

            return primary_gp_details;
        },
        getPharmacyDetails(patient) {
            var pharmacy_details = '';

            if (patient.pharmacy) {
                var phone_numbers = '';
                var fax_numbers = '';
                var email_addresses = '';
                
                if (patient.pharmacy.contact_methods) {
                    patient.pharmacy.contact_methods.forEach(contact_method => {
                        if (contact_method.type === 'phone') {
                            phone_numbers += (contact_method.data + '; ');
                        } else if (contact_method.type === 'fax') {
                            fax_numbers += (contact_method.data + '; ');
                        } else if (contact_method.type === 'email') {
                            email_addresses += (contact_method.data + '; ');
                        }
                    });
                }

                pharmacy_details += ('Name: ' + `${patient.pharmacy.name}`);
                pharmacy_details += ('\nAddress: ' + `${patient.pharmacy.address.street_address + ', ' + patient.pharmacy.address.city + ', ' + patient.pharmacy.address.state + ' ' + patient.pharmacy.address.postcode}`);
                pharmacy_details += ('\nPhone: ' + `${phone_numbers}`);
                pharmacy_details += ('\nFax: ' + `${fax_numbers}`);
                pharmacy_details += ('\nEmail: ' + `${email_addresses}`);
            }

            return pharmacy_details;
        },
        getEntitlementNumbers(patient) {
            var entitlement_numbers = '';

            if (patient.regulatory_information) {
                patient.regulatory_information.forEach(regulatory => {
                    if (regulatory.regulatory_type === 'Medicare Number') {
                        entitlement_numbers += ('Medicare Number: ' + `${regulatory.data}`);
                    } else if (regulatory.regulatory_type === 'Concession Number') {
                        entitlement_numbers += ('\nConcession Number: ' + `${regulatory.data}`);
                    } else if (regulatory.regulatory_type === 'DVA Number') {
                        entitlement_numbers += ('\nDVA Number: ' + `${regulatory.data}`);
                    }
                })
            }

            return entitlement_numbers;
        },
        getRegularCharts(charts) {
            var chartStruct = '';

            charts.forEach(chart => {
                chartStruct = chartStruct + 
                `
                {
                style: 'regularChartsTable',
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            {
                                border: [false, false, true, false],
                                style: 'chartDetailsBlock',
                                table: {
                                    widths: ['*', '*', '*', '*', '*'],
                                    body: [
                                        // ROW 1
                                        [
                                            {
                                                text: 'Start Date\n${chart.start_date}'
                                            },
                                            {
                                                text: 'Medication/form/strength\nAdditional Instructions',
                                                colSpan: 2,
                                                rowSpan: 3,
                                            },
                                            '',
                                            {
                                                text: 'Dose'
                                            }
                                        ],
                                        // ROW 2
                                        [
                                            {
                                                text: 'Stop Date',
                                                rowSpan: 2
                                            },
                                            '',
                                            '',
                                            {
                                                text: 'Route'
                                            }
                                        ],
                                        // ROW 3
                                        [
                                            '',
                                            '',
                                            '',
                                            {
                                                text: 'Frequency'
                                            }
                                        ],
                                        // ROW 4
                                        [
                                            {
                                                text: 'PBS/RPBS, Streamline Authority, Brand Sup, CTG',
                                                colSpan: 4
                                            },
                                            '',
                                            '',
                                            ''
                                        ],
                                        // ROW 5
                                        [
                                            {
                                                text: 'Prescriber name and signature',
                                                colSpan: 4
                                            },
                                            '',
                                            '',
                                            ''
                                        ],
                                    ]
                                },
                                layout: {
                                    hLineColor: 'purple',
                                    vLineColor: 'purple'
                                }
                            },
                            'ADMINISTRATION DETAILS TO GO HERE'
                        ]
                    ]
                },
                layout: {
                    hLineColor: 'purple',
                    vLineColor: 'purple'
                }
            }
                `
            })
            

            return chartStruct;
        },
        toDataURL(url) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.onload = function() {
                    var reader = new FileReader();
                    reader.onloadend = function() {
                        resolve(reader.result);
                    }
                    reader.onerror = reject;
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            })
            // var xhr = new XMLHttpRequest();
            // xhr.onload = function() {
            //     var reader = new FileReader();
            //     reader.onloadend = function() {
            //         callback(reader.result);
            //     }
            //     reader.readAsDataURL(xhr.response);
            // };
            // xhr.open('GET', url);
            // xhr.responseType = 'blob';
            // xhr.send();
        },
        getAge(dateString) {
            dateString = dateString.split('/')[1]+'/'+dateString.split('/')[0]+'/'+dateString.split('/')[2];
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            console.log('THIS IS THE AGE AND DATESTRING', age, dateString, new Date(dateString));
            console.log('THIS IS THE birthdate', birthDate);
            console.log('THIS IS THE birthdate', birthDate.getFullYear(), birthDate.getMonth());
            console.log('THIS IS THE today', today);
            console.log('THIS IS THE today', today.getFullYear(), today.getMonth());
            return age;
        },
        truncateText(longText, num){
            if (longText.length > num) {
                return longText.slice(0, num) + "...";
            } else {
                return longText;
            }
        },
        openExpiry(index){
            this.allCharts[index].renewExpired = true;
            console.log('this is the openexpiry chart', this.allCharts[index]);
        }
    },
    mounted() {
        console.log('testing retrieveallcharts --- mounted');
        this.$emit('retrieveAllCharts', this.form.filters);
        this.requiringAction = 0;
        this.getFrequencies();
        const renewingExpiredElements = this.$el.querySelectorAll('.renewing-expired');
        renewingExpiredElements.forEach((button, index) => {
            button.addEventListener('click', this.openExpiry(index));
        });
    },
    computed: {
        allCharts(){
            return this.charts;
        },
        onlyChartsWithChartTypes() {
            return this.charts.filter(item => { item.type })
        },
        getDrugsFilter() {
            return this.drugs.filter((v, i, a) => a.indexOf(v) === i);
        },
        getRoutesFilter() {
            return this.routes.filter((v, i, a) => a.indexOf(v) === i);
        },
        getTypesFilter() {
            return this.types.filter((v, i, a) => a.indexOf(v) === i);
        },
        getFrequenciesFilter() {
            return this.frequencies.filter((v, i, a) => a.indexOf(v) === i);
        },
        unreviewedCharts(){
            return this.reviewPayload.prescriptions.filter(item => !item.status && !item.authority)
        },
        signedPrescriptions(){
            return this.reviewPayload.prescriptions.filter(item => (item.status == 'continue' || item.status == 'reviewed')  && !item.draft)
        },
        draftPrescriptions(){
            return this.charts.filter(item=> (!item.signatures || !item.signatures.length) && !item.authority_prescription_number)
        },
        cancelledPrescriptions(){
            return this.reviewPayload.prescriptions.filter(item => item.status == 'cancelled')
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.signature-required {
    border: 1px solid red !important;
}

.no-signature-required {
    border: 1px solid green !important;
}

.medication-administration-table {
    border-collapse: collapse;

    th,
    td {
        padding: 0 15px;
    }

    td {
        border-bottom: none !important;
    }

    tr:nth-child(even) {
        background: #f3f4f4;
    }

    h4 {
        font-size: 0.9em;
        padding: 0;
        margin: 0;
    }
}

@media print {
    .data-table {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        // padding: 15px;
        font-size: 14px;
        // line-height: 18px;
    }
    .expand-column{
        display: none;
    }


    .web-camera-container{
        display: none;
    }
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d;
    text-align: left;
    margin-left: 5px;
}
.p-dropdown-panel{
    max-width: 30em;
}

</style>
