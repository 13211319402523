<template>
  <div class="login-view">
    <div class="login-form">
      <div class="login-logo" style="width: fit-content">
        <img src="@/assets/logos/full_logo.png" alt="StrongRoom AI Logo" style="height:70px;">
      </div>
      <div class="login-form-details">
        <h2>Login to Strong Care</h2>
        <Transition name="fade" appear>
        <div class="login-form-fields slide-fade"
          v-if="(!selectLocation && !sendPhoneCode && !sendMailCode) || loginError">
          <!-- <input placeholder="Location" v-model="loginForm.location" style="position: relative; width: 26em; background-color: #E5F1FE;" :style="[locationError ? {marginBottom: ''}:{marginBottom: '1em'}]" /> -->
          <!-- <p v-if="locationError" class="form-error">{{locationError}}</p> -->

          <!-- COMPANY -->
         
          <div style="display: grid; align-items: center; margin-bottom: 1em; ">
            <div class="text-search-container search-container text-field"
              style="display: flex; flex-direction: column; margin-top: 0.25em; margin-right: 20px; width: 100%">
              <input v-model="company" v-on:keyup="companySearch(company)" placeholder="Location"
                style="position: relative; background-color:  rgb(229, 241, 254);" />
              <div class="drug-search-result-container" v-if="company"
                style="position: absolute; margin-top: 35px; background-color: white; width: 20em; border: 2px solid #f6f6f6; z-index:1">
                <p class="drug-search-result" style="text-align: left; padding-left: 20px; cursor:pointer"
                  v-for="(item, index) in companyResults" :key="index" @click="selectCompany(item)">{{ item.name }}</p>
              </div>
            </div>
            <!-- <p style="text-align: left; margin-left: 20px; cursor:pointer; width: fit-content; margin: 0; margin-top: 20px;
                  border-bottom: 1px solid grey; " 
                  v-if="loginForm.company_id" @click="clearCompany">Clear Selected Company</p> -->
          </div>
         
          <!-- COMPANY -->

          <input placeholder="Username/email" v-model="loginForm.name" style="position: relative; width: 26em; background-color: #E5F1FE; margin-bottom: 1em" />
          <Password v-if="!forgotPassword" v-model="loginForm.password"  v-on:keyup.enter="submitLogin()" placeholder="Password" toggleMask :feedback="false" inputStyle="width:100%; background-color: #E5F1FE;padding: 10px 15px;"></Password>

          <!-- <input  v-if="!forgotPassword" v-model="loginForm.password" v-on:keyup.enter="submitLogin()" type="password"
            style="position: relative; width: 26em; background-color: #E5F1FE;" autocomplete="one-time-code" /> -->
          <!-- <input placeholder="Password" v-model="loginForm.password" v-on:keyup.enter="submitLogin()" type="text" style="position: relative; width: 26em; background-color: #E5F1FE; text-security:disc; -webkit-text-security:disc;" /> -->
          <p v-if="userError" class="form-error">{{ userError }}</p>
          <div class="p-field-checkbox" style="margin-top: 20px; ">
            <Checkbox name="username-binary" v-model="rememberUsername" :binary="true" />
            <label for="username-binary" style="margin-left: 10px;">Remember username</label>
          </div>
          
          <div style="margin-top: 1em; display: grid;  width: 100%" v-if="!forgotPassword">
            <p @click="loadingLogin ? '' : submitLogin()"  class="blue-button"
              style="width: 100%; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px;">
              {{loadingLogin ? 'Processing...': 'Login'}}</p>
              <div style="display: flex; justify-content: space-between">
                <p style="border-bottom: 2px solid black; font-weight: 1em; cursor: pointer; width: fit-content; justify-self: flex-end; color: #e08c00"><a href="https://help.strongroom.ai/knowledge-base-strongcare" target="_blank"  style="text-decoration: none; color: black;">Help</a></p>
                <p style="border-bottom: 2px solid black; font-weight: 1em; cursor: pointer; width: fit-content; justify-self: flex-end" @click="forgotPassword = true">Forgot Password?</p>
              </div>
              
          </div>
          <div style="margin-top: 1em; display: flex; align-items: center; justify-content: space-between;" v-if="forgotPassword">
            <p @click="submitForgotPassword()"  class="blue-button"
              style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; ">
              Email Reset Password</p>
            <p @click="forgotPassword = false"  class="blue-button"
              style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; ">
              Cancel</p>
          </div>
          <div style="display: flex; flex-wrap: wrap; width: 24em">
            <p v-if="loginError" style="color: red; word-break:break-word">Warning: {{ loginError }}</p>
          </div>
          
          <p v-if="loginCount" style="color: red; font-weight: bold;">{{ 5 - loginCount }} attempts remaining</p>
          
        </div>
        </Transition>
        <!-- MFA -->
        <!-- MFA -->
        <!-- MFA -->
        <Transition name="fade" appear>
        <div class="login-form-fields" style="text-align: left;"
          v-if="(sendMailCode || sendPhoneCode) && !loginError && !selectLocation">
          <p style="font-size: 1em;">You have Multi-Factor Authentication enabled via <span
              v-if="sendPhoneCode && sendMailCode"> both </span> <span v-if="sendPhoneCode" style="font-weight: bold">
              phone</span> <span v-if="sendPhoneCode && sendMailCode"> and </span> <span v-if="sendMailCode"
              style="font-weight: bold">email</span>.</p>
          <p style="font-size: 1em;" v-if="!(sentPhoneCode || sentMailCode)">Please click on 
            <span v-if="sendPhoneCode" style="font-weight: bold;">Receive SMS code</span>
            <span v-if="sendPhoneCode && sendMailCode"> &nbsp;AND</span> 
            <span v-if="sendMailCode" style="font-weight: bold">Receive email code</span> 
            to receive and enter the code
            <span v-if="sendPhoneCode && sendMailCode">s</span> below.
          </p>
          <!-- SMS CODE -->
          <div style="margin-top: 1em;" v-if="!successPhoneCode">
            <p class="blue-button" style="width: fit-content" v-if="sendPhoneCode && !sentPhoneCode && !loadingPhoneMFA"
              @click="sendMFAPhoneCode()">Receive SMS code</p>
            <div v-if="loadingPhoneMFA">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;"></i>
            </div>
            <p v-if="sentPhoneCode" style="font-size: 1em;">
              We sent a code to your phone number ending in <b>{{this.lastTwoDigits}}.</b>
            </p>
            <div style="display: flex; align-items: center;" v-if="sentPhoneCode">
              <input type="text" v-model="mfaPhoneCode" placeholder="Enter SMS code here." />
              <p class="blue-button" style="margin-left: 10px;" @click="checkMFACode('phone')">Submit code</p>
            </div>
            <p v-if="sentPhoneCode">Haven't received your SMS code? <u @click="sendMFAPhoneCode()">Click here.</u></p>
          </div>
          <p style="color: green; font-size: 1em; text-align: left;" v-else>Verified phone code.</p>
          <hr style="width: 100%; margin: 0; margin-top: 1em;" v-if="sendPhoneCode && sendMailCode">

          <!-- EMAIL CODE -->
          <div style="margin-top: 1em;" v-if="!successMailCode">
            <p class="blue-button" style="width: fit-content" v-if="sendMailCode && !sentMailCode && !loadingMailMFA"
              @click="sendMFAEmailCode()">Receive email code</p>
            <div v-if="loadingMailMFA">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 0.5em;"></i>
            </div>
            <div style="display: flex; align-items: center;" v-if="sentMailCode">
              <input type="text" v-model="mfaMailCode" placeholder="Enter email code here." />
              <p class="blue-button" style="margin-left: 10px;" @click="checkMFACode('email')">Submit code</p>
            </div>

            <p v-if="sentMailCode">Haven't received your email code? <u @click="sendMFAEmailCode()">Click here.</u></p>
          </div>
          <p style="color: green; font-size: 1em; text-align: left;" v-else>Verified email code.</p>
          <p v-if="codeError" style="color: red">Warning: {{ codeError }}</p>

        </div>
        </Transition>
        <!-- LOCATION SELECT -->
        <!-- LOCATION SELECT -->
        <!-- LOCATION SELECT -->
        <Transition name="fade" appear>
        <div class="login-form-fields" v-if="selectLocation && !loginError">
          <div style="border: 2px solid red; border-radius: 4px; padding: 0 10px; margin-bottom: 1em; width: 30em"
            v-if="direct_to_mfa_settings">
            <p style="color: red; font-weight: bold; font-size: 1em">After selecting your location, you will be required
              to enable at least one multi-factor authentication method.</p>
          </div>
          <!-- <select v-if="locationResults" @change="selectLocationID()" id="location-select" placeholder="Select location" 
            style="position: relative; width: 26em; background-color: #E5F1FE; margin-bottom: 1em; padding: 10px; border-radius: 8px">
              <option value="" disabled selected >Choose Location</option>
              <option v-for="(item, index) in locationResults" :key="index" :value="item.uuid" v-on:keyup.enter="submitSelectedLocation()">{{item.name}}</option>
            </select> -->
          <!-- @change="selectLocationID()"  -->
          <Dropdown v-if="locationResults" v-on:keyup.enter="submitSelectedLocation()"
            v-model="locationSelectForm.location_id" :options="locationResults" optionLabel="label" optionValue="uuid"
            placeholder="Select location" style="margin-left: 5px; width: 20em; margin-bottom: 15px;" />
          <div v-if="locationSelectForm.location_id">
            <p @click="loadingLogin ? '' : submitSelectedLocation()" class="blue-button"
              style="width: fit-content; padding: 5px 15px; font-weight: bold; font-size: 1em; border-radius: 8px; width: 100%; ">
              {{loadingLogin ? 'Logging in...' : 'Select Location'}}</p>
          </div>
          <div style="display: flex; flex-wrap: wrap; width: 100%;">
            <p v-if="loginError" style="color: red">Warning: {{ loginError }}</p>
          </div>
          
        </div>
        </Transition>

      </div>
    </div>
    <div class="login-context">

      <div class="login-logo" style="display: grid; justify-content: flex-end">
        <img src="@/assets/logos/short_logo.png" alt="StrongRoom AI Icon" style="height:70px;">
      </div>
      <h1 style="margin-top: 3em;">The AI-Driven Drug Management Platform</h1>

      <p style="font-size: 1.4em; font-weight: 500">Powering a secure digital gateway to quickly and safely access
        specialised treatment plans for patients.</p>
    </div>
    <SuccessModal @close="close" v-if="displaySuccessModal" :reloadPageOnClose="reloadPageOnClose"
    :message="successMessage" :first_spec_line="first_spec_line" />
    <EULAModal
      @close="closeMIMSEULAModal"
      v-if="displayMIMSEULAModal"
      :temp_access_token="locationSelectForm.temp_access_token"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import FirebaseService from "../services/FirebaseService";
import SuccessModal from '@/components/modals/SuccessModal.vue';
import EULAModal from '@/components/mims/EULAModal.vue';

export default {
  components: {
    SuccessModal,
    EULAModal
  },
  data() {
    return {
      displaySuccessModal: false,
      displayMIMSEULAModal: false,
      reloadPageOnClose: true,
      rememberUsername: '',
      successMessage: '',
      first_spec_line:'',
      forgotPassword: false,
      show: true,
      loadingLogin: false,
      loginCount: 0,
      company: '',
      loginError: '',
      loginForm: {
        // location: this.$store.state.uuid,
        name: '',
        password: '',
        // hardcoding company id as temporary measure
        company_id: ''
      },
      locationSelectForm: {
        temp_access_token: '',
        location_id: ''
      },
      locationError: '',
      userError: '',
      selectLocation: false,
      locationSelected: '',
      locationResults: [],
      companyResults: [],
      loadingPhoneMFA: false,
      loadingMailMFA: false,
      sendMailCode: false,
      sendPhoneCode: false,
      sentPhoneCode: false,
      sentMailCode: false,
      successPhoneCode: false,
      successMailCode: false,
      lastTwoDigits: '',
      mfaMailCode: '',
      mfaPhoneCode: '',
      temp_token: '',
      codeError: '',
      direct_to_mfa_settings: false,
      user: '',
      resetPassword: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    cacheUsername(){
      if (this.rememberUsername) {
        localStorage.setItem('username', this.loginForm.name);
      } else {
        localStorage.removeItem('username');
      }
    },  
    close(value){
      if(value){
        this.displaySuccessModal = false;
        this.forgotPassword = false;
      }
    },
    closeMIMSEULAModal() {
      this.displayMIMSEULAModal = false;
    },
    submitForgotPassword(){
      this.displaySuccessModal = true;
      this.successMessage = 'Submitting reset request.'
      axios.post('/reset-password/send-email',{company_id:this.loginForm.company_id, username: this.loginForm.name}).then(res=>{
        console.log('submitted reset', res.data);
        this.successMessage = 'Password reset link has been emailed.';
        this.first_spec_line = res.data + ' It may take a few minutes to receive link.';
        this.loginForm.company_id = '';
        this.loginForm.name ='';
      }).catch(err => {
          // this.loginError = err;
          this.successMessage = err;
          this.loginForm.name ='';
          this.loadingLogin = false;
          console.log('error is', err.response.data);
        })
    },
    setExpiry() {
      let now = new Date();
      let time = now.getTime();
      let expiredTime = time + 60;
      let setTime = now.setTime(expiredTime);
      console.log('now is', setTime);
      document.cookie = "auth=" + "authtoken2342342" + "; max-age=" + 60 + "; path=/";
      console.log('document.cookie is', document.cookie);
    },
    getCookie(name) {
      let matches = document.cookie.match(new RegExp(
        //eslint-disable-next-line
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
      ));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    submitLogin() {
      this.loginError = '';
      this.loadingLogin = true;
      if (!this.loginForm.name || !this.loginForm.password) {
        this.userError = 'Email/password is required.';
        this.loadingLogin = false;
      } else {
        this.userError = '';
      }
      if (this.loginForm.name && this.loginForm.password) {
        this.setExpiry();

        axios.post('/login', this.loginForm).then(res => {
          
          this.loadingLogin = false;
          localStorage.removeItem('loginCount');
          console.log('this is the login res', res.data);
          this.locationSelectForm.temp_access_token = res.data.temp_access_token;
          this.locationResults = res.data.locations;
          this.locationResults.forEach(loc=>{
            loc.label = `${loc.facility? loc.facility.name+ ' - ' :''} ${loc.name}`
          })
          localStorage.setItem('locations', JSON.stringify(this.locationResults));
          this.$store.commit('setAvailableLocations', this.locationResults);
          localStorage.setItem('company_mfa', res.data.company_mfa);
          this.$store.commit('setUserType');
          // Set the MIMS API Key to storage
          if (res.data.mims_api_key) {
            localStorage.setItem('mimsApiKey', res.data.mims_api_key);
          }
          if (res.data.mfa_email_active) {
            this.sendMailCode = true;
          }
          if (res.data.mfa_phone_active) {
            this.sendPhoneCode = true;
          }
          // if company mfa set to true but user does not have it enabled, will direct user to mfa settings
          if (res.data.company_mfa && !res.data.mfa_email_active && !res.data.mfa_phone_active) {
            this.direct_to_mfa_settings = true;
            localStorage.setItem('direct_to_mfa_settings', true);
          } else {
            this.direct_to_mfa_settings = false;
            localStorage.setItem('direct_to_mfa_settings', false);
          }

          // Check if the MIMS EULA has been accepted
          if (res.data.mims_eula_last_accepted == null) {
            this.displayMIMSEULAModal = true;
            // this.resetPassword = true;
            localStorage.setItem('reset_password', true);
          } else{
            // localStorage.setItem('reset_password', false);
          }
        }).then(data => {
          this.cacheUsername();
          // this.selectLocation = true;
          if (!this.sendPhoneCode && !this.sendMailCode) {
            this.selectLocation = true;
          }
          console.log('this is the data', data);
        }).catch(err => {
          // this.loginError = err;
          this.loadingLogin = false;
          if (err.message.split(" ").includes('403')) {
            // this.loginError = "Incorrect username or password."
            this.loginError = err.response && err.response.data ? err.response.data : "Incorrect username or password.";
            this.loginCount = localStorage.getItem('loginCount') ? Number(JSON.parse(localStorage.getItem('loginCount'))) + 1 : 1;
            localStorage.setItem('loginCount', this.loginCount);
          } else {
            this.loginError = err.response.data;
          }
          console.log('error is', err.response.data);
        })
      }
    },
    sendMFAEmailCode() {
      this.loadingMailMFA = true
      axios.post('/login-mfa-email', { temp_access_token: this.locationSelectForm.temp_access_token }).then(() => {
        this.sentMailCode = true;
        this.loadingMailMFA = false;
      }).catch(err => {
        console.log('this is the mfa email err', err)
        this.codeError = err.response && err.response.data ? err.response.data : err.response;
        this.sentMailCode = false;
        this.loadingMailMFA = false;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expire')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
      })
    },
    sendMFAPhoneCode() {
      this.loadingPhoneMFA = true;
      this.codeError = '';
      axios.post('/login-mfa-phone', { temp_access_token: this.locationSelectForm.temp_access_token }).then(res => {
        this.lastTwoDigits = res.data.phone_number;
        this.sentPhoneCode = true;
        this.loadingPhoneMFA = false;
      }).catch(err => {
        console.log('this is the mfa phone err', err);
        this.codeError = err.response && err.response.data ? err.response.data : err.response;
        this.sentPhoneCode = false;
        this.loadingPhoneMFA = false;
        if (err.response && err.response.data && err.response.data.split(' ').includes('expire')) {
          console.log('yes, it does include expired')
          this.$router.push('/login');
        }
      })
    },
    checkMFACode(type) {
      this.codeError = '';
      axios.post('/login-check-mfa', {
        'type': type,
        code: type == 'phone' ? this.mfaPhoneCode : this.mfaMailCode,
        temp_access_token: this.locationSelectForm.temp_access_token
      }).then(() => {
        if (type == 'phone') {
          this.successPhoneCode = true;
        } else {
          this.successMailCode = true;
        }

        if ((this.sendMailCode == this.successMailCode) && (this.sendPhoneCode == this.successPhoneCode)) {
          this.selectLocation = true;
        }
      }).catch(err => {
        this.codeError = err.response.data ? err.response.data.message : err.data.message;
        this.mfaMailCode = '';
        this.mfaPhoneCode = '';
        this.sentPhoneCode = false;
        this.sentMailCode = false;
      })
    },
    submitSelectedLocation() {
      this.selectLocationID();
      this.loginError = '';
      this.loadingLogin = true;
      setTimeout(() => {
        axios.post('/login/location-select', this.locationSelectForm).then(res => {
          console.log('this is the location res', res);

          let cookie = "auth=" + res.data.access_token + "; max-age=" + 3600 + "; path=/; samesite";
          document.cookie = cookie;
          this.$store.commit('setUser', res.data);
         
          // this.$store.commit('setHeaderLocation', JSON.parse(localStorage.getItem('currentLocation').uuid))
          console.log('this is the state user', this.$store.state.user);
          localStorage.setItem('user', JSON.stringify(this.$store.state.user));
          this.$store.commit('setIntegrationDetails', {
            integration_id: res.data.integration_id,
            integration_type: res.data.integration_type
          })
          let loginDate = new Date();
          localStorage.setItem('lastRefresh', JSON.stringify(loginDate.getTime()));
          console.log('this is the cookie', cookie);
          localStorage.removeItem('loginCount');
          this.locationSelectForm = {
            temp_access_token: '',
            location_id: ''
          }
         
          if (this.direct_to_mfa_settings) {
            this.$router.push('/settings');
            setTimeout(() => {
              this.getLoggedUser();
              this.$store.dispatch('getAlerts');
            }, 2000);

          } else {
              setTimeout(() => {
                this.getLoggedUser();
                this.$store.dispatch('getAlerts');
                this.$store.dispatch('checkSyncResident');
              }, 2000);
              if (this.$store.state.user.role.permissions.includes('can_view_drug_register') && this.$store.state.userType != 'prescriber' &&  this.$store.state.userType != 'pharmacy') {
                this.$router.push('/drug-register');
                this.$store.dispatch('timeRunner');
              } else {
                this.$router.push('/');
                this.$store.dispatch('timeRunner');
              }
            
            
          }
          
        }).catch(err => {
          this.loadingLogin = false;
          this.loginError = err.response && err.response.data ? err.response.data : err.response;
          // if(err.message.split(" ").includes('403')){
          //   this.loginError = "Invalid User";
          // } else if(err.response.data){
          //   this.loginError = err.response.data;
          // }
          console.log('this is the location error', err.response, err);
        })
      }, 500)

    },
    selectLocationID() {
      this.locationResults.forEach(item => {
        if (item.uuid == this.locationSelectForm.location_id) {
          localStorage.setItem('currentLocation', JSON.stringify(item));
          this.$store.commit('setLocation', JSON.parse(localStorage.getItem('currentLocation')));
        }
      })

    },
    companySearch(company) {
      let headers = {
        "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
        "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
      }

      console.log('this is the company', company);
      axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_companies/query`,
        { params: `query=${company}&hitsPerPage=5` },
        { headers })
      .then(res => {
        console.log('this is the algolia res', res.data.hits);
        this.companyResults = res.data.hits;
        // state.commit('setDrugNames', res.data.hits);
      })
    },
    selectCompany(company) {
      this.companyResults = [];
      console.log('this is the selected company', company);
      // company.type = 'aged care'; //remove prior to merge into aged care
      localStorage.setItem('company', JSON.stringify(company));
      this.$store.commit('setCompany', company);
      this.company = company.name;
      this.loginForm.company_id = company.uuid;
    },
    clearCompany() {
      this.loginForm.company_id = '';
    },
    getLoggedUser() {
      axios.post('/me', {}, this.$store.state.header).then(res => {
        localStorage.setItem('user_id', JSON.stringify(res.data.uuid));
        FirebaseService.getForm(res.data.uuid).then((res) => {
          // let formtype = type;
          console.log('trying to retrieve form', res.data())
          if (res.data() != undefined && res.data().saveContext) {
            // if(formtype == 'send' || formtype == 'receive'){
            //     formtype = 'transfer'
            // }
            

            localStorage.setItem('savedform', JSON.stringify(res.data()));
            console.log('form does exist', res.data());
            this.$store.commit('setCheckedFirebase', true);
            // console.log('this is the endpoint for the firebase form',res.data().endpoint, res.data().endpoint.includes(formtype));
          } else {
            console.log('form does not exist');
          }


        })
      }).catch(err => {
        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
          this.$router.push('/login');
        }
        console.log('this is the getloggeduser error', err);
      })
    }
  },
  watch: {
    rememberUsername: function() {
        console.log('rememberUsername set to', this.rememberUsername, 'in localStorage.')
        localStorage.setItem('rememberUsername', this.rememberUsername);
    }
  },
  mounted() {
    if(window.location.host.split('.')[0] == 'staging'){
      this.$store.commit('setSubdomain', 'staging')
    } else if(window.location.host.split('.')[0] == 'care'){
      this.$store.commit('setSubdomain', 'prod')
    } else{
      this.$store.commit('setSubdomain', 'local')
    }
    
    console.log('local storage check', localStorage.getItem('loginCount'));
    
    // Set rememberUsername to true if not in localStorage
    if (!localStorage.getItem('rememberUsername')) {
      console.log('rememberUsername not in localStorage, setting to true.')
      this.rememberUsername = true;
    }
    // Set username and company if rememberUsername is true
    else if (localStorage.getItem('rememberUsername') == 'true') {
      console.log('rememberUsername is true, setting username and company.')
      this.rememberUsername = Boolean(localStorage.getItem('rememberUsername'));
      if (localStorage.getItem('username')) {
        this.loginForm.name = localStorage.getItem('username');
      }
      if (localStorage.getItem('company')) {
        try {
          var parsedCompany = JSON.parse(localStorage.getItem('company'));
          this.selectCompany(parsedCompany);
        } catch(e) {
          localStorage.removeItem('company');
        }
      }
    }
    // Clear username and company if rememberUsername is false
    else {
      console.log('rememberUsername is not true, setting to false.')
      this.rememberUsername = false;
      localStorage.removeItem('username');
      localStorage.removeItem('company');
    }
    // Clear drug safe ids cached
    let removeIds = [];

    for (var i = 0; i < localStorage.length; i++){
      console.log('looping through local storage')
        if (localStorage.key(i).substring(0,4) == 'DRET') {
          console.log('found local storage drug id', localStorage.key(i));
          removeIds.push(localStorage.key(i));
        }
    }

    removeIds.forEach(id=>{
      localStorage.removeItem(id);
    })


    localStorage.removeItem('locations');
    localStorage.removeItem('currentLocation');
    localStorage.removeItem('user');
    localStorage.removeItem('user_id');
    localStorage.removeItem('lastRefresh');
    localStorage.removeItem('direct_to_mfa_settings');
    localStorage.removeItem('company_mfa');
    localStorage.removeItem('savedform');
    localStorage.removeItem('notifiedChartAlerts');
    // localStorage.removeItem('reset_password');
    document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    this.$store.commit('setAuthorization');
    this.$store.commit('setDisplayAlerts', false);
    this.$store.commit('setAlerts', null);
    
  }
}
</script>

<style lang="scss">
@import "../assets/css/main.scss";

.login-view {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.login-context {
  background: linear-gradient(84.65deg, #10DCF4 31.37%, #0870DC 87.12%);
  color: white;
  padding-top: 8.125em;
  padding-left: 17em;
  padding-right: 8em;
  text-align: left;

  .login-logo {
    justify-content: flex-end;
  }

  h1 {
    font-size: 3.5em;
    font-weight: bold;
  }
}

.login-logo {
  justify-self: flex-start;
}

.login-form {
  padding: 8.125em 4.375em;
  border-bottom-right-radius: 8.25em;
  background-color: white;
  margin-right: -10em;
  z-index: 1;
  box-shadow: 10px 10px 25px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.login-form-details {

  width: fit-content;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10em;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-content: center;

  h2 {
    text-align: left;
  }
}

.login-form-fields {
  display: grid;
  justify-content: center;
}

.form-error {
  margin: 0;
  margin-bottom: 1em;
  color: $error;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  padding-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
