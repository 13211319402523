<template>
  <div>
    <div class="menu">
      <router-link 
      @click="passViewToState('dashboard')"    
      class="menu-item" id="dashboard-item" v-if="$store.state.userType == 'prescriber' || $store.state.userType == 'pharmacy'" v-bind:class="[$route.name == 'Dashboard' ? 'active-menu-item' : '']" to="/" >
        <p>Dashboard</p>
      </router-link>
      <router-link 
      @click="passViewToState('drug-register')" v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.user.role.permissions.includes('can_view_drug_register')"     
      class="menu-item" id="drug-register-item" v-bind:class="[$route.name == 'Drug Register' ? 'active-menu-item' : '']" to="/drug-register" >
        <p>Drug Register</p>
      </router-link>
      <router-link 
      @click="passViewToState('stock')" 
      v-if="($store.state.userType != 'prescriber') && ($store.state.user.role.permissions.includes('can_view_stock_take') || $store.state.user.role.permissions.includes('can_view_transfers') || $store.state.user.role.permissions.includes('can_view_destruction') || $store.state.user.role.permissions.includes('can_view_deliveries'))"        
      class="menu-item" id="stock-item" v-bind:class="[$route.name == 'Stocktake' || $route.name == 'Stock' || 
      $route.name == 'Transfers' || $route.name == 'Destructions' || $route.name == 'Deliveries' || $route.name == 'Invoices'||$route.name == 'Returns'|| $route.name == 'Invoices' || $route.name == 'Batches' ? 'extended-active-menu-item' : '']" :to="$store.state.userType == 'pharmacy' ? '/stock/deliveries' : '/stock/stocktake'" >
        <p>Stock</p>
      </router-link>
      <router-link 
      @click="passViewToState('patients')"    
      class="menu-item" id="patients-item" v-bind:class="[$route.name == 'Patients' || $route.name == 'Patient' ? 'active-menu-item' : '']"
      v-if="$store.state.user.role.permissions.includes('can_view_resident') && $store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.company.type != 'warehouse'" to="/patients" >
        <p>Residents</p>
      </router-link>
      <router-link v-if="$store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy' && $store.state.user.role.permissions.includes('can_view_resident') && $store.state.user.patient_admin_active && $store.state.company.type != 'warehouse'"
      @click="passViewToState('administration')"    
      class="menu-item" id="administration-item" v-bind:class="[$route.name == 'Administration' ? 'active-menu-item' : '']" to="/administration" >
        <p>Administration</p>
      </router-link>
      <router-link v-if="$store.state.company.type == 'warehouse'"
      @click="passViewToState('orders')"    
      class="menu-item" id="orders-item" v-bind:class="[$route.name == 'Orders' ? 'active-menu-item' : '']" to="/orders" >
        <p>{{ getDynamicName() }}</p>
      </router-link>
      <router-link v-if="$store.state.company.type == 'warehouse'"
      @click="passViewToState('client')"    
      class="menu-item" id="clients-item" v-bind:class="[($route.name == 'Clients' || $route.name=='ClientInfo' )? 'active-menu-item' : '']" to="/clients" >
        <p>Clients</p>
      </router-link>
      <router-link v-if="$store.state.company.type == 'warehouse'"
      @click="passViewToState('supplier')"    
      class="menu-item" id="suppliers-item" v-bind:class="[($route.name == 'Suppliers' || $route.name=='SupplierInfo')  ? 'active-menu-item' : '']" to="/suppliers" >
        <p>Suppliers</p>
      </router-link>
      <router-link 
      @click="passViewToState('reports')" v-if="($store.state.userType != 'prescriber' && $store.state.userType != 'pharmacy') && ($store.state.user.role.permissions.includes('can_view_transactions_report') || $store.state.user.role.permissions.includes('can_view_stock_report') || $store.state.user.role.permissions.includes('can_view_logs_report'))"    
      class="menu-item" id="reports-item" v-bind:class="[$route.name == 'Reports' ? 'active-menu-item' : '']" to="/reports" >
        <p>Reports</p>
      </router-link>
      <router-link 
      @click="passViewToState('settings')"     
      class="menu-item" id="settings-item" v-bind:class="[$route.name == 'Settings' ? 'active-menu-item' : '']" to="/settings" >
        <p>Settings</p>
      </router-link>
    </div>
    <StockSubmenu v-show="$route.name == 'Stocktake' || $route.name == 'Stock' || 
      $route.name == 'Transfers' || $route.name == 'Destructions' || $route.name == 'Deliveries' || $route.name == 'Returns' || $route.name == 'Invoices' || $route.name == 'Batches'"  />
  </div>
</template>

<script>
import StockSubmenu from '@/components/navigation/StockSubmenu.vue'
import config  from "../../config.js"
export default {
  props:["page"],
  name: 'Menu',
  components: {
    StockSubmenu
  },
  data: function() {
    return {
      selectedView:'',
      isStock: false,
      dynamicName: config
    }
  },
  methods: {
    passViewToState(view) {
      console.log('this is the current view', view);
      this.$store.commit('changeView', view);
      this.selectedView = view;
      // remove any active indicator of a preselected sub menu item
      let submenuitems = document.getElementsByClassName('sub-menu-item');
      console.log('this is the submenu item list', submenuitems);
      for (let item of submenuitems) {
        item.classList.remove('active-sub-menu-item');
      }
      if (this.selectedView && this.selectedView.startsWith('stock')) {
       this.isStock = true;
      } else {
        this.isStock = false;
      }

    },
    getDynamicName(){
      const matchedItem = this.dynamicName.find(item => item.name === this.$store.state.user.integration_type)
      return matchedItem ? matchedItem.orders : "Orders"
    },
  },
  mounted() {
    this.selectedView = window.location.pathname.slice(1);
    if (this.selectedView && this.selectedView.startsWith('stock')) {
       this.isStock = true;
    }
   
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.menu-item{
  p {
    font-weight: bold;
  }
}
.active-menu-item{
  border-radius: 8px;
  background-color: white;
}

.extended-active-menu-item{
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
}
.menu-item:first-child, .menu-item:last-child{
    border-radius: 6px;
}
.menu:has(a:nth-child(6):last-child){
  grid-template-columns: repeat(6,1fr);
}
.menu:has(a:nth-child(5):last-child){
  grid-template-columns: repeat(5,1fr);
}
.menu:has(a:nth-child(4):last-child){
  grid-template-columns: repeat(4,1fr);
}
.menu:has(a:nth-child(3):last-child){
  grid-template-columns: repeat(3,1fr);
}
.menu:has(a:nth-child(2):last-child){
  grid-template-columns: repeat(2,1fr);
}
.menu:has(a:nth-child(1):last-child){
  grid-template-columns: repeat(1,1fr);
}
</style>
