<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <button @click="toggleBatchModal()" class="actions-menu-item">New Batch</button>
    </div>
    
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_deliveries')">
      <h3 class="panel-header">Batches</h3>
      <div class="drug-register-table table-container" style="display: flex;flex-direction: column;">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: end; height: max-content; justify-content: space-between;margin-bottom: 20px;">
          <div style="display: flex; align-items: end;gap: 12px;flex-wrap: wrap">
          <!-- SEARCH SECTION -->

            <div class="date-search-container search-container calenderdatelabel">
              <label for="calenderDate">Date</label>
                <div class="calendar-input-container" id="calenderDate">
                 <i class="pi pi-calendar"></i>
                  <Calendar v-model="date"  selectionMode="range" placeholder="Select a Date..." dateFormat="dd/mm/yy" />
                 </div>
            </div>

            <div class="text-search-container search-container calenderdatelabel">
              <label for="medication">Medication</label>
              <div class="calendar-input-container">
                 <i class="pi pi-search"></i>
             <input id="medication" placeholder="Search a Medication..." style="position: relative"  v-model="form.filters.drug"  @input="debouncedLoadEntries"/>
            </div>
            </div>

            <div class="text-search-container search-container calenderdatelabel">
              <label for="batch_name">Batch</label>
              <div class="calendar-input-container">
                 <i class="pi pi-search"></i>
             <input v-model="form.filters.batch_name" id="batch_name" @input="debouncedLoadEntries" placeholder="Search a Batch..." style="position: relative" />
            </div>
            </div>

          <!-- SELECT FILTER SECTION -->
            <div class="calenderdatelabel">
              <label for="multistatusselect">Status</label>
               <MultiSelect class="multiselectstatus" id="multistatusselect" v-model="selectedStatuses" :options="statusSelect" @change="updateStatusList" optionLabel="status" placeholder="Select Status..." />
            </div>

            <!-- <div class="calenderdatelabel">
              <label for="allTypes">Stock Type</label>
              <button class="button types-button" id="allTypes">All Types</button>
            </div> -->

            <div class="calenderdatelabel">
              <label for="presciptionType">Batch Type</label>
              <div style="display: flex; align-items: center">
            <button :class="[form.filters.show_active ? 'active-select-filter types-button pendingborder' : 'outline-button s8-button pendingborder']" id="presciptionType" @click="() => { form.filters.show_active = true; loadEntries(); }" style="min-width: 175px;">Active Batches</button>

            <button :class="[!form.filters.show_active ? 'active-select-filter types-button destructionborder' : 'outline-button s8-button destructionborder']" @click="() => { form.filters.show_active = false; loadEntries(); }" style="min-width: 175px;">Deleted Batches</button>
           </div>
           </div>
             
          <button class="button clear-button adjustbtnstyle" @click="clearEntriesFilters()">Clear</button>
            <button class="button submit-button adjustbtnstyle" @click="loadEntries()">Search</button>
          
            <button v-if="numberToDestroy > 0" class="button destroy-button" @click="() => { destroyItems() }" style="margin:3px 0px;">Delete {{ numberToDestroy }} Batches</button>
            <!-- <div style="display: flex; align-items: center;"> -->
               
              <!-- <div class="select-filters-container">
                <button @click="()=>{tags.s8 = !tags.s8; autoAll()}" 
                v-bind:class="[tags.s8 == true ? 'active-select-filter s8-button' : 'outline-button s8-button']">S8 Only</button>
            </div> -->
            <!-- </div> -->
          

            
            
          <!-- <div style="display: flex; gap: 20px; align-items: center">
          <button :class="ordersToSign > 0 ? 'red-button' : 'blue-button'" @click="toggleSelectAll(ordersToSign > 0 ? false : true)">{{ ordersToSign > 0 ? 'Unselect All' : 'Select All'}}</button>
          <button class="blue-button" :class="ordersToSign > 0 ? 'blue-button' : 'grey-button'" @click="signOrders()">Sign</button>
          <div class="calenderdatelabel" style="display: flex;flex-direction: row;align-items: center;gap: 10px;">
            <label for="attentionRequired" style="margin: 0px;">Attention Required</label>
            <InputSwitch id="attentionRequired" v-model="checked" @change="updateListing"/>
          </div>
        </div> -->
          
          </div>
          
        </div>
        <div v-if="!$store.state.loadingBatchData" style="display: flex; align-items: start; overflow-x: hidden">
          <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_deliveries")' >
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
        <table v-else class="data-table" cellspacing="0">
          <tr class="table-headers tableHeader" >
            <th style="width: 20px"></th>
            <th style="width: 50px" v-if="form.filters.show_active">
            <div @click.stop="() => { selectAllItem(numberToDestroy > 0 ? false : true) }" v-if="($store.state.currentLocation.state != 'WA' || $store.state.currentLocation.state == 'WA')" style="cursor: pointer">
              <div v-if="allSelected()" class="check-circle circle-on"></div>
              <div v-else class="circle-off"></div>
            </div>
            </th>
            <th>
              <h3 @click="sortBy('created_at', 'asc')">Creation Date</h3>
              <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  created_at-sort" v-if="form.col =='created_at' && form.sort_direction =='asc'" @click="sortBy('created_at', 'desc')"></i>
              <i class="pi pi-sort created_at-sort" v-else @click="sortBy('created_at', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('batch_name', 'asc')">Batch Name</h3>
              <div v-if="form.col == 'batch_name'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  batch_name-sort" v-if="form.col =='batch_name' && form.sort_direction =='asc'" @click="sortBy('batch_name', 'desc')"></i>
              <i class="pi pi-sort batch_name-sort" v-else @click="sortBy('batch_name', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('batch_id', 'asc')">Batch ID</h3>
              <div v-if="form.col == 'batch_id'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  batch_id-sort" v-if="form.col =='batch_id' && form.sort_direction =='asc'" @click="sortBy('batch_id', 'desc')"></i>
              <i class="pi pi-sort batch_id-sort" v-else @click="sortBy('batch_id', 'asc')"></i>
              </div>
            </th>
            <th>
            <h3 @click="sortBy('expiry_date', 'asc')">Expiry</h3>
            <div v-if="form.col == 'expiry_date'" style="display: inline; cursor: pointer">
            <i class="pi pi-sort  expiry_date-sort" v-if="form.col =='expiry_date' && form.sort_direction =='asc'" @click="sortBy('expiry_date', 'desc')"></i>
            <i class="pi pi-sort expiry_date-sort" v-else @click="sortBy('expiry_date', 'asc')"></i>
            </div>
          </th>
            <th style="padding-left: 20px; width: 200px">
              <h3 @click="sortBy('status', 'asc')">Status</h3>
              <div v-if="form.col == 'status'" style="display: inline; cursor: pointer">
                <i class="pi pi-sort  status-sort" v-if="form.col =='status' && form.sort_direction =='asc'" @click="sortBy('status', 'desc')"></i>
                <i class="pi pi-sort status-sort" v-else @click="sortBy('status', 'asc')"></i>
            </div>
            </th>
          </tr>
          <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
            <tr @click="showContent(item, index)" :class="[ item.expand && (item.expand == true) ? 'open-transfer-row': '']">
                <td style="width: 20px;">
                </td>
                <td v-if="form.filters.show_active">
                  <div @click.stop="() => { selectItem(item, index) }" v-if="($store.state.currentLocation.state != 'WA' || ($store.state.currentLocation.state == 'WA' && (numberToDestroy == 0 || item.display)))" style="cursor: pointer">
                  <div v-if="item.display && (item.display == true)" class="check-circle circle-on"></div>
                  <div v-else class="circle-off"></div>
                  </div>
                  </td>
                <td>{{item.created_date}}</td>
                <td>{{item.batch_name || '-'}}</td>
                <td>{{ item.batch_id || '-'}}</td>
                <td>{{item.expiry_date || '-'}}</td>
                <td><button style="max-width: 175px;min-width: 175px;" :class="[item.status == 'Good' ? 'completed-button' : (item.status == 'Expiring Soon' ? 'expiring-button' : 'button expired-button')]">{{ item.status }}</button></td>               
            </tr>
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
               <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr;
               border-width: 0px 1px 0px 1px; border-style: solid; border-color: #d5d7d7;width: 83vw;">
                  <div v-if="!item.medications" style="margin: 0 auto">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                  </div>
                  <div v-else class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;
                  display: flex;flex-direction: column;gap: 30px;width: 100%;margin: 0px">
                  <div class="notesandDrugclass">
                    <div class="transfer-notes-container">
                       <p class="field-title">Note</p>
                       <div class="field-notes fieldnotesclass">
                           <p >{{item.notes}}</p>
                       </div>
                     </div>
                   <div class="transfer-drugs-container" style="max-width: 100%;">
                       <h4 class="field-title">Medications</h4>
                       <div class="drug-field-tables drugfieldtables" >
                        <table>
                            <tr class="drugstablehead">
                              <th style="width: 40%;">Medications</th>
                              <th>Status</th>
                              <th>Invoice No.</th>
                              <th>Count</th>
                            </tr>
                        </table>
                        <div class="drugtablediv">
                             <table>
                                <tr class="drugtablerow" v-for="(drug,key) in item?.medications" :key="key">
                                    <td style="padding:0px 20px;width: 40%;">{{drug.medication}}</td>
                                    <td>{{drug.action || '--'}}</td>
                                    <td>{{drug.invoice_number}}</td>
                                    <td>{{drug.quantity}}</td>
                                </tr>
                            </table>
                        </div>
                       </div>
                  </div>
                </div>

                  </div>
               </div>
                <!-- TRANSFER ACTIONS -->
                <div class="transfer-actions-container" style="display: flex; align-items: center;gap: 10px;border-width: 0px 1px 1px 1px; border-style: solid; border-color: #d5d7d7;border-radius: 0px 0px 8px 8px;width: 83vw;padding: 20px;">
                  <div style="display:flex;justify-content:end;gap:10px;width: 100%;margin-left: 18px;align-items: center;">
                      <!-- Reverse Transfer / Reverse Destruction -->
                      <!-- <button v-if="item?.medications?.length > 0 && form.filters.show_active" @click="reverseTransfer(item)" class="button reversed-button" style="min-width: 200px">Reverse Transfer</button>
                      <button v-if="item?.medications?.length > 0 && form.filters.show_active" @click="reverseDestruction(item)" class="button warning-button" style="min-width: 200px" >Reverse Destruction</button> -->
                      <!--TRANSFER -->
                      <button :disabled="isDisabledConfirm(item)" v-if="item?.medications?.length > 0 && form.filters.show_active" class="button pending-button" :style="{ minWidth: '200px', backgroundColor: isDisabledConfirm(item) ? '#b8b8b8' : '#9F5CC0', cursor: isDisabledConfirm(item) ? 'auto' : 'pointer'}" @click="openTransferModal(item)">Transfer Out</button>

                      <!-- PENDING DESTRUCTION -->
                      <button :disabled="isDisabledConfirm(item)" v-if="item?.medications?.length > 0 && form.filters.show_active" class="button pending-button" :style="{ minWidth: '200px', backgroundColor: isDisabledConfirm(item) ? '#b8b8b8' : '#EF9600',cursor: isDisabledConfirm(item) ? 'auto' : 'pointer'}" @click="openDestructionModal(item)">Pending {{$store.state.company.type == 'warehouse' ?  'Destructions' : 'Disposals'}}</button>

                      <button :disabled="isDisabledConfirm(item)" v-if="item?.medications?.length > 0 && form.filters.show_active" class="button reversed-button" :style="{ minWidth: '200px', backgroundColor: isDisabledConfirm(item) ? '#b8b8b8' : '#E04F39',cursor: isDisabledConfirm(item) ? 'auto' : 'pointer'}" @click="openBatchesModal(item)">Remove Item from Batch</button>
                      <button @click="showContent(item, index)" class="crossbutton"><i class="pi pi-times"></i></button>
                    </div>
                </div>
            </tr>    
          </tbody>
          
        </table>
        </div>
        <div v-else>
          <h3 v-if="$store.state.batchError">{{$store.state.batchError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links" v-if="$store.state.drugEntries && $store.state.drugEntries.meta?.current_page">
            <p class="pagination-arrow" v-show="$store.state.drugEntries.meta?.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state.drugEntries.meta?.current_page}} of {{$store.state.drugEntries.meta?.last_page}}</p>
            <p class="pagination-arrow" v-show="$store.state.drugEntries.meta?.current_page < $store.state.drugEntries.meta?.last_page" @click="displayPage( $store.state.drugEntries.meta?.current_page + 1)"> &rarr; </p>
          </div>
       </div>
    </div>
    <div v-else class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <BatchesModal v-if="batchesModal > 0" :openInvoice="openBatches" :openstatus=batchesModal @close="closeBatches" @changestatus="changestatus" :successName="successName"/>

    <BatchModal v-if="displayBatchModal" @close="close"  />

    <NewTransferModal v-if="displayTransferModal || displayReverseTransfer" :actionParentdata="payload" :checkItemBoxView="true" @close="close" />
      
    <DestructionModal v-if="displayDestructionModal" :actionParentdata="payload" :checkItemBoxView="payload?.items && payload?.items.length > 0 ? true : false" @close="close" :ispendingDestruct="true" />
    <SignatureModal v-if="displayActionModal" @close="close" @isSuccessful="isSuccessful" :payload="payload" :endpoint="endpoint" dual="true" :loop="true" :notes="requireNotes" isStatus='Destruction'/>
    <!-- <ActionModal :toAction="toAction" /> -->
  </div>
</template>


<script>
import DestructionModal from '../../components/modals/DestructionModal.vue';
import NewTransferModal from '../../components/modals/NewTransferModal.vue';
import BatchModal from '../../components/modals/BatchModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import axios from 'axios';
import BatchesModal from '@/components/modals/BatchesModal.vue';
import debounce from "debounce"


export default {
  components: { 
      BatchModal, 
      SignatureModal,
      BatchesModal,
      NewTransferModal,
      DestructionModal
  },
  data(){
    return{
        // checked:false,
        batchesModal:0,
        openBatches :null,
        requireNotes: false,
        endpoint: '',
        loadingError:'',
        displayActionModal: false,
        displayBatchModal: false,
        displayDestructionModal:false,
        displayTransferModal:false,
        displayDeliveryModal: false,
        toAction:{
            uuid:'',
            action: 'Approve',
            drugs:[]
        },
        selectedStatuses: [],
        statusSelect: [
            {status:'Expired'},
            {status:'Expiring Soon'},
            {status:'Good'}
        ],
        tags:{
            s8:false,
            s4: false,
            antimicrobial: false,
        },
      transferType:'',
      drugNames: [],
      registerData: [],
      filters:{
          processed: false,
          pending: false,
          all: true,
          in_progress: false,
          completed: false
      },
      types:{
          send: true,
          receive: true
      },
      toCache:[],
      date:[],
      clearForm: {
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters: {
          date: {
            start: '',
            end: ''
          },
          batch_name: "",
          drug: "",
          status: [],
          show_active: true,
        },

      },
      form: {
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters: {
          date: {
            start: '',
            end: ''
          },
          batch_name: "",
          drug: "",
          status: [],
          show_active: true
        },

      },
      testData:[
      ],
      payload:{
       batch_uuid:[]
      },
      isAllDrugSelected:false,
      successName:'Batch Items Deleted Successfully',
      showReverseButton: false,
      selectedDrugs: [],  
      isChecked: false ,
      displayReverseDestructionModal: false,
      displayReverseTransfer:false,
      openInvoice:null,
      completedDelivery:0,
      actions: [
        "Pending Destruction",
        "Partial Destruction",
        "Destroyed",
        "Complete Destruction",
        "Transferred",
        "Partial Transferred",
        "Complete Transferred",
        "Partially Approved",
        "Pending Approval",
      ]
    }
  },
  methods:{
    // toggleSelectAll(select){
    //   this.getDrugEntries.forEach(entry=>{
    //     if(entry.status == 'pending'){
    //       if(select){
    //       entry.to_sign = true;
    //     } else{
    //       entry.to_sign = false;
    //     }
    //     }
        
    //   })
    // },
    allSelected() {
      if (this.numberToDestroy > 0) {
        const allSelect = this.getDrugEntries.every(entry => entry.display && entry.display == true)
        if (allSelect) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    destroyItems() {
      this.payload = [{
        batch_uuid: []
      }]
      this.endpoint = '/warehouse/delete-batch'
      this.getDrugEntries.forEach(item => {
        if (item.display == true) {
          this.payload[0].batch_uuid.push(item.uuid);
        }
        console.log(this.payload, "payload")
      })
      this.displayActionModal = true;
    },
    signOrders(){
      this.payload = {
        orders:[]
      }
      this.endpoint = '/warehouse/orders/sign'
      this.getDrugEntries.forEach(order=>{
        if(order.to_sign){
          this.payload.orders.push(order.uuid);
        }
      })
      this.displayActionModal = true;

    },
    close(value,value2){
        if (value) {
          this.displayActionModal = false;
          this.displayBatchModal = false;
          this.displayDestructionModal = false;
          this.displayTransferModal = false;
          this.displayDeliveryModal = false;
          this.displayReverseDestructionModal = false;
          this.displayReverseTransfer = false
        }
        if (value2 == 'success') {
          this.$store.dispatch('getBatches', this.form);
        }
    },
    selectItem(item, index) {
      console.log('this the selected log', item, index)
      // let refid=`expand-${index}`;
      if (this.getDrugEntries[index].display) {
        this.getDrugEntries[index].display = false;
      } else {
        this.getDrugEntries[index].display = true;
      }
    },
    selectAllItem(select) {
      const allSelect = this.getDrugEntries.every(entry => entry.display && entry.display == true)
      this.getDrugEntries.forEach(entry => {
        if (select) {
          entry.display = true;
        } else {
          if (allSelect) {
            entry.display = false;
          } else {
            entry.display = true;
          }
        }
      })
    },
    isSuccessful(value){
      console.log('this has been', value,this.payload);
      if (value) {
        // this.displayActionModal = false;
        // this.displayBatchModal = false;
        // this.displayDestructionModal = false;
        // this.displayTransferModal = false
        this.displayActionModal = false;
      }
      this.$store.dispatch('getBatches', this.form);
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.loadEntries();
    },
    showContent(delivery, index){
        console.log('this the selected log', delivery)
        // let refid=`expand-${index}`;

        if(delivery.expand && (delivery.expand === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            delivery.expand = !delivery.expand;
        } else{
            delivery.expand = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
            axios.post('/warehouse/retrieve-batch-details', {batch_uuid: delivery.uuid}, this.$store.state.header).then(res=>{
                let entries = res.data.data[0];
                delivery.notes = entries?.notes
                delivery.medications = entries?.batch_items
            }).catch(err=>{
              this.loadingError = err;
              console.log('this is the error, err');
            })
            
            //  console.log("showcontent>>",this.getDrugEntries);
        }
    },
    loadEntries() {
      console.log('this is the date', this.date);
      if (this.date.length) {
        if (typeof this.date == "string") {
          this.form.filters.date.start = this.date.split("-")[0];
          this.form.filters.date.end = this.date.split("-")[1];
        } else {
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString("en-au", { timeZone: "Australia/Sydney" });
          this.form.filters.date.end = this.date[1] ? new Date(this.date[1]).toLocaleDateString("en-au", { timeZone: "Australia/Sydney" }) : new Date(this.date[0]).toLocaleDateString("en-au", { timeZone: "Australia/Sydney" });
        }
        this.$store.dispatch('getBatches', this.form);
      } else {
        this.form.filters.date.start = '';
        this.form.filters.date.end = '';
        this.$store.dispatch('getBatches', this.form);
      }
      console.log('request: ', this.$store.state.latestDrugEntriesMutationRequest);
    },

  debouncedLoadEntries: debounce(function() {
    this.loadEntries();
  }, 500),

    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getBatches', this.form);
    },
    updateStatusList(){
      this.checked = false
      this.form.filters.status = [];
      console.log('updating status list')
      this.selectedStatuses.forEach(statusObject=>{
          for (const [key, value] of Object.entries(statusObject)) {
              console.log('this is the key', key);
              this.form.filters.status.push(value);
           }
      });
      this.debouncedLoadEntries();
  },
  // updateListing(){
  //   this.form.filters.status = [];
  //   if (this.checked) {
  //     this.selectedStatuses = [
  //         new Proxy({ status: 'Expired' }, {}),
  //         new Proxy({ status: 'Expiring Soon' }, {})
  //     ];
  //     this.selectedStatuses.forEach(statusObject=>{
  //         for (const [key, value] of Object.entries(statusObject)) {
  //             this.form.filters.status.push(value);
  //          }
  //     });
  //   }else {
  //     this.selectedStatuses =[]
  //   }
  //   this.loadEntries();
  // },
    clearEntriesFilters() {
      this.form = {
        sort_direction: 'desc',
        col: 'created_at',
        page: 1,
        filters: {
          date: {
            start: '',
            end: ''
          },
          batch_name: "",
          drug: "",
          status: [],
          show_active: true,
        },

      },
      this.selectedStatuses = []
      this.date = [];
      this.checked = false
      this.$store.dispatch('getBatches', this.form);
    },
    openBatchesModal(item) {
      this.batchesModal = 1
      const filterForRemove = item.medications.filter((item) => !this.actions.includes(item?.action));
      this.openBatches = { uuid: item.uuid, medications: [...filterForRemove] }
    },
    isDisabledConfirm(item) {
      return item.medications.every(item =>
        this.actions.includes(item?.action)
      );
    },
    openTransferModal(item) {
      const filterForTransfer = item.medications.filter((item) => !this.actions.includes(item?.action));
        const transferdrug = filterForTransfer.map(item => ({
          batch_uuid: item?.batch_item_uuid,
          drug: item?.stock_item_uuid,
          stock: item.medication,
          qty: Number(item.quantity),
          batch_item_id: item?.batch_item_id,
          tags: item?.tags
        }));
        this.payload.items = transferdrug;
      this.transferType = 'Send',
        this.displayTransferModal = true
      this.endpoint = "/transfers/new-transfer"
    },
    openDestructionModal(item) {
      const filterForDestruct = item.medications.filter((item) => !this.actions.includes(item?.action));
        const destDrug = filterForDestruct.map(item => ({
          batch_uuid: item?.batch_item_uuid,
          drug: item?.stock_item_uuid,
          stock: item?.medication,
          qty: Number(item?.quantity),
          batch_item_id: item?.batch_item_id,
          tags: item?.tags
        }));
        this.payload.items = destDrug;
      this.transferType = "Destruction",
      this.displayDestructionModal = true
      this.endpoint = '/location/' + this.$store.state.currentLocation.uuid + '/destroy'
    },
  //   reverseDestruction(items) {
  //     const destDrug = items.medications.map(item => ({
  //         action: item.action == "Pending Destruction" ? false : true, 
  //         drug: item?.unit_uuid,
  //         medication: item.medication,
  //         qty: Number(item.quantity),
  //         date: items.expiry_date,
  //         // batch_item_id: item?.batch_item_id,
  //     }));

  //     this.payload.items = destDrug;
  //     this.transferType = "Destruction";
  //     this.displayReverseDestructionModal=true ;
  //     this.endpoint = '/reverse-destruction'
  // },

  //  reverseTransfer(items) {
  //     const destDrug = items.medications.map(item => ({
  //         // action: item.action == "Pending Transfer" ? false : true, 
  //         action: true, 
  //         drug: item?.unit_uuid,
  //         medication: item.medication,
  //         qty: Number(item.quantity),
  //         date: items.expiry_date,
  //         batch_item_id: item?.batch_item_id,
  //     }));

  //     this.payload.items = destDrug;
  //     this.transferType = "Destruction";
  //     this.displayReverseTransfer=true ;
  //     // this.endpoint = '/single-transfer/reverse'
  // },

// reverseDestroyItems() {
//   const reverseDrug = [];
//   this.getDrugEntries.forEach(item => {
//       if (item.display == true) {
//           reverseDrug.push({
//               drug: item?.uuid,
//               medication: item?.alias?.name,
//               qty: item.quantity,
//               date: item.date,


//           });
//       }
//   });
//   this.payload.items = reverseDrug;
//   console.log('reverse items function is running', this.payload.items);
//   this.displayReverseDestructionModal = true;
//  },
    toggleBatchModal(){
      this.payload = {}
      this.displayBatchModal = true
    },
    closeBatches(value){
      this.batchesModal = value   
    },
    changestatus(value){
      this.batchesModal = value
      if (value === 2) {
        this.$store.dispatch('getBatches', this.form);
        // this.displayReverseDestructionModal = false;
        // this.buttonName = 'New Destruction'
      }
    },
  },
   mounted(){
    this.loadEntries();
    this.toCache = [];
  },
  computed:{
    getDrugEntries (){
      return this.$store.getters.getDrugEntries
    },
    numberToDestroy() {
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.display) {
            num += 1;
          }
          console.log("item.display", num)
        })
      }
      return num;
    },
  },
  watch:{
  date:{
   handler(newValue){
    if (newValue?.length > 0) {
      this.debouncedLoadEntries()
    }
   },
   deep: false
  }
}
}
</script>
<style lang="scss" scoped>
.filters {
div {
  margin-right: 0px !important;
}
}
.submit-button {
  margin-right: 0px !important;
}
.clear-button {
  margin-right: 0px !important;
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/main.scss";
.select-row {
background-color: #E5F1FE; //$babyblue;
}
.pi-circle-on,
.pi-circle-off,
.pi-check-circle {
color: #0870DC; //$strongblue;
opacity: 50%;
font-weight: bold;
}
.circle-on,
.circle-off,
.check-circle {
font-weight: bold;
width: 20px;
height: 20px;
border-radius: 20%;
margin-left: 5px;
border: 2px solid #0870DC; //$strongblue;
}
.circle-on {
background-color: #0870DC; //$strongblue;
}
.pendingborder{
border-radius: 8px 0px 0px 8px !important;
}
.destructionborder{
border-radius: 0px 8px 8px 0px !important;
}
tr{
    height: 60px;
}
.open-transfer-row {
    background-color: #0870dc17 !important;
    border-radius: 0px;
    cursor: pointer;
}
.open-transfer-row>td {
  border-top: 1.5px solid #0870DC;
  border-bottom: 1.5px solid #0870DC;
  position: relative;
}
.open-transfer-row>td:first-child {
  border-top: 1.5px solid #0870DC;
  border-left: 1.5px solid #0870DC;
}
.open-transfer-row>td:last-child {
  border-top: 1.5px solid #0870DC;
  border-right: 1.5px solid #0870DC;
}
.adjustbtnstyle{
  margin: 0px;
  height: 35px;
  padding: 0px 23px
}
.calendar-input-container{
    background-color: #F3F4F4;
    border: 1px solid #ced4da;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
::placeholder {
  color: #282828 !important;
}
.multiselectstatus{
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 8px !important;
    background: #F3F4F4 !important;
    width: 258px;
    max-width: 258px;
    padding: 0px 0px 0px 13px !important;
    
  }
  input {
    max-height: 100%; 
  }
</style>
