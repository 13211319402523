<template>
    <div class="chart-form-approval-container" style="justify-content: space-between; margin-top: 1em; height: 100%;">
        <!-- BEGINNING OF CHART -->
        <div class="chart-approval-only" :style="!displayDropDown ? { marginLeft: '-3px' } : {}"
            style="display: flex; flex-wrap: wrap; margin-bottom: 10px">
            <div :ref="'height-measurer' + parentIndex" :id="'height-measurer' + parentIndex"
                style="margin-right: 12px;">
                <div class="chart-form-container">
                    <div class="chart-form" :style="!displayDropDown ? { backgroundColor: 'transparent' } : {}">
                        <!-- displayDropDown is a conditional on whether to display the chart drop down, it is being used as defacto patient summary modal view conditional -->
                        <div class="zero-chart-row chart-row" v-if="displayDropDown">
                            <div class="first-chart-row-item chart-row-item"
                                style="display: grid !important; align-content: start; min-height: 40px;">
                                <p v-if="displayDropDown" style="text-align: left; margin-left: 20px; font-size: 1em;">
                                    Select Chart Type</p>
                                    
                                <Dropdown v-if="!viewOnly" v-model="selectedChart" :options="charts" 
                                    optionLabel="name" placeholder="Select Chart Type"
                                    style="margin-left: 9px; text-align: left;" id="selectchart"
                                    :style="selectedChart.color ? { backgroundColor: selectedChart.color } : ''"
                                    :class="selectedChart.color ? 'active-selected-chart' : ''" 
                                    @change="clearForm(), checkIfPRNOrInsulin(), assignFrequencyList()"
                                    />
                                <h3 v-else :style="selectedChart.color ? { backgroundColor: selectedChart.color } : ''"
                                    :class="selectedChart.color ? 'active-selected-chart' : ''"
                                    style="margin: 0; padding: 5px; border-radius: 8px; margin-left: 13px; text-align: left; color: white;">
                                    {{ selectedChart.name }}</h3>
                                <p style="color: red; font-weight: bold; text-align: left; font-size: 1em; margin-left: 20px;">Note: For nutritional supplements, please use the Nutritional Supplement chart.</p>
                                <!-- <div class="text-search-container search-container" style="display: flex; align-items: center">
                                    <input v-model="searchPatient" placeholder="Search Patient" style="position: relative; width: fit-content; margin-bottom: 5px" /> 
                                    <div style="cursor: pointer;" @click="searchForPatient(searchPatient)"><i class="pi pi-search" style="font-weight: bold"></i></div>
                                    <span v-if="prescription.patient">{{prescription.patient.first_name}}</span>
                                </div> -->
                                <!-- <div class="patient-search-results-container" v-if="patientSearchResults">
                                    <div class="individual-patient-container" v-for="(item, index) in patientSearchResults" :key="index" @click="selectPatient(item)">
                                        <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">
                                        </div>
                                        <div class="patient-result-info">
                                                <p v-if="item.personal_information"><b>Name:</b> {{item.personal_information.first_name}} {{item.personal_information.last_name}}</p>
                                                <p v-if="item.regulatory_information && item.regulatory_information.length"><b>Medicare Number:</b>  {{ item.regulatory_information[0].data.slice(0,4)}} {{item.regulatory_information[0].data.slice(4, 9)}} {{item.regulatory_information[0].data.slice(9)}}</p>
                                                <p><b>DOB:</b> {{item.personal_information && item.personal_information.dob ? item.personal_information.dob : ''}}</p>
                                                <p><b>Location:</b> {{item.physical_location && item.physical_location.location ? item.physical_location.location.name : ''}}</p>
                                        </div>
                                    </div>
                                    <div v-if="!patientSearchResults.length">
                                        <h4>No results found.</h4>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div ref="first-chart-row" v-if="selectedChart" class="first-chart-row chart-row">
                            <div class="first-chart-row-item chart-row-item"
                                :style="displayDropDown ? { minHeight: '60px' } : { minHeight: '43px' }"
                                style="display: flex !important; gap: 20px;">
                                <div style="padding-left: 10px;" v-if="displayDropDown">
                                    <SelectButton style="display: flex; height: 38px" v-model="drugSearchFilter"
                                        :options="searchTypes" optionLabel="name" optionValue="value" @click="clearCustomMedForm()" />
                                </div>
                                <!-- MED SELECT OPTION -->
                                <div style="width: 100%; height: 100%; display: flex; align-items: center">
                                    <Dropdown
                                        id="prescription-med-search"
                                        v-if="displayDropDown"
                                        v-model="searchedDrugName"
                                        ref="med"
                                        @keyup="algoliaDrugSearch(searchedDrugName)"
                                        @change="drugSelect(searchedDrugName)"
                                        :loading="loadingDrugs"
                                        :options="formattedDrugList"
                                        optionLabel="full_name"
                                        :disabled="drugSearchFilter == 3"
                                        :editable="true"
                                        style="width: 100%; display: flex" placeholder="Search Medication"
                                    />
                                    <div v-else style="display: grid; grid-template-columns: 3fr 1fr 1fr; grid-gap: 20px; align-items: center; height: 100%; width: 100%; margin-bottom: 10px;">
                                        <div style="margin-top:-25px">
                                            <p  :style="dashboardRenew ? { marginLeft: '10px !important'}:{ marginLeft: '20px'}"
                                                style="text-align: left; margin-bottom: 5px; font-size: 1em; font-weight: 500"
                                                >
                                                Medication
                                            </p>
                                            <inputText id="medication-name-field"
                                                :placeholder="!loadingDrugs ? selectedDrug.full_name ? selectedDrug.full_name : draftDrug ? draftDrug.name : 'Updating...' : ''"
                                                style="background-color: white; padding:5px 10px; border-radius: 6px; border: 1px solid #f3f4f4; display: flex; margin-left: 10px; width: 24em; font-weight: normal" disabled />
                                        </div>
                                        <MimsImage
                                            v-if="mimsApiKey"
                                            id="mims_image"
                                            :alias_id="draftDrug ? draftDrug.alias_id : ''"
                                            off_market="true"
                                            width="120px"
                                            height="100px"
                                            style="justify-self: center;"
                                            @mimsImageChanged="mimsImageChanged">
                                        </MimsImage>
                                        <button
                                            v-if="mimsApiKey"
                                            class="black-button"
                                            @click="displayCMIPIModal = true">
                                            View CMI/PI
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <!-- CUSTOM MED CREATION -->
                        <!-- CUSTOM MED CREATION -->
                        <!-- CUSTOM MED CREATION -->
                        <h3 v-if="drugSearchFilter == 3" style="margin: 0; text-align: left; padding-left: 20px;">Create Custom Medication</h3>
                        <div  v-if="drugSearchFilter == 3" class="chart-row prescription-chart" style="grid-column-start: 1; grid-column-end: 4; width: 707px;; display: grid;">
                            
                            <div class="chart-row-item"
                                style="width: fit-content; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; margin: 0 10px; padding: 5px 0; border-radius: 5px; background-color: #f3f4f4">
                                <div v-tooltip="'Medication Name'" style="grid-column-start:1; grid-column-end: 3">
                                    <p style="text-align: left; margin-left: 10px;">
                                        Full Name
                                    </p>
                                    <InputText style="min-width: 450px; width: 450px;"
                                        v-model="customMedication.concat_name"
                                        placeholder="e.g. Salicylic acid 5% in Aqueous Cream"
                                    />
                                </div>
                                <div v-tooltip="'Medication Name'" style="grid-column-start:4; grid-column-end: 5; margin-left: -40px;">
                                    <p style="text-align: left; margin-left: 10px;">
                                         Active Ingredient
                                    </p>
                                    <InputText style="min-width: 200px; width: 200px;"
                                        v-model="customMedication.name"
                                        placeholder="e.g. Salicylic acid"
                                    />
                                </div>
                                <div v-tooltip="'S8'">
                                    <p style="text-align: left; margin-left: 10px;">
                                        S8
                                    </p>
                                    <Dropdown
                                        id="s8-type"
                                        style="width: 150px"
                                        placeholder="Yes or No"
                                        :options="yes_or_no"
                                        optionValue="value"
                                        optionLabel="label"
                                        :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription')"
                                        v-model="custom_s8"
                                    />
                                </div>
                                <div v-tooltip="'Strength'">
                                    <p style="text-align: left; margin-left: 10px;">
                                        Strength
                                    </p>
                                    <InputText type="number" style="min-width: 150px; width: 150px;"
                                        v-model="customMedication.strength"
                                        placeholder="e.g. 9600"
                                    />
                                </div>
                                <div v-tooltip="'Form'">
                                    <p style="text-align: left; margin-left: 10px;">
                                        Form
                                    </p>
                                    <InputText style="min-width: 150px; width: 150px;"
                                        placeholder="e.g. cream"
                                        v-model="customMedication.form"
                                    />
                                </div>
                                <div v-tooltip="'Quantity'">
                                    <p style="text-align: left; margin-left: 10px;">
                                        Quantity
                                    </p>
                                    <InputText style="min-width: 150px; width: 150px;"
                                        v-model="customMedication.quantity"
                                        placeholder="e.g. 100g"
                                    />
                                </div>
                                <div v-tooltip="'Measurement Unit'">
                                    <p style="text-align: left; margin-left: 10px;">
                                        Measurement Unit
                                    </p>
                                    <div style="display: flex; align-items: flex-end">
                                        <InputText style="min-width: 150px !important; width: 150px !important;"
                                            v-model="customMedication.measurement_unit_1"
                                            placeholder="e.g. mg or mg/mL"
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                            <div style="width: 98%; display: flex; align-items: center; justify-content: flex-end; margin-top: 10px;">
                                <div v-if="medicationSaveStatus" style="margin-right: 10px;">
                                    <Button class="outline-button p-button-outlined" :loading="medicationSaveStatus.includes('Saving')" :class="medicationSaveStatus == 'Medication saved!' ? 'p-button-success': medicationSaveStatus ? 'p-button-warning':''" v-if="medicationSaveStatus" :label="medicationSaveStatus" />
                                </div>
                                <button class="blue-button" @click="saveCustomMedication()" v-if="medicationSaveStatus != 'Medication saved!'">Save Medication</button>
                                <button class="grey-button" style="margin-left: 10px;" @click="drugSearchFilter = 0" v-if="medicationSaveStatus != 'Medication saved!'">Cancel</button>
                            </div>
                            <div style="width: 95%; height: 2px; background-color: #cdcdcd; margin: 0 auto; margin-top: 10px; margin-bottom: 20px;"></div>
                            
                        </div>
                        <!-- expansionFormat -->
                        <div v-if="selectedChart" class="chart-row prescription-chart"
                            :class="displayDropDown ? '' : ''" style="flex-wrap: wrap; width: 707px">
                            <!-- v-for="(item, index) in selectedChart.inputs" :key="index" -->
                            <div id="reason-input-container"
                                style="grid-column-start: 1; grid-column-end: 4; width: 707px;"
                                v-if="!displayDropDown && !dashboardRenew && selectedChart">
                                <div style="display: flex;">
                                    <p style="text-align: left; font-size: 1em; font-weight: 500; margin: 0; margin-left: 20px;">
                                        Prescription Type
                                    </p>
                                    <p style="text-align: left; font-size: 1em; font-weight: 500; margin: 0; margin-left: 80px;">
                                        Indication
                                    </p>
                                </div>
                                <div class="col-11 md:col-4">
                                    <div class="p-inputgroup" style="display: flex;">
                                        <Button @click="prescriptionType = 'private', pbsFilter = '', changePBSOption()" style="margin-left: 10px;
                                                border-top-right-radius: 0px !important;
                                                border-bottom-right-radius: 0px !important;
                                                height: 35px;"
                                            label='Private'
                                            :class="prescriptionType == 'private' ? 'blue-button':'p-button-secondary p-button-outlined'"
                                            :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                        />
                                        
                                        <Button
                                            @click="displayStreamlineCodeModal = true, prescriptionType = 'pbs/rpbs', pbsFilter = 'pbs', changePBSOption()"
                                            style="border-right: none;
                                                border-left: none;
                                                border-top-right-radius: 0px !important;
                                                border-bottom-right-radius: 0px !important;
                                                border-top-left-radius: 0px !important;
                                                border-bottom-left-radius: 0px !important;
                                                height: 35px;"
                                            :disabled="!pbs_codes.length || !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                            :label="loading_pbs ? 'Loading...': 'PBS/RPBS'" :class="prescriptionType == 'pbs/rpbs' ? 'blue-button':'p-button-secondary p-button-outlined'"
                                        />
                                        <inputText
                                            v-tooltip="'Indication'"
                                            placeholder="Select from PBS/RPBS list or type indication for private medication"
                                            style="min-width: 50%; width: 553px;  border: 1px solid #c3d4c3"
                                            :disabled="(!selectedChart.inputs.includes('indication') && !indications_list.length) || !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                            v-model="form.prescription_payload.indication"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="valid-prescription-notes" v-if="!displayDropDown && !dashboardRenew && selectedChart"
                                style="margin-top: 0; padding-bottom: 0; background-color: transparent">
                                <div class="prescriber-options-container"
                                    :style="selected_pbs_code.restriction_flag == 'A' || form.prescription_payload.authority_prescription_number ? {} : { justifyContent: 'flex-start' }"
                                    style="align-items: self-end; margin-left: 0px; margin-top: 0; height: 53px;">
                                    <div :style="selected_pbs_code.restriction_flag == 'A' ? {} : {}" style="width: 25%">
                                        <p style="margin: 0; text-align: left; margin-left: 10px; font-size: 1em; font-weight: 500; "
                                            :style="selected_pbs_code.restriction_flag == 'A' || dashboardRenew ? { marginLeft: '10px' } : {}">
                                            PBS Code 
                                        </p>
                                        <InputText
                                            disabled
                                            style="width: 100%; border: 1px solid #ced4da;"
                                            :style="displayDropDown ? {backgroundColor: '#F3F4F4'}: dashboardRenew ? {marginLeft: '10px'}:{}"
                                            v-model="form.prescription_payload.pbs_code"
                                        />
                                        
                                    </div>
                                    
                                    <div v-if="form.prescription_payload.pbs_type && form.prescription_payload.pbs_type != ''" style="margin-left: 20px;width: 25%">
                                        <p style="margin: 0; text-align: left; margin-left: 10px; font-size: 1em; font-weight: 500; "
                                            :style="selected_pbs_code.restriction_flag == 'A' || dashboardRenew ? { marginLeft: '10px' } : {}">
                                            PBS Type 
                                        </p>
                                        
                                        <InputText
                                            disabled
                                            style="width: 100%; border: 1px solid #ced4da;"
                                            :style="displayDropDown ? {backgroundColor: '#F3F4F4'}: dashboardRenew ? {marginLeft: '10px'}:{}"
                                            :value="translatePbsType(form.prescription_payload.pbs_type)"
                                        />
                                    </div>
                                    <div
                                        v-if="(pbsFilter == 'pbs' || pbsFilter == 'rpbs') && (selected_pbs_code.restriction_flag == 'A' || form.prescription_payload.authority_prescription_number)" style="margin-left: 20px;width: 25%">
                                        <p style="margin: 0; text-align: left; margin-left: 15px; font-size: 1em; font-weight: 500; ">
                                            Authority Number
                                        </p>
                                        <InputText disabled style="width: 100%; border: 1px solid #ced4da"
                                            placeholder="Create Number"
                                            :style="displayDropDown ? { backgroundColor: '#F3F4F4' } : {}"
                                            v-model="form.prescription_payload.authority_prescription_number"
                                            @click="form.prescription_payload.authority_prescription_number ? '' : updatePBSPrescription()" />
                                    </div>
                                    <div 
                                        v-if="dispensing_apn" style="margin-left: 20px;width: 25%">
                                        <p style="margin: 0; text-align: left; margin-left: 15px; font-size: 1em; font-weight: 500; ">
                                            Authority Number
                                        </p>
                                        <InputText disabled style="width: 100%; border: 1px solid #ced4da" 
                                            :style="displayDropDown ? { backgroundColor: '#F3F4F4' } : {}"
                                            v-model="dispensing_apn" />
                                    </div>
                                    <div
                                        :style="selected_pbs_code.restriction_flag == 'A' ? {} : { marginLeft: '20px' }" style="width: 25%">
                                        <div>
                                            <p style="margin: 0; text-align: left; margin-left: 10px; font-size: 1em; font-weight: 500; ">
                                                Approval Code <span v-if="form.prescription_payload.authority_prescription_number" style="color: red; font-size: 1em">*</span>
                                            </p>
                                        </div>
                                        <span class="p-input-icon-right">
                                            <InputText
                                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                :style="displayDropDown ? {backgroundColor: '#F3F4F4'}:{}"
                                                style="width: 100%; border: 1px solid #ced4da;"
                                                type="text"
                                                v-model="form.prescription_payload.pbs_authority_code"
                                            />
                                        </span>
                                    </div>
                                    
                                </div>
                                <p style="font-size: 1em; color: red" v-if="form.prescription_payload.authority_prescription_number && !form.prescription_payload.pbs_authority_code">Authority scripts require an approval code in order to sign.</p>
                            </div>
                            
                            <div class="chart-row-item"
                                style="width: fit-content; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; min-width: 717px; margin-top: 7px;">
                                <div style="display: grid; " v-tooltip="'Start Date'" :style="dashboardRenew ? {backgroundColor: '#FED182', borderRadius: '4px', margin:'0 5px', paddingBottom: '5px'}:{}">
                                    <p
                                        style="text-align: left; margin-left: 20px; margin-bottom:5px;"
                                        :style="!selectedChart.inputs.includes('start_date') && !selectedChart.inputs.includes('datetime') ? { color: '#C8C8C8' } : ''"
                                    >
                                        Start Date
                                    </p>
                                    <Calendar
                                        id="startdateformat"
                                        @date-select="formatDate('start')"
                                        :disabled="((!selectedChart.inputs.includes('start_date') && !selectedChart.inputs.includes('datetime')) || !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll) && !dashboardRenew"
                                        v-model="start_date"
                                        dateFormat="dd/mm/yy"
                                        placeholder="Start Date" />
                                </div>

                                <div style="display: grid;" v-tooltip="'Stop Date'"
                                    v-if="selectedChart.inputs.includes('stop_date') && selectedChart.chart_name != 'regular'"
                                    :style="dashboardRenew ? {backgroundColor: '#FED182', borderRadius: '4px', margin:'0 5px', paddingBottom: '5px'}:{}">
                                    <p style="text-align: left; margin-left: 20px; margin-bottom:5px;"
                                        :style="!selectedChart.inputs.includes('stop_date') ? { color: '#C8C8C8' } : ''">
                                        Stop Date
                                    </p>
                                    <Calendar
                                        id="stopdateformat"
                                        @date-select="formatDate('end')"
                                        v-model="stop_date"
                                        dateFormat="dd/mm/yy"
                                        placeholder="Stop Date"
                                        :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                    />
                                </div>

                                <div style="display: grid; " v-tooltip="'Prescription Date'">
                                    <p style="text-align: left; margin-left: 20px; margin-bottom:5px;">
                                        Created Date
                                    </p>
                                    <Calendar
                                        id="prescriptiondateformat"
                                        disabled
                                        v-model="date_added"
                                        dateFormat="dd/mm/yy"
                                        placeholder="Prescription Date"
                                    />
                                </div>
                                <!-- END DATE -->
                                <div v-tooltip="'Dose Unit'">
                                    <p style="text-align: left; margin-left: 20px; margin-bottom:5px;">
                                        Dose Unit
                                    </p>
                                    <Dropdown
                                        v-if="selectedChart.chart_name != 'insulin'"
                                        id="dose_unit-type"
                                        placeholder="Select Dose Unit"
                                        :options="doseTypes"
                                        :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                        v-model="form.prescription_payload.dose_unit"
                                        @change="selectedChart.chart_name != 'stat' ? updateInstructions() : updateStatInstructions()"
                                    />
                                    <Dropdown v-if="selectedChart.chart_name == 'insulin'" :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll" id="dose_unit-type" placeholder="Select Dose Unit" :options="doseTypeInsulin"
                                        v-model="form.prescription_payload.dose_unit" @change="updateInstructions()" />
                                </div>


                                <div v-tooltip="'Route'">
                                    <p style="text-align: left; margin-left: 20px; margin-bottom:5px;">
                                        Route
                                    </p>
                                    <Dropdown v-if="selectedChart.chart_name != 'insulin'" id="route-type" placeholder="Select Route" :options="administrationTypes" :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                        v-model="form.prescription_payload.route" @change="selectedChart.chart_name != 'stat' ? updateInstructions() : updateStatInstructions()" />
                                    <Dropdown v-else id="route-type" placeholder="Select Route" :options="administrationInsulinTypes" :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                        v-model="form.prescription_payload.route" @change="updateInstructions()" />
                                </div>
                                <div v-tooltip="'Max Dose per Administration'"
                                    v-if="selectedChart.inputs.includes('max_admin')">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('max_admin') ? { color: '#C8C8C8' } : ''">
                                        Max Per Admin Qty({{ form.prescription_payload.dose_unit }})
                                    </p>
                                    <input type="number" min="0" :disabled="!selectedChart.inputs.includes('max_admin') || !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll" 
                                        v-model="form.prescription_payload.max_admin_dose" />
                                </div>

                                <div v-tooltip="'Max Dose in 24hrs'" v-if="selectedChart.inputs.includes('max_admin')">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('max_24_dose') ? { color: '#C8C8C8' } : ''">
                                        24hr Max Dose
                                    </p>
                                    <InputText 
                                        :disabled="!selectedChart.inputs.includes('max_24_dose') || !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                        v-model="form.prescription_payload.max_dose" />
                                </div>
                                <div v-tooltip="'Max X per day'" v-if="selectedChart.inputs.includes('max_admin')">
                                    <p style="text-align: left; margin-left: 10px;">
                                        Max times per day
                                    </p>
                                    <InputText 
                                        v-model="form.prescription_payload.max_times_per_day"
                                        :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                    />
                                </div>
                                <div v-tooltip="'Prescription Quantity'">
                                    <p style="text-align: left; margin-left: 20px;">
                                        Prescription Quantity
                                    </p>
                                    <input
                                        type="number"
                                        mode="decimal"
                                        min="0"
                                        :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                        v-model="form.prescription_payload.prescribed_quantity"
                                        @change="checkPrescribedQty()"
                                    />
                                </div>
                                <div v-tooltip="'Repeats'" v-if="selected_pbs_code.restriction_flag == 'A' || (selectedDrug && selectedDrug.tags ? selectedDrug.tags.includes('s8') || selectedDrug.tags.includes('S8') : false ) || (draftDrug ? (draftDrug.tags.includes('S8') || draftDrug.tags.includes('s8')) : false) || form.prescription_payload.authority_prescription_number || custom_s8">
                                    <p style="text-align: left; margin-left: 20px;">
                                        Repeats
                                    </p>
                                    <input min="0" type="number" :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                            v-model="form.prescription_payload.num_repeats_authorised" @change="checkPrescribedQty()"/>
                                    
                                </div>

                                <div v-tooltip="'Repeat Interval'" v-if="selected_pbs_code.restriction_flag == 'A' || (selectedDrug && selectedDrug.tags ? selectedDrug.tags.includes('s8') || selectedDrug.tags.includes('S8') : false ) || (draftDrug ? (draftDrug.tags.includes('S8') || draftDrug.tags.includes('s8')) : false) || form.prescription_payload.authority_prescription_number || custom_s8">
                                    <p style="text-align: left; margin-left: 20px;">
                                        Repeat Interval (Days)
                                    </p>
                                    <!-- <InputNumber 
                                        :min="0"
                                        v-model="form.prescription_payload.repeat_interval"
                                        :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription')"
                                    /> -->
                                    <input min="0" type="number" :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                    v-model="form.prescription_payload.repeat_interval"/>
                                    
                                </div>
                                <!-- END OF INTERVAL -->
                            </div>
                            <div style="width: 100%; overflow: auto" v-if="error_message">
                                <p style="margin: 0 auto; font-size: 1em; color: red;">
                                    Error message: {{ error_message }}
                                </p>
                            </div>
                            <div style="width: fit-content;
                                    min-width: 697px;
                                    display: grid;
                                    flex-wrap: nowrap;
                                    grid-template-columns: 1fr 2fr 1fr;
                                    padding: 0px;
                                    background-color: #f3f4f4;
                                    border-radius: 4px;" class="chart-row-item"
                                :style="displayDropDown ? { margin: '10px 10px' } : dashboardRenew ? {marginLeft: '15px'} : {}">
                                <!-- DOSE -->
                                <!-- DOSE -->
                                <!-- DOSE -->
                                <div v-tooltip="'Dose'" v-if="selectedChart.chart_name != 'stat'">
                                    <p style="text-align: left; margin-left: 20px;"
                                    :style="selectedChart.chart_name == 'stat' ? { color: '#C8C8C8' } : ''">
                                        Quantity ({{ form.prescription_payload.dose_unit }})
                                    </p>
                                    <input
                                        type="number"
                                        min="0"
                                        v-model="form.prescription_payload.dose"
                                        @change="updateDoseSchedule()"
                                        :disabled="this.form.prescription_payload.frequency.period == 'variable' || !$store.state.user.role.permissions.includes('can_sign_prescription') || selectedChart.chart_name == 'stat' || disableAll"
                                    />
                                </div>
                                <!--   FREQUENCY -->
                                <!--   FREQUENCY -->
                                <!--   FREQUENCY -->
                                <div id="frequency-type"
                                    style="display: grid; background-color: transparent !important; width: 47%;"
                                    class="chart-form-select" v-tooltip="'Frequency'" v-if="selectedChart.chart_name != 'stat'">
                                    <p style="text-align: left; margin-left: 10px;"
                                        :style="!selectedChart.inputs.includes('frequency') ? { color: '#C8C8C8' } : ''">
                                        Frequency
                                    </p>
                                    <Dropdown
                                        @change="updateDoseSchedule()"
                                        :disabled="!selectedChart.inputs.includes('frequency') || !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                        id="route-type"
                                        :placeholder="displayDropDown ? 'Select Frequency' : 'Change Frequency'"
                                        :options="selectedChart.chart_name == 'stat' ? statFrequency : frequencies" optionLabel="frequency_name"
                                        v-model="form.prescription_payload.frequency"
                                        style="text-align: left; margin-left: 0px; width: 20em;" />
                                </div>

                                <!-- END FREQUENCY -->
                                <!-- END FREQUENCY -->
                                <!-- END FREQUENCY -->

                                <!-- INTERVAL -->

                                <div
                                    v-tooltip.bottom="`Interval - e.g. frequency set to 'Daily' at interval of 2 means every 2 days.`" v-if="selectedChart.chart_name != 'stat'">
                                    <p style="text-align: left; margin-left: 20px;"
                                        :style="!form.prescription_payload.frequency ? true : form.prescription_payload.days_of_week && form.prescription_payload.days_of_week.length ? true : false ? { color: '#C8C8C8' } : ''">
                                        Periodicity
                                    </p>
                                    <div style="display: flex;
                                        align-items: center;
                                        padding-left: 10px;">
                                        <p>Every</p>
                                        <InputNumber
                                            :min="0"
                                            class="dynamic-number-input"
                                            style="width: 65px"
                                            :disabled="(form.prescription_payload.days_of_week && form.prescription_payload.days_of_week.length ? true : false) || !$store.state.user.role.permissions.includes('can_sign_prescription') || selectedChart.chart_name == 'stat' || disableAll"
                                            placeholder="Interval" v-model="form.prescription_payload.interval" />
                                        <p v-if="form.prescription_payload.frequency">
                                            {{ form.prescription_payload.frequency.period == 'Daily' ||
                                            form.prescription_payload.frequency.period == 'variable' || form.prescription_payload.frequency.period == 'stat' ? 'day' :
                                            form.prescription_payload.frequency.period.slice(0, -2)
                                            }}/s
                                        </p>
                                    </div>
                                </div>
                                
                                <!-- DOSE SCHEDULE -->
                                <!-- DOSE SCHEDULE -->
                                <!-- && displayDropDown -->
                                <div style="grid-column-start: 1; grid-column-end: 4; border-radius: 4px; width: 97%; margin-left: 10px; margin-top: 10px;"
                                    v-if="selectedChart.inputs.includes('schedule') && (form.prescription_payload.frequency || (activePresChart && activePresChart.prescription_payload.frequency))">
                                    <p style="text-align: left; font-weight: 500; font-size: 1em; padding: 0; padding-left: 10px; padding-right: 10px;"
                                        v-if="form.prescription_payload.frequency && form.prescription_payload.frequency.period && form.prescription_payload.frequency.period.toLowerCase() == 'daily' && !form.prescription_payload.frequency.frequency_name.includes('Hourly')
                                        && !form.prescription_payload.frequency.frequency_name.includes('2 Hourly') && !form.prescription_payload.frequency.frequency_name.includes('3 Hourly') && selectedChart.chart_name != 'stat'">
                                        Daily Time & Dose Quantity
                                    </p>
                                    <table class="schedule-table" style=" border: none; width: fit-content"
                                        v-if="form.prescription_payload.frequency && form.prescription_payload.frequency.period && (form.prescription_payload.frequency.period.toLowerCase() == 'daily' || form.prescription_payload.frequency.period == 'Monthly')&& form.prescription_payload.frequency != 'Weekly' && !form.prescription_payload.frequency.frequency_name.includes('1 Hourly')
                                        && !form.prescription_payload.frequency.frequency_name.includes('2 Hourly') && !form.prescription_payload.frequency.frequency_name.includes('3 Hourly') && selectedChart.chart_name != 'stat'">
                                        <tr>
                                            <th v-for="(item,index) in schedule_quantity" :key="index">
                                                <input
                                                    @change="updateInstructions()"
                                                    :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                    style="padding: 0; text-align: center;"
                                                    v-model="item.time"
                                                    minlength="4"
                                                    maxlength="4"
                                                />
                                            </th>
                                        </tr>
                                        <tr>
                                            <td v-for="(item,index) in schedule_quantity" :key="index">
                                                <input
                                                    :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                    style="padding: 0; padding-left: 5px" :class="item.qty ? 'blue-input': ''"
                                                    v-model="item.qty"
                                                    @change="updateInstructions()"
                                                />
                                            </td>
                                        </tr>
                                        <!-- <tr v-if="selectedChart.chart_name == 'insulin'">
                                            <td>Sliding Scale</td>
                                            <td v-for="(item,index) in schedule_quantity" :key="index">
                                                <Checkbox
                                                    id="binary"
                                                    :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription')"
                                                    v-model="item.sliding_scale"
                                                    @change="displaySlideTable()"
                                                    :binary="true"
                                                    style="margin-right: 5px;"
                                                />
                                            </td>
                                        </tr> -->
                                    </table>

                                    <!-- WEEKLY SCHEDULE -->
                                    <div v-if="form.prescription_payload.frequency.period == 'variable'"
                                        style="display: flex; align-items: center; padding-left: 10px; margin-bottom: 10px;">
                                        <p style="font-size: 1em; margin: 0; margin-right: 10px;">
                                            {{ dateRange[0].date.slice(0, -5) }} - {{ dateRange[6].date.slice(0, -5) }}
                                        </p>
                                        <p style="cursor: pointer; margin: 0; border-top-left-radius: 4px; border-bottom-left-radius: 4px; padding: 0; padding-right: 5px; padding-left: 5px; font-size: 1em; font-weight: bold; background-color: white"
                                            v-if="currentWeekIndex > 0" @click="displayPrevWeek()">
                                            &lt;
                                        </p>
                                        <p style="cursor: pointer; margin: 0; border-bottom-right-radius: 4px;  border-top-right-radius: 4px; padding: 0; padding-left: 5px; padding-right: 5px;  font-size: 1em; font-weight: bold; background-color: white; justify-self: flex-end"
                                            @click="displayNextWeek()">
                                            >
                                        </p>
                                    </div>
                                    <!-- STAT SCHEDULE -->
                                    <div v-if="selectedChart.chart_name == 'stat'" style="display: flex;">
                                        <div v-tooltip="'Date'">
                                            <p style="text-align: left; margin-left: 5px;">
                                                Commence Admin Date
                                            </p>
                                            <Calendar
                                                id="startdateformat"
                                                style="margin-left: -20px;"
                                                @date-select="formatDate('start')"
                                                :disabled="(!selectedChart.inputs.includes('start_date') && !selectedChart.inputs.includes('datetime')) ||
                                                 !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                v-model="statDoseTime[0].times[0].date"
                                                dateFormat="dd/mm/yy"
                                                placeholder="Commence Date" />
                                            <!-- <input type="date" style="margin-left: 0;"  :disabled="this.form.prescription_payload.frequency.period == 'variable' || !$store.state.user.role.permissions.includes('can_sign_prescription')" /> -->
                                        </div>
                                        <div v-tooltip="'Time'">
                                            <p style="text-align: left; margin-left: 20px;">
                                                Admin Time
                                            </p>
                                            <input
                                                style="padding-left: 10px;"
                                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                v-model="statDoseTime[0].times[0].time"
                                                @change="updateStatInstructions()"
                                            />
                                        </div>
                                        <div v-tooltip="'Quantity'">
                                            <p style="text-align: left; margin-left: 20px;">
                                                Quantity
                                            </p>
                                            <input
                                                style="padding: 10px; width: 4.5em !important;" 
                                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                class="schedule-input"
                                                v-model="statDoseTime[0].times[0].qty"
                                                @change="updateStatInstructions()"
                                            />
                                        </div>
                                        
                                    </div>

                                    <!-- WEEKLY SCHEDULE -->
                                    <table
                                        v-if="readyToDisplaySchedule && form.prescription_payload.frequency != '' && form.prescription_payload.frequency.period && (form.prescription_payload.frequency.period.toLowerCase() != 'daily' || (activePresChart && activePresChart.prescription_payload.frequency == 'weekly')) 
                                        && selectedChart.chart_name != 'stat' && form.prescription_payload.frequency.period != 'Monthly'"
                                        class="schedule-table" style=" border: none; margin-left: -6px;">
                                        <tr>
                                            <th style="width: 50px;">
                                                <p
                                                    style="font-size: 1em; font-weight: 500; margin: 0; padding: 0; text-align: left; padding-left: 15px;">
                                                    Dose <br> Times
                                                </p>
                                            </th>
                                            <th
                                                v-for="(item, index) in weekly_schedule"
                                                :key="index" style="width: 30px;" 
                                                :style="!dateRange[index].edit && form.prescription_payload.frequency.period == 'variable' ?  {color: 'grey'} :{} "
                                            >
                                                {{item.day}} <br/>{{ form.prescription_payload.frequency.period == 'variable' ? dateRange[index].date.slice(0,5) : ''}} 
                                            </th>
                                        </tr>
                                        <tr v-for="(p, i) in schedule_quantity" :key="i">
                                            <th>
                                                <input
                                                    style="padding: 0; padding-left: 5px;"
                                                    :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                    v-model="p.time"
                                                    minlength="4"
                                                    maxlength="4"
                                                    @change="saveWeeklySchedule()"
                                                />
                                            </th>
                                            <td v-for="(item,index) in weekly_schedule" :key="index" >
                                                <input
                                                    :disabled="(form.prescription_payload.frequency.period == 'variable' && !dateRange[index].edit) || 
                                                    !$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                                    style="padding: 0; width: 4.5em !important;" 
                                                    class="schedule-input"
                                                    :id="`${currentWeekIndex}-${i}-${index}`"
                                                    @input="customInputSchedule(`${currentWeekIndex}-${i}-${index}`)"
                                                    
                                                />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <!-- DOSE SCHEDULE -->
                                <!-- DOSE SCHEDULE -->
                                
                            </div>
                            <!-- PRN SLIDING SCALE OPTION -->
                             <!-- PRN SLIDING SCALE OPTION -->
                              <!-- PRN SLIDING SCALE OPTION -->
                            <div v-if="displayPRNSliding" style="padding-left: 20px; display: flex">
                                <Checkbox v-model="sliding" :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll" name="sliding" :binary="true" @change="sliding && !form.prescription_payload.sliding_scale.length ? addSlidingTable() : ''" />
                                <label for="sliding" style="margin-left:10px; font-weight: bold" :style="$store.state.user.role.permissions.includes('can_sign_prescription') ? {}:{color: '#cdcdcd'}">Include sliding scale</label>
                            </div>
                            <!-- QP TEXT INTERACTIONS -->
                            <QPTextInteractions
                                style="margin: 10px; min-width: 697px;"
                                :patient-id="patientId"
                                :drug-uuid="selectedDrug.drug_uuid"
                                :drug-reference="draftDrug ? draftDrug.reference : ''"
                                :drug-company-id="this.draftDrug && this.draftDrug.company_id && !this.selectedDrug.company_id"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 697px -->
            <div class="approval-container"
                style="margin-left: 10px; justify-content: space-between; height: fit-content; width: 707px; min-width: 400px"
                v-if="selectedChart">
                <div>
                    <p style="text-align: left; font-size: 1em; margin: 0; margin-top: 10px; margin-left: 10px; margin-bottom: 10px;">
                        Notes/Directions 
                        <Dropdown
                            id="dose_unit-type"
                            placeholder="Select Treatment Use"
                            :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                            :options="useOptions"
                            v-model="selectedUse"
                            @change="updateInstructions()"
                        />
                    </p>
                    <Textarea
                        v-tooltip="'Notes/Directions'"
                        :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription')"
                        style="min-width: 400px; width: 100%; min-height: 190px; height: 190px; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;"
                        placeholder="Notes/Directions"
                        :autoResize="true"
                        v-model="form.prescription_payload.notes"
                    />
                    <p style="text-align: left; margin: 0;margin-top: 10px; margin-left: 10px; font-size: 0.9em; font-weight: 500; margin-bottom: 5px">
                        Prescription Options
                    </p>
                    <div v-if="selectedChart"
                        style="display: flex; align-items: center; margin-bottom: 10px; flex-wrap: wrap;">
                        <div style="display: flex; align-items: center; ">
                            <ToggleButton
                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                v-model="form.prescription_payload.is_paper"
                                onLabel="Paper prescription"
                                offLabel="Paper prescription"
                                style="margin: 0; margin-bottom: 10px"
                            />
                        </div>
                        <div v-if="form.prescription_payload.is_paper" style="display: flex; align-items: center; padding-left: 1em; margin-bottom: 10px" >
                            <input
                                placeholder="Enter Prescription #"
                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                v-model="form.prescription_payload.paper_prescription_number"
                                :style="!displayDropDown ? {backgroundColor: 'white'}:{} "
                            />
                        </div>
                        <div style="display: flex; align-items: center; padding-left: 10px; margin-right: 10px; margin-bottom: 10px">
                            <ToggleButton
                                v-model="form.prescription_payload.substitution_not_permitted"
                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                onLabel="No brand substitution"
                                offLabel="No brand substitution"
                            />
                        </div>
                        <div style="margin-bottom: 10px">
                            <InputText
                                style="height: 37px; width:12em; border: 1px solid #ced4da;"
                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                :placeholder="authorisationReferenceNumberLabel"
                                type="text"
                                v-model="form.prescription_payload.permit_number"
                            />
                        </div>
                    </div>
                    <!-- ADMINISTRATION OPTIONS -->

                    <div>
                        <p
                            style="text-align: left; margin: 0; margin-left: 10px; font-size: 0.9em; font-weight: 500; margin-bottom: 5px">
                            Administration Options
                        </p>
                        <div style="display:grid; grid-template-columns: auto auto;">
                            <SelectButton
                                class="administration-select-buttons"
                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                v-model="selected_administration_options"
                                :options="administration_options"
                                optionLabel="label"
                                multiple
                            />
                            <Dropdown 
                            :disabled="disableAll"
                            v-model="selected_packing" 
                            :options="packing_options" 
                            optionLabel="label" 
                            placeholder="Pack type"  
                            style="margin-left: 6px;width:140px ;"
                            />

                        </div>
                    </div>
                    <!-- PRIVACY POLICY AND ASLR CONSENT -->
                    <div style="margin-top: 1em; display: grid; justify-content: flex-start; text-align: left; grid-gap: 10px; grid-template-columns: 1fr;">
                        <div class="field-checkbox">
                            <Checkbox
                                inputId="binary"
                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                v-model="form.prescription_payload.privacy_statement"
                                :binary="true"
                            />
                            <label for="binary" style="padding-left: 10px;">Privacy agreement (policy <a href="https://strongroom.ai/privacy-policy/" target="_blank">here</a>.)</label>
                        </div>

                        <div class="field-checkbox">
                            <Checkbox
                                inputId="binary"
                                :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                v-model="form.prescription_payload.aslr_consent"
                                :binary="true"
                            />
                            <label for="binary" style="padding-left: 10px;">Patient has exercised their choice to keep the information away from their ASL.</label>
                        </div>
                    </div>
                    <div v-if="sliding" style="padding: 10px;
                                border: 1px solid black;
                                margin: 10px 0;
                                border-radius: 5px;">
                        <h4  style="text-align: left; font-weight: normal; margin: 0">Sliding Scale</h4>
                        <div style="grid-column-start: 1; grid-column-end: 4; margin-left: 10px; display: flex; align-items: center;"
                             class="schedule-table">
                            
                            <table style="width: fit-content">
                                <tr>
                                    <th></th>
                                    <th>Min</th>
                                    <td v-for="(item, index) in form.prescription_payload.sliding_scale" :key="index">
                                        <input
                                            :style="!displayDropDown ? {backgroundColor:'white', border:'1px solid #cdcdcd'}:{}"
                                            :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                            type="text"
                                            v-model="item.min"
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <th style="width: 50px; padding: 0 10px;">BGL Readings</th>
                                    <th>Max</th>
                                    <td v-for="(item, index) in form.prescription_payload.sliding_scale" :key="index">
                                        <input
                                            :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                            :style="!displayDropDown ? {backgroundColor:'white', border:'1px solid #cdcdcd'}:{}"
                                            type="text"
                                            v-model="item.max"
                                        >
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th style="padding: 0 10px;">Amount</th>
                                    <td v-for="(item, index) in form.prescription_payload.sliding_scale" :key="index">
                                        <input 
                                            type="text"
                                            :disabled="!$store.state.user.role.permissions.includes('can_sign_prescription') || disableAll"
                                            :style="!displayDropDown ? {backgroundColor:'white', border:'1px solid #cdcdcd'}:{}" 
                                            v-model="item.value">
                                    </td>
                                </tr>
                            </table>
                            <div style="display: flex;" v-if="$store.state.user.role.permissions.includes('can_sign_prescription') || !disableAll">
                                <p class="blue-button" v-if="form.prescription_payload.sliding_scale.length > 1"
                                style="padding: 5px 0; width: fit-content; margin: 0; margin-right: 10px;"
                                @click="removeSlidingTable()"><i class="pi pi-minus"></i></p>
                                <p class="blue-button"
                                style="padding: 5px 0; width: fit-content; margin: 0; margin-right: 10px;"
                                @click="addSlidingTable()"><i class="pi pi-plus"></i></p>
                            </div>
                        </div>
                    </div>

                    <!-- CREATE CHART PBS CONTAINER DISPLAYING AT THE END OF THE PRESCRIPTION -->
                    <div id="reason-input-container"
                        style="grid-column-start: 1; grid-column-end: 4; width: 100%; margin-top:20px;"
                        v-if="(displayDropDown || dashboardRenew) && selectedChart">
                        <div style="display: flex;">
                            <p style="text-align: left; margin: 0; margin-left: 20px;">
                                Prescription Type
                            </p>
                            <p style="text-align: left; margin: 0; margin-left: 80px;">
                                Indication
                            </p>
                        </div>
                        <div class="col-11 md:col-4">
                            <div class="p-inputgroup">
                                <Button @click="prescriptionType = 'private', pbsFilter = '', changePBSOption()" style="
                                        border-top-right-radius: 0px !important;
                                        border-bottom-right-radius: 0px !important;
                                        height: 35px;" label='Private'
                                    :class="prescriptionType == 'private' ? 'blue-button' : 'p-button-secondary p-button-outlined'" />
                                <Button @click="displayStreamlineCodeModal = true, prescriptionType = 'pbs/rpbs', pbsFilter = 'pbs', changePBSOption()"
                                    style="border-right: none;
                                        border-left: none;
                                        border-top-right-radius: 0px !important;
                                        border-bottom-right-radius: 0px !important;
                                        border-top-left-radius: 0px !important;
                                        border-bottom-left-radius: 0px !important;
                                        height: 35px;" :disabled="!indications_list.length || (disableAll && (!dashboardRenew && !indications_list.length))"
                                    :label="loading_pbs ? 'Loading...' : 'PBS/RPBS'"
                                    :class="prescriptionType == 'pbs/rpbs' ? 'blue-button' : 'p-button-secondary p-button-outlined'" />
                                <inputText v-tooltip="'Indication'"
                                    placeholder="Select from PBS/RPBS list or type indication for private medication"
                                    style="min-width: 50%; width: 553px;  border: 1px solid #c3d4c3"
                                    :disabled="!selectedChart.inputs.includes('indication') && !indications_list.length || disableAll"
                                    v-model="form.prescription_payload.indication" />
                            </div>
                        </div>
                    </div>
                    <div class="valid-prescription-notes" v-if="selectedChart && (displayDropDown || dashboardRenew)"
                        style="margin-top: 0; padding-bottom: 0; padding-left: 0px; background-color: transparent">
                        <div class="prescriber-options-container"
                            :style="selected_pbs_code.restriction_flag == 'A' || form.prescription_payload.authority_prescription_number ? {} : { justifyContent: 'flex-start' }"
                            style="align-items: self-end; margin-left: 0px; margin-top: 0;margin-right: 0; height: 53px;">
                            <div :style="selected_pbs_code.restriction_flag == 'A' ? {} : {}">
                                <p style="margin: 0; text-align: left; margin-left: 10px;"
                                    :style="selected_pbs_code.restriction_flag == 'A' ? { marginLeft: '20px' } : {}">
                                    PBS Code
                                </p>
                                <InputText disabled style="width:14em; border: 1px solid #ced4da;"
                                    :style="displayDropDown ? { backgroundColor: '#F3F4F4' } : {}"
                                    v-model="form.prescription_payload.pbs_code" />
                            </div>
                            <div
                                v-if="(pbsFilter == 'pbs' || pbsFilter == 'rpbs') && (selected_pbs_code.restriction_flag == 'A' || form.prescription_payload.authority_prescription_number)">
                                <p style="margin: 0; text-align: left; margin-left: 20px;">
                                    Authority Number
                                </p>
                                <InputText disabled style="width:14em; background-color: #F3F4F4"
                                    placeholder="Create Number"
                                    v-model="form.prescription_payload.authority_prescription_number"
                                    @click="form.prescription_payload.authority_prescription_number ? '' : updatePBSPrescription()" />
                            </div>
                            <div :style="selected_pbs_code.restriction_flag == 'A' ? {} : { marginLeft: '20px' }">
                                <div>
                                    <p style="margin: 0; text-align: left; margin-left: 10px;">
                                        Approval Code <span v-if="form.prescription_payload.authority_prescription_number" style="color: red; font-size: 1em">*</span>
                                    </p>
                                </div>
                                <span class="p-input-icon-right">
                                    <!-- :disabled="selected_pbs_code.restriction_flag != 'A'" -->
                                    <InputText 
                                        :style="displayDropDown ? { backgroundColor: '#F3F4F4' } : {}"
                                        style="width:14em; border: 1px solid #ced4da;" type="text"
                                        v-model="form.prescription_payload.pbs_authority_code" />
                                </span>
                            </div>
                        </div>
                        <p style="font-size: 1em; color: red" v-if="form.prescription_payload.authority_prescription_number && !form.prescription_payload.pbs_authority_code">Authority scripts require an approval code in order to sign.</p>
                    </div>
                </div>
                <div>
                    <div class="chart-approval" style="margin-top: 20px;" v-if="dashboardRenew">
                        <p class="blue-button" @click="cancelPrescription(), addedToReview = true"
                            v-if="(!addedToReview || !chartReview) && $store.state.user.role.permissions.includes('can_sign_prescription') && !renewingCancelledScript"
                            style="font-weight: bold; width:fit-content; margin-right: 10px; background-color: #E04E39 !important"
                            >
                            {{ activePresChart && activePresChart.prescription_payload.signed ? 'Cease Rx' : 'Cancel Draft'
                            }}</p>
                        <p class="blue-button"
                            v-if="$store.state.user.role.permissions.includes('can_create_draft_prescription') && !phone_order"
                            @click="createChart(false, false)"
                            style="width:150px; margin-right: 10px; background-color: #EF9600 !important">
                            {{ 'Save Draft' }}
                        </p>
                        <p class="green-button" v-if="!phoneOrder && !isDraft && (!addedToReview || !chartReview) && activePresChart && activePresChart.chart_payload.prescription_id && $store.state.user.role.permissions.includes('can_sign_prescription')"
                            :style="form.prescription_payload.authority_prescription_number && !form.prescription_payload.pbs_authority_code ? {backgroundColor: '#EF9600 !important'}:{}"
                            @click="form.prescription_payload.authority_prescription_number ? (!form.prescription_payload.pbs_authority_code ? updateChart(false) : updateChart(true)) : createChart(true, true) " >
                            {{ form.prescription_payload.authority_prescription_number ? (!form.prescription_payload.pbs_authority_code ? 'Save Draft':'Save Rx'):'Save Rx' }}</p>
                        <p class="green-button" v-if="phoneOrder && $store.state.user.role.permissions.includes('can_sign_prescription')"
                            :style="form.prescription_payload.authority_prescription_number && !form.prescription_payload.pbs_authority_code ? {backgroundColor: '#EF9600 !important'}:{}"
                            @click="form.prescription_payload.authority_prescription_number ? (!form.prescription_payload.pbs_authority_code ? updateChart(false) : updateChart(true)) : createChart(true, true) " >
                            Sign Phone Order
                        </p>
                        
                    </div>
                    <div class="chart-approval" style="margin-top: 20px;"
                        v-if="selectedChart && !viewOnly && !dashboardRenew"
                        :style="displayDropDown ? '' : { justifyContent: 'flex-start', marginTop: '10px' }">
                        <p class="blue-button"
                            v-if="(activePresChart && activePresChart.chart_payload && activePresChart.chart_payload.prescription_id && last_administration && activePresChart.prescription_payload.signed)"
                            @click="showAdminHistory()" style="font-weight: bold; margin-right: 10px; display: flex; align-items: center;"
                            :style="last_administration.quantity ? {}: {backgroundColor: '#cdcdcd !important', cursor: 'inherit'}">
                            Admin History
                        </p>
                        <p class="blue-button"
                            v-if="form.prescription_payload.dose_times && activePresChart" style="font-weight: bold; margin-right: 10px;"
                            @click="displayScheduledDoseTimesModal = true" >
                            Admin Schedule
                        </p>
                        <p class="blue-button" @click="cancelPrescription(), addedToReview = true"
                            v-if="/*!phoneOrder && otherwise phone orders run indefinitely*/ (!addedToReview || !chartReview) && ($store.state.user.role.permissions.includes('can_cancel_prescription')) && this.$store.state.user.type == 'prescriber' && !expired"
                            style="font-weight: bold; width:fit-content; margin-right: 10px; backgroundColor: #E04E39 !important">
                            {{ activePresChart && activePresChart.prescription_payload.signed ? 'Cease Rx' : 'Cancel Draft'
                            }}</p>
                        <p class="blue-button" @click="dismissPrescription()"
                            v-if="/*!phoneOrder && otherwise phone orders run indefinitely*/ (!addedToReview || !chartReview) && ($store.state.user.role.permissions.includes('can_cancel_prescription')) && (this.$store.state.user.type == 'prescriber' || $store.state.user?.role?.permissions?.includes('can_dismiss_expired_charts')) && expired"
                            style="font-weight: bold; width:fit-content; margin-right: 10px; background-color: #E04E39 !important">
                            Dismiss Rx</p>
                         <p class="blue-button" @click="cancelPrescription(), addedToReview = true"
                            v-if="(!addedToReview || !chartReview) && ($store.state.user.role.permissions.includes('can_cancel_prescription') && !activePresChart?.prescription_payload?.signed) && this.$store.state.user.type != 'prescriber'"
                            style="font-weight: bold; width:fit-content; margin-right: 10px; background-color: #E04E39 !important">
                            Cancel Draft</p>
                        <!-- <p class="blue-button"
                            v-if="(form.signatures && form.signatures.length) && $store.state.user.role.permissions.includes('can_sign_prescription')"
                            @click="createChart(true, false)" style="font-weight: bold; margin-right: 10px; ">Disable
                            Prescription</p> -->
                        <p class="blue-button"
                            v-if="( !phoneOrder && !addedToReview && (chartReview && form.prescription_payload.authority_prescription_number? false :  true) && (isDraft || (activePresChart && !activePresChart.prescription_payload.signed) || !activePresChart)) && (activePresChart && !activePresChart.prescription_payload.signed ? $store.state.user.role.permissions.includes('can_edit_draft_prescription') : $store.state.user.role.permissions.includes('can_create_draft_prescription'))"
                            @click="activePresChart || form.prescription_payload.authority_prescription_number ? updateChart(false) : createChart(false, false), chartReview ? addedToReview = true: ''"
                            style="width:150px; margin-right: 10px; background-color: #EF9600 !important">
                            {{ activePresChart || form.prescription_payload.authority_prescription_number ? 'Update Draft' : 'Save Draft' }}
                        </p>
                        
                        <p class="green-button"
                            v-if="!expired && (!isDraft && !displayDropDown && ((!phoneOrder || (!phoneOrder && !disableAll)) && (!addedToReview || !chartReview) && $store.state.user.type == 'prescriber' 
                            && ((activePresChart && ((!activePresChart?.prescription_payload?.authority_prescription_number && !activePresChart?.prescription_payload?.pbs_authority_code ) || 
                            (activePresChart?.prescription_payload?.pbs_authority_code && (activePresChart?.prescription_payload?.pbs_type == 'S')))) || 
                            editingPrescription)  && $store.state.user.role.permissions.includes('can_sign_prescription') && $store.state.user.type == 'prescriber'))"
                            @click="((((( activePresChart?.prescription_payload?.authority_prescription_number || form.prescription_payload.authority_prescription_number  ) && form.prescription_payload.pbs_authority_code)) && form.chart_payload.prescription_id))  ? updateChart(true) : createChart(true, true), chartReview ? addedToReview = true: '' " >
                            {{ chartReview ? 'Save Changes' : expired ? 'Renew Rx' : 'Save Rx' }}
                        </p>
                        <p class="green-button"
                            v-if="expired && activePresChart?.prescription_payload.pbs_type != 'A' && activeSelectedChart?.chart_name != 'intermittent' && $store.state.user.type == 'prescriber'"
                            @click="((((( activePresChart?.prescription_payload?.authority_prescription_number || form.prescription_payload.authority_prescription_number  ) && form.prescription_payload.pbs_authority_code)) && form.chart_payload.prescription_id))  ? updateChart(true) : createChart(true, true), chartReview ? addedToReview = true: '' " >
                            Renew Rx
                        </p>
                        <p class="green-button"
                            v-if="isDraft && chartReview && ((!phoneOrder || (!phoneOrder && !disableAll)) || expired && (!addedToReview || !chartReview) && $store.state.user.type == 'prescriber' 
                            && ((activePresChart && ((!activePresChart?.prescription_payload?.authority_prescription_number && !activePresChart?.prescription_payload?.pbs_authority_code) || 
                            (activePresChart?.prescription_payload?.pbs_authority_code && !activePresChart?.prescription_payload?.authority_prescription_number))) || 
                            editingPrescription)  && $store.state.user.role.permissions.includes('can_sign_prescription'))"
                            @click="((((( activePresChart?.prescription_payload?.authority_prescription_number || form.prescription_payload.authority_prescription_number  ) && form.prescription_payload.pbs_authority_code)) && form.chart_payload.prescription_id))  ? updateChart(true) : createChart(true, true), chartReview ? addedToReview = true: '' " >
                            {{ chartReview ? 'Save Changes' : '' }}
                        </p>
                        <!-- Signed phone orders should not allow the opportunity to sign again. -->
                        <p class="green-button"
                            v-if="phoneOrder && !disableAll && $store.state.user.type == 'prescriber'  && $store.state.user.role.permissions.includes('can_sign_prescription') && !prescriberSigned"
                            @click="signOffPhoneOrder()">
                            Sign Phone Order
                        </p>
                        
                        <p class="blue-button"
                            v-if="!isDraft && !addedToReview && chartReview && !form.prescription_payload.authority_prescription_number && activePresChart.chart_payload.prescription_id && $store.state.user.role.permissions.includes('can_sign_prescription')"
                            @click="addIDToReview(), addedToReview = true" style="font-weight: bold; margin-left: 10px;">
                            Continue Unchanged Rx
                        </p>
                        
                        <!-- <button class="blue-button"
                            v-if="!isDraft && addedToReview && chartReview && activePresChart.chart_payload.prescription_id && $store.state.user.role.permissions.includes('can_sign_prescription')"
                            @click="addedToReview = false"
                            style="font-weight: 500; margin-left: 10px;">Remove from Review
                        </p> -->
                        <p class="green-button"
                            v-if="!phoneOrder && $store.state.user.type == 'prescriber' && !chartReview && !editingPrescription 
                            && ((form.prescription_payload.pbs_authority_code) 
                            || ((isDraft && (form.prescription_payload.pbs_authority_code && form.prescription_payload.authority_prescription_number)) 
                            || ((isDraft || !activePresChart) && (!form.prescription_payload.pbs_authority_code && !form.prescription_payload.authority_prescription_number)) 
                            || (!form.chart_payload.prescription_id && (activePresChart && activePresChart.chart_payload.prescription_id && (form.prescription_payload.pbs_authority_code) ? true : false)))) 
                            && (!activePresChart || (activePresChart && !activePresChart.prescription_payload.signed)) 
                            && $store.state.user.role.permissions.includes('can_sign_prescription')"
                            @click="activePresChart || form.chart_payload.prescription_id ? updateChart(true) : initateChartCreation()"
                            style="font-weight: bold; margin: 0;">
                            Save & Approve Chart
                        </p>
                        <p class="green-button"
                            v-if="$store.state.user.type == 'nurse' && selectedChart.chart_name != 'regular' && !chartReview
                            && (!activePresChart || (activePresChart && !activePresChart.prescription_payload.signed)) 
                            && $store.state.user.role.permissions.includes('can_sign_prescription')"
                            @click="confirmPhoneOrder()"
                            style="font-weight: bold; margin: 0;">
                            Sign As Phone Order
                        </p>
                        
                    </div>
                </div>

            </div>
        </div>
        <!-- END OF CHART -->

        <SignatureModal
            v-if="displaySignatureModal"
            :payload="payload"
            @close="close"
            :endpoint="endpoint"
        />
        <StreamlineCodeModal
            v-if="displayStreamlineCodeModal"
            :streamlineOptions="indications_list"
            @close="close"
            @confirmCode="confirmCode"
        />
        <PBSAlertModal
            ref="pbsalert"
            v-if="displayPBSAlertModal && selected_pbs_code"
            :exceededQty="form.prescription_payload.prescribed_quantity > selected_pbs_code.max_quantity"
            :authCode="form.prescription_payload.authority_prescription_number"  
            :patient="patient"
            :dashboardRenew="dashboardRenew"
            :medicare="medicare && medicare.data ? medicare.data : 'NO MEDICARE'"
            :hasAuthCode="hasAuthCode"
            :pbsCode="selected_pbs_code"
            @updatePrescriptionQuantityAndRepeats="updatePrescriptionQuantityAndRepeats" 
            @passAuthCode="passAuthCode"
            @passPhoneCode="passPhoneCode"
            :streamlineOptions="selected_pbs_code"
            @close="closePBSModal"
            @revertQtyUpdate="revertQtyUpdate" 
            :instructions="form.prescription_payload.notes"
        />
        <!-- :loadingDelay="loadingDelay" -->
        <SuccessModal :loading="loading" @stopAction="stopAction" @successfullySubmitted="successfullySubmitted"
            @close="close" v-if="(displaySuccessModal || signFromMedicationChartRow) && !displayPhoneOrderModal" :action="signature"
            actionType='sign' :calculated_stop_date="calculated_stop_date" :noAuthRequired="!scheduledMedicine && !custom_s8 && !form.prescription_payload.authority_prescription_number" :payload="prescription_sign_payload"
            :statusMessage="'Prescription Action'" :first_spec_line="displayMessage" :second_spec_line="displayMessage2" @cancelDraft="cancelDraft"
            signature_endpoint="/prescription/sign" @backToEdit="backToEdit" @closeSuccess="closeSuccess" :closeOnRenew="dashboardRenew ? true: false" :adminSchedule="adminSchedule"
            :displayFirstDose="true" :doNotReload="renewExpired" />
        <SuccessModal v-if="displayErrorModal && IHICheck" :first_spec_line="errorMessage"   :statusMessage="'IHI Error'"
            @close="closeError" :closeOnRenew="dashboardRenew ? true: false" @closeSuccess="closeSuccess" />
        <!-- A modal for any other generic warnings -->
        <SuccessModal v-if="displayErrorModal" :first_spec_line="errorMessage" :statusMessage="errorTitle"
            @close="closeError" @closeSuccess="closeSuccess" />
        <SuccessModal v-if="displayPhoneOrderModal" :first_spec_line="displayMessage2" :phone_order="true" @updateSelectedPrescriber="updateSelectedPrescriber" :action="signature" actionType='sign' :statusMessage="'Prescription Action'" signature_endpoint="/prescription/phone-sign"
            @close="close" :closeOnRenew="dashboardRenew ? true: false" @closeSuccess="closeSuccess" :prescribers="patient?.prescribers ? patient?.prescribers : []" :payload="prescription_sign_payload" @backToEdit="backToEdit"
            :signPhoneOrder="signPhoneOrder" @createPhoneOrder="createPhoneOrder" @successfullySubmitted="successfullySubmitted" />
        <ScheduledDoseTimesModal v-if="displayScheduledDoseTimesModal" :uuid="activePresChart?.chart_payload?.prescription_id" :dose_times="form.prescription_payload.dose_times" :dose_unit="form.prescription_payload.dose_unit"
        @close="closeDoseTimes" />
        <CMIPIModal ref="cmiPiModal" v-if="displayCMIPIModal" :drug="draftDrug" off_market="true" :mimsImage="mimsImage"
            @close="closeCMICP" />
        <PatientAdministrationSummaryModal v-if="displayPatientAdministrationSummaryModal" @close="closeAdminHistory" :prn="activeSelectedChart?.chart_name == 'prn' ? true : false" :admin_history="administration_history" 
        :prescription_id="activePresChart && activePresChart.chart_payload && activePresChart.chart_payload.prescription_id ? activePresChart.chart_payload.prescription_id : '' " />
        <PrescribingQuestionnaire v-if="displayQuestionnaire" @bubbleQestions="setQ" @continue="close(); createChart(true, false);"  @close="close"/>
    </div>
</template>

<script>
import axios from 'axios';
import SignatureModal from './modals/SignatureModal.vue';
import SuccessModal from './modals/SuccessModal.vue';
import StreamlineCodeModal from './modals/StreamlineCodeModal.vue';
import PBSAlertModal from './modals/PBSAlertModal.vue';
import PatientAdministrationSummaryModal from './modals/PatientAdministrationSummaryModal.vue';
import QPTextInteractions from './qualitypharma/QPTextInteractions.vue';
import MimsImage from './mims/MimsImage.vue';
import CMIPIModal from './mims/CMIPIModal.vue';
import ScheduledDoseTimesModal from './modals/ScheduledDoseTimesModal.vue';
import PrescribingQuestionnaire from './modals/PrescribingQuestionnaire.vue';
import { sortMedicationList } from '../services/SortMedicationList';

export default {
    props: ['patientId', 'patient', 'activePresChart', 'activeSelectedChart', 'draftDrug', 'isDraft', 'displayDropDown', 
        'parentIndex', 'checkHeight', 'signFromMedicationChartRow', 'viewOnly', 'chartReview', 'reviewed', 'new', 'medicare', 'nextReviewDate',
        'frequencyOptions', 'administration_history', 'disableAll','expired', 'dashboardRenew', 'renewingCancelledScript', 'phoneOrder', 'prescriberSigned',
        'renewExpired'],
    components: {
        SignatureModal,
        SuccessModal,
        StreamlineCodeModal,
        PatientAdministrationSummaryModal,
        PBSAlertModal,
        MimsImage,
        CMIPIModal,
        QPTextInteractions,
        ScheduledDoseTimesModal,
        PrescribingQuestionnaire
    },
    data() {
        return {
            mimsApiKey: localStorage.getItem('mimsApiKey'),
            mimsImage: '',
            custom_s8: false,
            customMedication:{
                concat_name:'',
                name:'',
                form:'',
                strength:0,
                quantity: 0,
                measurement_unit_1:'',
                measurement_unit_2:'',
                tags:[]
            },
            medicationTimer: 0,
            createStreamlinedApn: false,
            medicationSaveStatus:'',
            IHICheck: false,
            beginWeekDate: '', // the date that the week begins, may be used for prev week calc
            endWeekDate: '', // the date that the week ends, may be used for next week calc
            currentWeekIndex: 0,
            numWeeks: 1,
            sliding: false,
            prescription_answers: null,
            rpbs: false,
            pbs: false,
            ctg: false,
            addedToReview: false,
            payload: {},
            signature: false,
            loading: false,
            loadingDelay: false,
            cancelAction: null,
            displaySuccessModal: false,
            displayStreamlineCodeModal: false,
            displayPatientAdministrationSummaryModal: false,
            displaySignatureModal: false,
            displayPBSAlertModal: false,
            displayErrorModal: false,
            displayCMIPIModal: false,
            displayPhoneOrderModal: false,
            displayMessage: '',
            displayMessage2: '',
            displayScheduledDoseTimesModal: false,
            displayQuestionnaire: false,
            searchedDrugName: '',
            errorTitle: 'Form Error',
            errorMessage: '',
            clearDrug: false,
            last_administration: [],
            weeks: {},
            drugList: [],
            selectedDrug: {},
            loadingDrugs: false,
            selectedUse: '',
            useOptions: [
                'For dental treatment only.',
                'For midwifery use only.',
                'For optometry use only.',
                'For podiatric treatment only.',
                'For treatment of foot conditions only.',
                'For ocular treatment only.'
            ],
            schedule_quantity: [
                {
                    time: '0600',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                },
                {
                    time: '0800',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                },
                {
                    time: '1200',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                },
                {
                    time: '1400',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                },
                {
                    time: '1600',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                },
                {
                    time: '1700',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                },
                {
                    time: '2000',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                },
                {
                    time: '2200',
                    qty: null,
                    date: null,
                    sliding_scale: false,
                }
            ],
            weekly_schedule: [
                {
                    day: "SU",
                    dayId: 0,
                    times: []
                },
                {
                    day: "MO",
                    dayId: 1,
                    times: []
                },
                {
                    day: "TU",
                    dayId: 2,
                    times: []
                },
                {
                    day: "WE",
                    dayId: 3,
                    times: []
                },
                {
                    day: "TH",
                    dayId: 4,
                    times: []
                },
                {
                    day: "FR",
                    dayId: 5,
                    times: []
                },
                {
                    day: "SA",
                    dayId: 6,
                    times: []
                }
            ],
            sliding_scale_template: {
                name: 'BGL',
                min: '',
                max: '',
                value: '',
                reading: null,
            },
            statDoseTime:[
                {
                    "day": null,
                    "times": [
                        {
                            "time": '0800',
                            "qty": '0', 
                            "date": '',
                            "sliding_scale": false
                        }
                    ]
                }
            ],
            endpoint: '/prescription/create',
            endpoint2: '/chart',
            prescription_sign_payload: {
                prescription_id: ''
            },
            selected_administration_options: [],
            selected_packing: {},
            form: {
                chart_payload: {
                    prescription_id: '',
                    chart_form: {
                        type: '',
                        route: '',
                        max_dose: '',
                        indication: '',
                        tags: [],
                        schedule_quantity: [],
                        
                    }
                },
                prescription_payload: {
                    paper_prescription_number: '',
                    patient_id: '',
                    prescriber: '',
                    prescriber_id: '',
                    drug_uuid: '',
                    sliding_scale: [],
                    use_pbs: false,
                    pbs_manufacturer_code: '',
                    pbs_code: '',
                    pbs_authority_code: '',
                    permit_number: '',
                    authority_prescription_number: '',
                    prescribed_quantity: 0,
                    max_times_per_day: 0,
                    unusual_dose: false,
                    dose_unit: '',
                    substitution_not_permitted: false,
                    dose: '',
                    max_dose: '',
                    max_daily_admin: '',
                    max_admin_dose: '',
                    start_date: '',
                    stop_date: '',
                    date_added: '',
                    indication: '',
                    is_paper: false,
                    frequency_shorthand: '',
                    interval: 1,
                    frequency: '',
                    dose_times: [],
                    route: '',
                    reason: '',
                    notes: '',
                    instructions: '',
                    additional_regulatory_checks: '',
                    num_repeats_authorised: 0,
                    unusual_qty: false,
                    repeat_interval: 0,
                    privacy_statement: true,
                    aslr_consent: false,
                    private_prescription: false,
                    emergency_supply: false,
                    ctg: false,
                    script_ctg_annotation: '',
                    dispense_all_repeats: false,
                    old_prescription_id: ''
                }
            },
            administration_options: [
                { label: 'Do not crush', value: 'Do not crush' },
                { label: 'Self Admin', value: 'Self Administered' },
                { label: 'Unusual Dose', value: 'Unusual Dose' },
                { label: 'Unusual Qty', value: 'Unusual Quantity' },
            ],
            packing_options: [ 
                { label: 'Non-packed', value: 'Non-packed' },
                { label: 'Packed', value: 'Packed' }
            ],
            charts: [
                {
                    name: 'Regular Medicine Order',
                    chart_name: 'regular',
                    inputs: [
                        'dose',
                        'route',
                        'start_date',
                        'stop_date',
                        'frequency',
                        'schedule',
                        // 'reason',
                        'substitution',
                        'indication',
                        'single_multi',
                        'packed',
                        'comments',
                    ],
                    subtext: '',
                    color: '#9F5CC0',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Insulin Order',
                    chart_name: 'insulin',
                    inputs: [
                        'dose',
                        'route',
                        'start_date',
                        'stop_date',
                        'frequency',
                        'schedule',
                        // 'reason',
                        'substitution',
                        'indication',
                        'single_multi',
                        'packed',
                        'comments',
                    ],
                    subtext: '',
                    color: '#F3C400',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'PRN Medicine',
                    chart_name: 'prn',
                    inputs: [
                        // 'reason',
                        'indication',
                        'frequency',
                        'max_24_dose',
                        'dose',
                        'route',
                        'max_dose',
                        'substitution',
                        'packed',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'comments',
                        'min_admin',
                        'max_admin'
                    ],
                    subtext: '',
                    color: '#00B2A2',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Short Term Medicine',
                    chart_name: 'short_term',
                    inputs: [
                        'now_or_next_pack',
                        'dose',
                        'route',
                        'packed',
                        'frequency',
                        'indication',
                        'prescription_date',
                        'start_date',
                        'stop_date',
                        'schedule',
                        // 'reason',
                        'substitution',
                        'comments',

                    ],
                    subtext: '(antibiotics)',
                    color: '#D6001C',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                {
                    name: 'Once Only Meds (Stat)',
                    chart_name: 'stat',
                    inputs: [
                        'datetime',
                        'dose',
                        'route',
                        'schedule'
                        // 'reason'
                    ],
                    subtext: '',
                    color: '#8E0018',
                    prescriber: true,
                    sign1: true,
                    sign2: false,
                },
                {
                    name: 'Intermittent Medicine',
                    chart_name: 'intermittent',
                    inputs: [
                        'frequency',
                        'start_date',
                        'schedule'
                    ],
                    subtext: '',
                    color: '#5B00AA',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
                // {
                //     name: 'Variable Dose Meds',
                //     chart_name: 'variable_dose',
                //     inputs: [
                //         'frequency',
                //         'dose',
                //         'route',
                //         'packed',
                //         'prescription_date',
                //         'start_date',
                //         'stop_date',
                //         'comments',
                //     ],
                //     subtext: '(not insulin)',
                //     color: '#00AD50',
                //     prescriber: true,
                //     sign1: false,
                //     sign2: false,
                // },
                //   {
                //       name:'Nurse Initiated Meds',
                //       inputs:[
                //           'frequency',
                //           'dose',
                //           'route',
                //           'reason',
                //           'start_date',
                //           'comments'
                //       ],
                //       subtext:'(non-prescription)',
                //       color: '#274188',
                //       prescriber:false,
                //       sign1:false,
                //       sign2: false,
                //       nurse: true,
                //   },
                // {
                //     name: 'Non Prescription Meds',
                //     chart_name: 'non_prescription',
                //     inputs: [
                //         'frequency',
                //         'dose',
                //         'route',
                //         // 'reason',
                //         'start_date',
                //         'comments',
                //         'schedule'
                //     ],
                //     subtext: '(resident initiated)',
                //     color: '#EF9600',
                //     prescriber: true,
                //     sign1: false,
                //     sign2: false,
                // },
                {
                    name: 'Nutritional Supplement',
                    chart_name: 'supplement',
                    inputs: [
                        'frequency',
                        'dose',
                        'route',
                        // 'reason',
                        'start_date',
                        'stop_date',
                        'substitution',
                        'packed',
                        'comments',
                        'schedule'
                    ],
                    subtext: '',
                    color: '#E56DB1',
                    prescriber: true,
                    sign1: false,
                    sign2: false,
                },
            ],
            administrationTypes: [
                'PO',
                'PR',
                'Topical',
                'Subcut',
                'Subling',
                'Each ear',
                'Each eye',
                'Each nostril',
                'Left ear',
                'Left eye',
                'Left nostril',
                'Right ear',
                'Right eye',
                'Right nostril',
                'Mucosal',
                'Nasoduodenal',
                'Percutaneous',
                'NG - Nasogastric',
                'NJ - Nasojejunal',
                'PEG - Percutaneous endoscopic gastrostomy',
                'PEJ - Percutaneous endoscopic jejunostemy',
                'IM',
                'IV',
                'Epidural',
                'Inhale/Inhalation',
                'Intraarticular (joint)',
                'Intrathecal (spine)',
                'Intranasal (nose)',
                'Irrigation',
                'NEB',
                'PV',
                'PICC'
            ],
            administrationInsulinTypes:[
                'Subcutaneous',
                'Intravenous'
            ],
            statFrequency:[
                {
                    frequency_name: "Stat",
                    interval: 1,
                    period: 'daily',
                    times: ['0800'],
                    days_of_week: []
                }
            ],
            frequencyTypes: [
                {
                    frequency_name: "Daily (except on Mon)",
                    interval: 1,
                    period: 'weekly',
                    times: ['0800'],
                    days_of_week: ['TU', 'WE', 'TH', 'FR', 'SA', 'SU']
                },
                {
                    frequency_name: "Daily (except on Tue)",
                    interval: 1,
                    period: 'weekly',
                    times: ['0800'],
                    days_of_week: ['MO', 'WE', 'TH', 'FR', 'SA', 'SU']
                },
                {
                    frequency_name: "Daily (except on Wed)",
                    interval: 1,
                    period: 'weekly',
                    times: ['0800'],
                    days_of_week: ['TU', 'MO', 'TH', 'FR', 'SA', 'SU']
                },
                {
                    frequency_name: "Daily (except on Thu)",
                    interval: 1,
                    period: 'weekly',
                    times: ['0800'],
                    days_of_week: ['TU', 'WE', 'MO', 'FR', 'SA', 'SU']
                },
                {
                    frequency_name: "Daily (Mon to Fri)",
                    interval: 1,
                    period: 'weekly',
                    times: ['0800'],
                    days_of_week: ['MO', 'TU', 'WE', 'TH', 'FR']
                },
                {
                    frequency_name: "4 Hourly",
                    interval: 1,
                    period: 'daily',
                    times: ['0600', '1000', '1400', '1800', '2200'],
                    days_of_week: []
                },
                {
                    frequency_name: "6 Hourly",
                    interval: 1,
                    period: 'daily',
                    times: ['0600', '1200', '1800'],
                    days_of_week: []
                },
                // { "label": "TDS", "data": "TDS" },
                // { "label": "QiD", "data": "QiD" },
                // { "label": "Periodic", "data": "Periodic" },
                //   {"label": "PRN", "data": "PRN"},
                // { "label": "Daily", "data": "daily" },
                // { "label": "Weekly", "data": "weekly" },
                // { "label": "Monthly", "data": "monthly" },
                // { "label": "Variable", "data": "variable" },
            ],
            daysOfWeek: [
                {
                    value: 'MO',
                    label: 'Monday'
                },
                {
                    value: 'TU',
                    label: 'Tuesday'
                },
                {
                    value: 'WE',
                    label: 'Wednesday'
                },
                {
                    value: 'TH',
                    label: 'Thursday'
                },
                {
                    value: 'FR',
                    label: 'Friday'
                },
                {
                    value: 'SA',
                    label: 'Saturday'
                },
                {
                    value: 'SU',
                    label: 'Sunday'
                }
            ],
            doseTypes: [],
            doseTypeInsulin:['Unit'],
            selectedChart: '',
            formTimes: {
                time1: '',
            },
            drugSearchFilter: 1,
            pbsFilter: '',
            prescriptionType: '',
            searchTypes: [
                { name: 'All', value: 0 },
                { name: 'Brands', value: 2 },
                { name: 'Generics', value: 1 },
                { name: 'Custom', value: 3 }
            ],
            benefitTypes: [
                { name: 'Private', value: '' },
                { name: 'PBS/RPBS', value: 'pbs' }
            ],
            loading_pbs: false,
            pbs_codes: [],
            indications_list: [],
            selected_pbs_code: '',
            packed: '',
            now_or_next_pack: '',
            single_multi: '',
            error_message: '',
            start_date: '',
            stop_date: '',
            date_added: '',
            dateRange: [],
            hasAuthCode: false,
            authorisationReferenceNumberLabel: 'Permit Number',
            scheduledMedicine: false,
            prescription_id: '',
            readyToDisplaySchedule: false,
            generateCode: false,
            displayPRNSliding: false,
            adminSchedule:null,
            yes_or_no:[
                {label:'Yes',
                  value: true},
                {label:'No',
                  value: false},
            ],
            calculated_stop_date: null,
            editingPrescription: false,
            cancelPrescriptionFirst: false,
            dispensing_apn: null,
            selectedPrescriber: null,
            phone_order: false,
            signPhoneOrder: false,
            parentFunction:{
                type: Function,
                required: true
            }
        }
    },
    methods: {
        updateSelectedPrescriber(value){
            if(value){
                console.log('updating prescriber id', value);
                this.selectedPrescriber = {uuid: value};
            }
        },
        confirmPhoneOrder(){
            console.log('this is the phone order being confirmed');
            if (!this.activePresChart?.prescription_payload?.prescriber) this.displayPhoneOrderModal = true;
            else {
                // go straight to sign
                this.displayPhoneOrderModal = true;
                this.phone_order = true;
                this.signature = true;
                // add the prescription id to the payload for signing
                this.prescription_sign_payload.prescription_id = this.activePresChart?.chart_payload?.prescription_id;
            }
        },
        createPhoneOrder(value){
            if(value){
                this.createChart(true,false,false, true)
            }
        },
        signOffPhoneOrder(){
            console.log('signing off phoneOrder', this.activePresChart.chart_payload.prescription_id);
            this.payload = {
                prescription_id: this.activePresChart.chart_payload.prescription_id
            }
            this.endpoint = '/prescription/sign'
            this.displaySignatureModal = true;
        },
        cancelDraft(value){
            
        },  
        closeSuccess(value){
            if(value){
                this.displaySuccessModal=false;
                this.displayPhoneOrderModal = false;

            }
        },
        backToEdit(value){
            if(value){
                console.log('editing live prescription', value);
                this.editingPrescription = true;
                this.displaySuccessModal = false;
            }
           
        },
        showAdminHistory(){
            if(this.last_administration.quantity){
                this.displayPatientAdministrationSummaryModal = true
            }
        },
        closeAdminHistory(){
            this.displayPatientAdministrationSummaryModal = false
        },
        saveCustomMedication(){
            let strengthVal = this.customMedication.strength;

            this.date_added = new Date();
            this.medicationSaveStatus = 'Saving';
            this.customMedication.measurement_unit = `${this.customMedication.measurement_unit_1}`;
            strengthVal = parseFloat(strengthVal).toFixed(5);
            this.customMedication.quantity = parseInt(this.customMedication.quantity);
            if(this.custom_s8){
                this.customMedication.tags.push('s8');
            }

            axios.post('/create-custom-drug', this.customMedication, this.$store.state.header).then(res=>{
                this.customMedication.uuid = res.data.uuid;
                this.medicationSaveStatus = 'Medication saved!';

                let units = this.customMedication.measurement_unit.split('/')
                this.doseTypes.push(this.customMedication.form, units[0], units[1], 'application')
            })
            .catch(err => {
            if(err.response) {
                this.medicationSaveStatus = 'Failed to save!';
            
                setTimeout(() => {
                this.medicationSaveStatus = '';
                }, 5000);
            }
            })
            this.selected_pbs_code = '';
            this.form.prescription_payload.pbs_code = '';
            this.form.prescription_payload.authority_prescription_number = '';
            this.form.prescription_payload.pbs_authority_code = '';
            this.form.prescription_payload.pbs_manufacturer_code = '';
            this.form.prescription_payload.permit_number='';
            
        },
        clearCustomMedForm(){
            console.log('hitting clear custom med fuction');
            this.custom_s8 = false;
            this.customMedication = {
                concat_name:'',
                name:'',
                form:'',
                strength:0,
                quantity: 0,
                measurement_unit_1:'',
                measurement_unit_2:'',
                tags:[]
            }
            this.medicationSaveStatus = '';
            this.selectedDrug = {};
            if(!this.dashboardRenew){
                this.selected_pbs_code = '';
                this.form.prescription_payload.pbs_code = '';
                this.form.prescription_payload.authority_prescription_number = '';
                this.form.prescription_payload.pbs_authority_code = '';
                this.form.prescription_payload.pbs_manufacturer_code = '';
                this.form.prescription_payload.permit_number='';
                this.doseTypes = [];
            }
            this.searchedDrugName = '';
            
            
            
        },
        assignFrequencyList(){
            console.log('this is the selected chart', this.selectedChart, this.selectedChart.chart_name);
            if(this.selectedChart.chart_name == 'stat'){
                this.statDoseTime = [
                    {
                        "day": null,
                        "times": [
                            {
                                "time": '0800',
                                "qty": '0', 
                                "date": '',
                                "sliding_scale": false
                            }
                        ]
                    }
                ]
                this.form.prescription_payload.frequency = this.statFrequency[0];
                this.form.prescription_payload.notes = '';
            } else{
                this.form.prescription_payload.notes = '-------Please write any additional notes below this line------- \n';
            }
        },
        updateStatInstructions(){
            this.form.prescription_payload.notes = `Administer ${this.statDoseTime[0].times[0].qty} ${this.form.prescription_payload.dose_unit}/s at ${this.statDoseTime[0].times[0].time}. \n ${this.form.prescription_payload.route}`
        },
        checkIfPRNOrInsulin(){
            this.$store.dispatch('getRefreshToken');
            if(this.selectedChart.chart_name == 'prn' || this.selectedChart.chart_name == 'insulin'){
                this.displayPRNSliding = true;
            }
        },
        updatePBSPrescription() {
            this.$store.dispatch('getRefreshToken');
            this.form.prescription_payload.prescribed_quantity = this.selected_pbs_code.max_quantity;
            // this.form.prescription_payload.authority_prescription_number = '';
            this.form.prescription_payload.pbs_authority_code = '';
            if (this.selected_pbs_code.restriction_flag == 'A') {
                this.hasAuthCode = false;
                this.displayPBSAlertModal = true;
            }
        },
        updatePrescriptionQuantityAndRepeats(value) {
            this.$store.dispatch('getRefreshToken');
            console.log('this is the update prescription quantity and repeats func', this.selected_pbs_code);
            if (value && value.quantity && this.selected_pbs_code) {
                this.selected_pbs_code.max_quantity = value?.quantity;
                this.form.prescription_payload.prescribed_quantity = value?.quantity;
                this.form.prescription_payload.num_repeats_authorised = value?.repeats?.toString();
                this.displayPBSAlertModal = false;
            }
        },
        checkPrescribedQty() {
            this.$store.dispatch('getRefreshToken');
            if ((this.form.prescription_payload.prescribed_quantity > this.selected_pbs_code.max_quantity) && !this.form.prescription_payload.authority_prescription_number && this.pbsFilter) {
                this.displayPBSAlertModal = true;
            }
        },
        revertQtyUpdate(value) {
            this.$store.dispatch('getRefreshToken');
            if (value) {
                this.form.prescription_payload.prescribed_quantity = this.selected_pbs_code.max_quantity;
            }
        },
        changePBSOption() {
            this.$store.dispatch('getRefreshToken');
            this.selected_pbs_code = '';
            console.log('changing pbs option', this.selected_pbs_code);
            if(this.form.prescription_payload.authority_prescription_number){
                this.cancelPrescriptionFirst = true;
            }
            this.form.prescription_payload.authority_prescription_number = '';
            this.form.prescription_payload.pbs_code = '';
            this.form.prescription_payload.pbs_authority_code = '';
            this.form.prescription_payload.indication = '';
            this.form.prescription_payload.pbs_type = '';
        },
        parseDoseTimes() {
            this.$store.dispatch('getRefreshToken');
            console.log('PARSING DOSE TIME');
            if (this.form.prescription_payload.frequency.period == 'Daily' || this.form.prescription_payload.frequency.period == 'Monthly') {
                this.form.prescription_payload.dose_times = [
                    {
                        day: '',
                        times: this.schedule_quantity
                    }
                ]
            } else if (this.form.prescription_payload.frequency.period == 'Weekly') {
                let daysContainingTimes = [];
                this.weekly_schedule.forEach(day => {
                    if (day.times.length) {
                        daysContainingTimes.push(day);
                    }
                })
                this.form.prescription_payload.dose_times = daysContainingTimes;
            } 
            // else if (this.form.prescription_payload.frequency.period == 'variable') {
            //     this.removeDuplicatesInSchedule();
            // }
        },
        clearDaysInputs() {
            this.$store.dispatch('getRefreshToken');
            // this.form.prescription_payload.days_of_week.forEach(item=>{
            //     if
            // })
        },
        getSunday(direction) {
            this.$store.dispatch('getRefreshToken');
            var startDay = new Date();
            startDay.setDate(startDay.getDate() - (startDay.getDay() + 7) % 7);
            var curr; // get current date
            let first;
            if (direction == 'next' && this.endWeekDate) {
                this.endWeekDate.setDate(this.endWeekDate.getDate() + 7);
                curr = this.endWeekDate;
                first = this.endWeekDate.getDate() - this.endWeekDate.getDay();
            } else if (direction == 'prev' && this.endWeekDate) {
                this.beginWeekDate.setDate(this.beginWeekDate.getDate() - (this.beginWeekDate.getDay() + 7));
                curr = this.beginWeekDate;
                first = this.beginWeekDate.getDate() - this.beginWeekDate.getDay();
            } else {
                curr = startDay;
                first = startDay.getDate() - startDay.getDay();
            }
            console.log('THIS IS THE FIRST', first);
            this.dateRange = [];
            for (var i = 0; i < 7; i++) {
                var next = new Date(curr.getTime());
                next.setDate(first + i);
                if (i == 6) {
                    this.endWeekDate = next;
                    console.log('END WEEK LOOP', this.endWeekDate);
                }
                if (i == 0) {
                    this.beginWeekDate = next;
                }
                next = next.toLocaleString("en-au", { timeZone: "Australia/Sydney" });
                next = next.slice(0, 10);
                console.log(next);
                this.dateRange.push({
                    date: next,
                    edit: true
                });
            }
            setTimeout(() => {
                this.disablePastDates();
            }, 400)

        },
        disablePastDates() {
            this.$store.dispatch('getRefreshToken');
            let currentDate = new Date().toLocaleString("en-au", { timeZone: "Australia/Sydney" });
            currentDate = currentDate.slice(0, 10);
            console.log('THIS IS THE CURRENT DATE', currentDate);
            if (this.currentWeekIndex == 0) {
                this.dateRange.forEach(item => {
                    if ((item.date.split('/')[1] < currentDate.split('/')[1]) || (item.date.split('/') < currentDate.split('/'))) {
                        item.edit = false;
                    }
                })
            }

        },
        displayNextWeek() {
            this.$store.dispatch('getRefreshToken');
            this.saveWeeklySchedule();

            this.currentWeekIndex += 1;

            setTimeout(() => {
                this.getSunday('next');
                this.clearWeeklySchedule();
                if (this.currentWeekIndex == this.numWeeks) {
                    this.numWeeks += 1;
                }
                this.populateVariableScheduleCells();
            }, 400);
        },
        displayPrevWeek() {
            this.$store.dispatch('getRefreshToken');
            this.saveWeeklySchedule();
            this.currentWeekIndex -= 1;
            setTimeout(() => {
                this.getSunday('prev');
                this.clearWeeklySchedule();
                this.populateVariableScheduleCells()
            }, 400);
        },
        dismissPrescription(){
            axios.post('/dismiss-expired-prescriptions', {prescription_ids: [this.activePresChart.chart_payload.prescription_id]}, this.$store.state.header).then(res=>{
                console.log('dismissed expired med', res.data);
                console.log('testing retrieveallcharts --- refreshlist - isSuccessful --dismissPrescription');
                // this.$emit('isSuccessful', true);
                this.$emit('dismissExpiredScript', this.activePresChart.chart_payload.prescription_id);
            })
            
        },
        flattenPBSCodes() {
            let newStructure = [];
            this.pbs_codes.forEach(code => {
                if (code.restrictions.length) {
                    // restriction.restriction_flag != '
                    code.restrictions.forEach(restriction => {
                            restriction.pbs_code = code.pbs_code;
                            restriction.pbs_manufacturer_code = code.pbs_manufacturer_code;
                            restriction.max_quantity = code.max_quantity;
                            restriction.pack_size = code.pack_size;
                            restriction.max_repeats = code.max_repeats;
                            restriction.restriction_flag = code.restriction_flag;
                            restriction.prescribers = code.prescribers;
                            // If there is not a note then update to 'no restriction description'
                            if (restriction.note) {
                                restriction.indication = restriction.note;
                            } else {
                                restriction.note = 'No restriction description';
                                restriction.indication = '';
                            }

                            console.log('splitting the restriction text', restriction);
                            if(restriction.restriction_flag == 'R' || restriction.restriction_flag == 'U'){
                                restriction.indication_id = '';
                                newStructure.push(restriction);
                            } else{
                                newStructure.push(restriction);
                            }
                            
                        
                        
                    })
                } else{
                    newStructure.push({
                        pbs_code : code.pbs_code,
                        pbs_manufacturer_code: code.pbs_manufacturer_code,
                        max_quantity : code.max_quantity,
                        pack_size: code.pack_size,
                        max_repeats: code.max_repeats,
                        restriction_flag: code.restriction_flag ?? 'None',
                        restriction_id: 'General',
                        note: 'No restrictions',
                        indication: '',
                        prescribers: code.prescribers
                    })
                }
            });
            this.indications_list = newStructure;
        },
        saveWeeklySchedule() {
            this.$store.dispatch('getRefreshToken');
            // SAVING AND UPDATING THE TIMES WITHIN EACH DOSE TIME DAY (WEEKLY SCHEDULE)
            // currentWeekIndex - schedule_quantity - weekly_schedule
            let saveArray = [];

            for (let time = 0; time < 9; time++) {
                for (let day = 0; day < 7; day++) {
                    if (document.getElementById(`${this.currentWeekIndex}-${time}-${day}`) && document.getElementById(`${this.currentWeekIndex}-${time}-${day}`).value) {
                        console.log('CELL HAS VALUE', document.getElementById(`${this.currentWeekIndex}-${time}-${day}`).value);
                        saveArray.push({
                            day: day == 0 ? 'SU' : day == 1 ? 'MO' : day == 2 ? 'TU' : day == 3 ? 'WE' : day == 4 ? 'TH' : day == 5 ? 'FR' : day == 6 ? 'SA' : '',
                            times:{
                                qty: document.getElementById(`${this.currentWeekIndex}-${time}-${day}`).value.toString(),
                                time: this.schedule_quantity[time].time,
                                cellId: `${this.currentWeekIndex}-${time}-${day}`,
                                date: this.form.prescription_payload.frequency.period.toLowerCase() == 'weekly' ? '' : this.dateRange[day].date,
                                sliding_scale: false
                            }
                        })
                    }
                }
            }
            
            if (this.form.prescription_payload.times && this.form.prescription_payload.times.length) {
                saveArray.forEach(doseTime => {
                    this.form.prescription_payload.times.forEach(savedTime => {
                        if (doseTime.cellId == savedTime.cellId) {
                            console.log('FOUND CELL ID', doseTime);
                            savedTime.qty = doseTime.times.qty.toString();
                        }
                    })
                })
                
            }
            this.form.prescription_payload.times = saveArray;
            console.log('THIS IS THE SAVE ARRAY', saveArray, this.form.prescription_payload.times);
            this.weeks[`week${this.currentWeekIndex}`] = saveArray;
            console.log('THESE ARE THE WEEKS!', this.weeks);
            let weeklyschedule = [
                {
                    day: "SU",
                    dayId: 0,
                    times: []
                },
                {
                    day: "MO",
                    dayId: 1,
                    times: []
                },
                {
                    day: "TU",
                    dayId: 2,
                    times: []
                },
                {
                    day: "WE",
                    dayId: 3,
                    times: []
                },
                {
                    day: "TH",
                    dayId: 4,
                    times: []
                },
                {
                    day: "FR",
                    dayId: 5,
                    times: []
                },
                {
                    day: "SA",
                    dayId: 6,
                    times: []
                }
            ];
            this.form.prescription_payload.times.forEach(time=>{
                weeklyschedule.forEach(dayOfWeek=>{
                    if(time.day == dayOfWeek.day){
                        dayOfWeek.times.push(time.times)
                    }
                })
            })
            this.weekly_schedule = weeklyschedule;
            console.log('THIS THE LOCAL VARIABLE WEEKLYSCHEDULE', weeklyschedule);
            setTimeout(this.updateInstructions(), 1000)
            // this.updateInstructions();
        },
        customInputSchedule(cellId) {
            this.$store.dispatch('getRefreshToken');
            // IF WEEKLY SCHEDULE IS BEING UPDATED MANUALLY WITHIN THE INPUT FIELDS
            console.log('CUSTOM UPDATE DETECTED!', cellId, document.getElementById(`${cellId}`).value);
            if (document.getElementById(`${cellId}`).value) {
                console.log('adding colouring');
                document.getElementById(`${cellId}`).classList.add('blue-input')
            } else {
                console.log('removing colouring');
                document.getElementById(`${cellId}`).classList.remove('blue-input')
            }

            let dayIndex = cellId.split('-')[2];
            let timeIndex = cellId.split('-')[1];
            if (this.weekly_schedule[dayIndex].times.length) {
                console.log('will need to find and update the schedule');
                this.weekly_schedule[dayIndex].times.forEach((time, index) => {
                    time.date = this.dateRange[dayIndex].date;
                    if (document.getElementById(`${cellId}`).value) {
                        if (time.cellId == cellId) {
                            console.log('DOES MATCH', time.cellId, cellId, time.qty);
                            time.qty = document.getElementById(`${cellId}`).value.toString();
                            console.log('THIS MATCH EXISTS', time.qty, document.getElementById(`${cellId}`).value)
                        } else {
                            console.log('DOES NOT MATCH', time.cellId, cellId);
                        }
                    } else {
                        console.log('no value in inputted, must have cleared data')
                        if (time.cellId == cellId) {
                            this.weekly_schedule[dayIndex].times.splice(index, 1);
                        }
                    }
                    this.saveWeeklySchedule();
                    
                })
            } else {
                console.log('new addition');
                this.saveWeeklySchedule();
                this.weekly_schedule[dayIndex].times.push(
                    {
                        cellId: cellId,
                        date: '',
                        qty: document.getElementById(`${cellId}`).value.toString(),
                        sliding_scale: false,
                        time: this.schedule_quantity[timeIndex].time
                    }
                )
                
            }


        },
        populateWeeklySchedule() {
            this.$store.dispatch('getRefreshToken');
            setTimeout(() => {

                this.weekly_schedule.forEach((item) => {
                    if (item.times.length) {
                        item.times.forEach(time => {
                            if (document.getElementById(`${time.cellId}`)) {
                                console.log('this is the time.qty coming from populateweeklyschedule', time.qty);
                                document.getElementById(`${time.cellId}`).value = time.qty.toString();
                                if (time.qty) {
                                    document.getElementById(`${time.cellId}`).classList.add('blue-input');
                                    // console.log('coloring the time now', time.qty, document.getElementById(`${time.cellId }`), document.getElementById(`${time.cellId }`).value, this.draftDrug ? this.draftDrug.name : '');
                                } else {
                                    document.getElementById(`${time.cellId}`).classList.remove('blue-input');
                                    console.log('not coloring the time now');
                                }
                            }
                        })
                    }
                })
            }, 300)
        },
        populateVariableScheduleCells() {
            this.$store.dispatch('getRefreshToken');
            setTimeout(() => {
                this.weeks[`week${this.currentWeekIndex}`].forEach((time) => {
                    if (document.getElementById(`${time.cellId}`)) {
                        document.getElementById(`${time.cellId}`).value = time.qty.toString();
                        if (time.qty) {
                            document.getElementById(`${time.cellId}`).classList.add('blue-input')
                        } else {
                            document.getElementById(`${time.cellId}`).classList.remove('blue-input')
                        }
                    }
                })

            }, 300)

        },
        clearWeeklySchedule() {
            this.$store.dispatch('getRefreshToken');
            let inputs = document.getElementsByClassName('schedule-input');

            for (let item of inputs) {
                console.log('trying to clear weekly schedule', item, inputs);
                // item.firstElementChild
                if (item) {
                    console.log('CLEARING VALUE HERE', item.value);
                    item.value = null;
                    item.classList.remove('blue-input')
                    item.value = null;
                }
            }
            // document.getElementsByClassName('schedule-input').forEach(item=>{
            //     console.log('CLEARING VALUE HERE', item.firstElementChild.value);
            //     item.firstElementChild.value = null;
            //     item.firstElementChild.classList.remove('blue-input')
            //     item.value = null;
            // })
        },
        updateInstructions() {
            this.$store.dispatch('getRefreshToken');
            let instructionList = [];
            const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});
            if (this.form.prescription_payload.frequency.period == 'Daily' || this.form.prescription_payload.frequency.period == 'Monthly') {
                if (this.form.prescription_payload.frequency.frequency_name.includes('Hourly') && parseInt(this.form.prescription_payload.frequency.frequency_name.split(' ')[0]) < 4) {
                    instructionList.push(`Give ${this.form.prescription_payload.dose ? this.form.prescription_payload.dose : '1'} ${this.form.prescription_payload.dose_unit}/s every ${this.form.prescription_payload.frequency.frequency_name.split(' ')[0]} hour${parseInt(this.form.prescription_payload.frequency.frequency_name.split(' ')[0]) > 1 ? 's': ''}. \n`)
                } else {
                    console.log('DAILY SCHEDULE INSTRUCTION TEMPLATE')
                    console.log(`Take`, groupBy(this.schedule_quantity, v => (v.qty)));
                    let groupedQuantities = groupBy(this.schedule_quantity, v => (v.qty));
                    console.log('THIS IS THE GROUPED QUANTITIES', groupedQuantities['1'], typeof groupedQuantities);
                    // Adding time, unless PRN
                    if(this.selectedChart.chart_name != "prn") {
                    for (const key in groupedQuantities) {
                        let direction = `Give ${groupedQuantities[key][0].qty} ${this.form.prescription_payload.dose_unit} at `;
                        groupedQuantities[key].forEach((item, index) => {
                            let insertTime;
                            if (index == 0) {
                                insertTime = item.time
                            } else {
                                insertTime = ', ' + item.time
                            }
                            direction = direction.concat(insertTime);
                        })
                        instructionList.push(direction + '. ');
                    }
                } else {
                    for (const key in groupedQuantities) {
                    const direction = `Give ${groupedQuantities[key][0].qty} ${this.form.prescription_payload.dose_unit} ${this.form.prescription_payload.frequency.frequency_name} PRN`;
                    instructionList.push(direction + '. ');
                    }


                }
                }

                // this.form.prescription_payload.notes = instructionList.join('').split('. ').join('. \n') 
                this.form.prescription_payload.notes = instructionList.join('').split('. ').join('. \n').concat(this.form.prescription_payload.route + ' \n').concat(this.selectedUse + ' \n').concat('-------Please write any additional notes below this line------- \n').concat(this.form.prescription_payload.notes.split('-------Please write any additional notes below this line------- \n')[1] ? this.form.prescription_payload.notes.split('-------Please write any additional notes below this line------- \n')[1] : '')
            } else if (this.form.prescription_payload.frequency.period == 'Weekly') {
                let instructionList = [];
                console.log('WEEKLY SCHEDULE INSTRUCTION TEMPLATE')
                this.weekly_schedule.forEach(day => {
                    if (day.times.length) {
                        let direction;
                        let groupedQuantities = groupBy(day.times, v => (v.qty));
                        console.log('these are the grouped quantities', groupedQuantities, Object.keys(groupedQuantities));
                        if (Object.keys(groupedQuantities).length == 1 && Object.keys(groupedQuantities)[0] != '') {
                            direction = `Give ${Object.keys(groupedQuantities)[0]} ${this.form.prescription_payload.dose_unit}/s at `;
                            for (const key in groupedQuantities) {
                                let count = 0;
                                groupedQuantities[key].forEach((item, index) => {
                                    let insertTime;
                                    count += 1
                                    // console.log('CHECKING IF INDEX AND LENGTH MATCH', index +1, groupedQuantities[key].length, groupedQuantities[key], day.day)
                                    console.log('CHECKING IF SCHEDULE QUANT', this.schedule_quantity)
                                    if (count != groupedQuantities[key].length) {
                                        insertTime = item.time + ', '
                                    } else if (((index + 1) == groupedQuantities[key].length)) {
                                        insertTime = `${item.time} on ${day.day}`;
                                    }
                                    direction = direction.concat(insertTime);
                                })
                            }
                            instructionList.push(direction + '. ');
                        } else {
                            console.log('HITTING THE ELSE CONDITION IN GROUP QUANTS');
                            let direction = `Give `
                            for (const key in groupedQuantities) {
                                direction = direction.concat(` ${key} ${this.form.prescription_payload.dose_unit} at `);
                                // direction = `Take ${key} ${this.form.prescription_payload.dose_unit} at `;
                                console.log('THESE ARE THE NEW QUANTITIES', groupedQuantities[key]);
                                let count = 0;
                                if (groupedQuantities[key] != '') {
                                    groupedQuantities[key].forEach((item, index) => {
                                        count += 1;
                                        console.log(item.time)
                                        if (index == 0 || ((index + 1) != Object.keys(groupedQuantities).length)) {
                                            direction = direction.concat(`${item.time}${groupedQuantities[key].length ? ', ' : ' '}`)
                                        } else if (((index + 1) == Object.keys(groupedQuantities).length)) {
                                            direction = direction.concat(`${item.time}, `);
                                        }
                                        if (groupedQuantities[key].length == count) {
                                            console.log('GROUP QUANTS LENGTH NOT SAME AS COUNT')
                                            direction = direction.slice(0, -2)
                                            direction = direction.concat(` AND `)
                                        } else {
                                            console.log('GROUP QUANTS LENGTH SAME AS COUNT')
                                        }
                                    })
                                }
                            }
                            if (direction) {
                                console.log('DIRECTION IS NOT UNDEFINED', direction);
                                instructionList.push(direction.slice(0, -5) + ` on ${day.day}. \n`);
                            }

                        }
                        this.form.prescription_payload.notes = instructionList.join('').split('. ').join('. \n').concat(this.form.prescription_payload.route + ' \n').concat(this.selectedUse + ' \n').concat('-------Please write any additional notes below this line------- \n').concat(this.form.prescription_payload.notes.split('-------Please write any additional notes below this line------- \n')[1] ? this.form.prescription_payload.notes.split('-------Please write any additional notes below this line------- \n')[1] : '')

                        console.log('this is the weekly direction', direction);

                        // for(const key in groupedQuantities){

                        // }
                    }
                })

            } else if (this.form.prescription_payload.frequency.period == 'variable') {
                console.log('VARIABLE DOSE SCHEDULE DETECTED', this.weekly_schedule);
                this.form.prescription_payload.notes = '';
                // instructionList = [];
                // this.form.prescription_payload.notes = instructionList;
            }

        },
        updateDoseSchedule() {
            this.$store.dispatch('getRefreshToken');
            console.log('BEING CALLED');

            this.weekly_schedule = [
                {
                    day: "SU",
                    dayId: 0,
                    times: []
                },
                {
                    day: "MO",
                    dayId: 1,
                    times: []
                },
                {
                    day: "TU",
                    dayId: 2,
                    times: []
                },
                {
                    day: "WE",
                    dayId: 3,
                    times: []
                },
                {
                    day: "TH",
                    dayId: 4,
                    times: []
                },
                {
                    day: "FR",
                    dayId: 5,
                    times: []
                },
                {
                    day: "SA",
                    dayId: 6,
                    times: []
                }
            ];
            this.clearWeeklySchedule();
            console.log(this.form.prescription_payload.frequency);
            this.form.prescription_payload.interval = parseInt(this.form.prescription_payload.frequency.interval);
            this.weekly_schedule.forEach(item => {
                if ((this.form.prescription_payload.frequency.days_of_week && this.form.prescription_payload.frequency.days_of_week.length)) {
                    this.form.prescription_payload.frequency.days_of_week.forEach(val => {
                        if (item.day.startsWith(val)) {
                            this.form.prescription_payload.frequency.times.forEach((titem, index) => {
                                let time = {
                                    time: titem,
                                    date: '',
                                    sliding_scale: false,
                                    qty: this.form.prescription_payload.frequency.period == 'variable' ? '' : this.form.prescription_payload.dose.toString() ? this.form.prescription_payload.dose.toString() : '1.00',
                                    cellId: `0-${index}-${item.dayId}`
                                }
                                console.log('THIS IS THE POTENTIAL CELL ID', `0-${index}-${item.dayId}`, time)
                                item.times.push(time);


                                // console.log('THIS IS THE POTENTIAL CELL ID SCHEDULE QUANTITY', this.schedule_quantity);
                            })
                            let newSchedule = []
                            if (this.form.prescription_payload.frequency && this.form.prescription_payload.frequency.times.length) {
                                this.form.prescription_payload.frequency.times.forEach((titem, index) => {
                                    newSchedule.push({
                                        time: titem,
                                        date: '',
                                        sliding_scale: false,
                                        qty: index.toString(),
                                        cellId: ''
                                    })
                                    this.schedule_quantity = newSchedule;
                                    console.log('THIS IS THE SCHEDULE_QUANT', this.schedule_quantity);
                                })
                            }

                        }
                    })
                } else {
                    this.schedule_quantity = [];
                    console.log('HITTING ELSE ON THE DOSE SCHEDULE UPDATE', this.schedule_quantity);
                    if (this.form.prescription_payload.frequency && this.form.prescription_payload.frequency.times.length) {
                        this.form.prescription_payload.frequency.times.forEach(titem => {
                            let time = {
                                time: titem,
                                date: '',
                                sliding_scale: false,
                                qty: this.form.prescription_payload.dose ? this.form.prescription_payload.dose.toString() : '1.00',
                            }
                            item.times.push(time);
                            this.schedule_quantity.push(time);
                            console.log('ADDING ON THE DOSE SCHEDULE UPDATE', this.schedule_quantity);
                        })
                    }
                }
            })
            setTimeout(() => {
                console.log('CALLING THE POPULATE FUNCTION');
                console.log('THIS IS THE SCHEDULE_QUANT IN TIMEOUT', this.schedule_quantity);
                this.populateWeeklySchedule();
                this.updateInstructions();
                this.readyToDisplaySchedule = true;
            }, 400)
        },
        addSlidingTable() {
            this.$store.dispatch('getRefreshToken');
            console.log('adding sliding column')
            let slideTable = {
                name: 'BGL',
                min: '',
                max: '',
                value: '',
                reading: null
            }
            this.form.prescription_payload.sliding_scale.push(slideTable);
        },
        removeSlidingTable() {
            this.form.prescription_payload.sliding_scale.pop();
        },
        displaySlideTable() {
            console.log('DISPLAY SLIDING TABLE FORM', this.form.prescription_payload);
            this.sliding = false;
            this.schedule_quantity.forEach(item => {
                if (item.sliding_scale) {
                    this.sliding = true;
                    if(this.form.prescription_payload.sliding_scale.length == 1 && !this.form.prescription_payload.sliding_scale[0].min){
                        this.form.prescription_payload.sliding_scale = [];
                        this.addSlidingTable();
                    } else{
                        this.addSlidingTable();
                    }
                }
            })
        },
        validateSlidingTables() {
            this.$store.dispatch('getRefreshToken');
            this.form.prescription_payload.sliding_scale.forEach((scale, index) => {
                if (!scale.value) {
                    this.form.prescription_payload.sliding_scale.splice(index, 1);
                }
            })
        },
        show() {
            this.$store.dispatch('getRefreshToken');
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList.length) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        addIDToReview() {
            this.$store.dispatch('getRefreshToken');
            let id = this.parentIndex + '';

            this.$emit('addToReview', {
                prescription_id: this.activePresChart.chart_payload.prescription_id,
                status: this.activePresChart.chart_payload && this.activePresChart.chart_payload.chart_form && (this.activePresChart.chart_payload.chart_form.type != 'stat' && this.activePresChart.chart_payload.chart_form.type != 'short_term')
                    ? 'continue'
                    : 'reviewed'
                });
            this.$emit('displayItem', id);
        },
        removeIDFromReview() {
            this.$emit('removeFromReview', this.form.chart_payload.prescription_id);
            //  this.$emit('displayItem', id);
        },
        formatDate(start_or_end) {
            console.log('its the', start_or_end, this.stop_date?.toString().includes('/'), this.start_date, this.form.prescription_payload);
            if (start_or_end == 'end') {
                if(!this.stop_date?.toString().includes('/')){
                    console.log('hitting thie sstop date condition that does not include slash', this.stop_date)
                    this.form.prescription_payload.stop_date = new Date(this.stop_date).toLocaleDateString('en-AU');
                } 
            } else {
                
                if(!this.form.prescription_payload.start_date || !this.start_date.toString().includes('/')){
                    this.form.prescription_payload.start_date = new Date(this.start_date).toLocaleDateString('en-AU');
                }
                
                if(this.stop_date && !this.stop_date.toString().includes('/')){
                    console.log('hitting thie sstop date condition that does not include slash', this.stop_date)
                    this.form.prescription_payload.stop_date = new Date(this.stop_date).toLocaleDateString('en-AU');
                } 

            }
            console.log('this is the end of the formatdate function', this.start_date);
        },
        stopAction(value) {
            if (value == true) {
                this.cancelAction = true;
                this.close(value);
            }
            if (value == false) {
                this.cancelAction = false;
            }
        },
        readHeightMeasurer() {
            this.$store.dispatch('getRefreshToken');
            console.log('this is the height', this.$refs['height-measurer' + this.parentIndex].clientHeight);
            setTimeout(() => {
                this.$emit('updateHeightCheck', { checkHeight: false, index: this.parentIndex, height: this.$refs['height-measurer' + this.parentIndex].clientHeight });

            }, 100)
        },
        closeError(value) {
            if (value) {
                this.displayErrorModal = false;
            }
        },
        closePhoneOrder(value) {
            if (value) {
                this.displayPhoneOrderModal = false;
                this.displaySuccessModal = false;
            }
        },
        closeDoseTimes(value) {
            if (value) {
                this.displayScheduledDoseTimesModal = false;
            }
        },
        close(value) {
            this.displayQuestionnaire = false
            this.$store.dispatch('getRefreshToken');
            if (value) {
                this.displaySuccessModal = false;
                this.displaySignatureModal = false;
                this.displayCMIPIModal = false;
                // this.displayMessage = '';
                // this.displayMessage2 = '';
                console.log('closing prescription chart success modal');
                if (!this.chartReview && !this.displayStreamlineCodeModal && !this.renewExpired) {
                    console.log('testing retrieveallcharts --- refreshlist - isSuccessful -- close if');
                    this.$emit('isSuccessful', true);
                } else {
                    this.displayStreamlineCodeModal = false;
                }
                if (this.displayMessage.includes('success') && !this.chartReview) {
                    window.location.reload();
                }
                if(this.closeOnRenew){
                    console.log('testing retrieveallcharts --- refreshlist - isSuccessful -- closeOnRenew');
                    this.$emit('isSuccessful', true);
                }
            }
        },
        closePBSModal(value){
            if(value){
                console.log('hitting the close pbs modal', value);
                this.displayPBSAlertModal = false;
            }
        },
        closeCMICP() {
            this.$store.dispatch('getRefreshToken');
            this.displayCMIPIModal = false;
        },
        mimsImageChanged(value) {
            this.$store.dispatch('getRefreshToken');
            this.mimsImage = value;
        },
        confirmCode(value) {
            this.$store.dispatch('getRefreshToken');
            console.log('THIS IS THE value confirmcode', value);
            if (value) {
                this.pbsFilter = 'pbs';

                // Set the prescription_payload.indication to the text before \n in value.indication
                this.form.prescription_payload.indication = value.indication.split(/\r?\\n/g, 1)[0].trim();

                this.form.prescription_payload.pbs_code = value.pbs_code;
                this.form.prescription_payload.pbs_manufacturer_code = value.pbs_manufacturer_code;
                this.selected_pbs_code = value;
                this.form.prescription_payload.prescribed_quantity = JSON.stringify(value.max_quantity);
                this.displayStreamlineCodeModal = false;
                this.pbs_type = value.restriction_flag;
                if (value.restriction_flag != 'A') {
                    if(value.restriction_flag == 'S'){
                         this.createStreamlinedApn = true;
                    }

                    this.form.prescription_payload.pbs_authority_code = value.indication_id;
                } else {
                    this.displayPBSAlertModal = true;
                }
            }
        },
        translatePbsType(pbsType){
            if(pbsType == "A"){
                return "Authority Required";
            }
            if(pbsType == "S"){
                return "Streamlined";
            }
            return "PBS";

        },
        passAuthCode(value){

            this.$store.dispatch('getRefreshToken');
            console.log('passauthcode value', value);
            this.generateCode = true;
            if((this.cancelPrescriptionFirst && this.activePresChart?.chart_payload?.prescription_id) || !this.form.chart_payload.prescription_id || (this.form.chart_payload.prescription_id && !this.form.prescription_payload.authority_prescription_number) || this.dashboardRenew || !this.activePresChart){
                this.hasAuthCode = true;
                console.log('hitting if condition on passauthcode');
                if((this.cancelPrescriptionFirst && this.activePresChart?.chart_payload?.prescription_id) || (this.form.chart_payload.prescription_id && this.form.prescription_payload.authority_prescription_number)){
                    axios.post(`/prescription/cancel`, {prescription_id: this.form.chart_payload.prescription_id ? this.form.chart_payload.prescription_id : this.activePresChart.chart_payload.prescription_id}, this.$store.state.header).then(res=>{
                        console.log('cancelled prescription', res.data);
                    })
                }
                if(this.dashboardRenew){
                    console.log('hitting passauthcode, dashboard renew if conditional', value);
                    this.createChart(false,true, true);
                } else{
                    this.createChart(false,false);
                }
            } else{
                console.log('hitting else condition on passauthcode');
                axios.post('/apn/create', {prescription_id: this.activePresChart.chart_payload.prescription_id, prescriber: JSON.parse(localStorage.getItem('user_id'))}, this.$store.state.header)
                .then(response=>{
                    // Do error handling here 
                    console.log(response)
                    this.form.prescription_payload.authority_prescription_number = response.data.authority_prescription_number;
                    this.hasAuthCode = true;
                })
                .catch(error => {
                    this.displayPBSAlertModal = false
                    this.loading = false
                    this.error_message = error.response.data.message
                    this.displayMessage2 = error.response.data.errors
                    this.displayMessage = 'Error: ' + this.error_message
                    console.log('HITTING CREATE ERROR', this.error_message)
                })
                
                
            }
        },
        getFirstAdminRoundTime(){
            if(this.form?.chart_payload?.prescription_id || this.prescription_id){
                axios.post(`/prescription/${this.form?.chart_payload?.prescription_id ? this.form?.chart_payload.prescription_id : this.prescription_id}/admin-schedule`, {}, this.$store.state.header).then(res=>{
                        this.adminSchedule = res.data;
                })
            }
        },
        passPhoneCode(value) {
            this.$store.dispatch('getRefreshToken');
            if (value) {
                this.form.prescription_payload.pbs_authority_code = value;
                this.prescription_date = new Date();
                console.log('hitting the close pbs modal pass phone code func', value);
                this.displayPBSAlertModal = false;
            }
        },
        successfullySubmitted(value) {
                this.$store.dispatch('getRefreshToken');
                if (value) {
                    console.log('testing retrieveallcharts --- refreshlist - isSuccessful -- successfullySubmitted');
                    this.$emit('isSuccessful', true);
                    this.displaySuccessModal = false;
                    this.displaySignatureModal = false;
                    this.editingPrescription = false;

                    if(!this.chartReview && !this.displayMessage.includes("Error")){
                    this.displayPhoneOrderModal = false;
                    if(!this.chartReview){
                        this.clearForm();
                        this.clearCustomMedForm();
                    }
                    this.displayMessage = '';
                    this.displayMessage2 = '';
                    if (this.chartReview) {
                        this.$emit('addToReview', { prescription_id: this.activePresChart.chart_payload.prescription_id, status: 'continue' });
                    }
                }
            }
        },
        cancelPrescription() {
            this.$store.dispatch('getRefreshToken');
            console.log('CANCEL PRESCRIPTION FUNCTION RUNNING');
            let timer = 1;
            this.displaySuccessModal = true;
            
            let interval = setInterval(() => {
                timer--;
                this.displayMessage = `${timer}`;
                this.loadingDelay = true;
                if (timer == 0 || this.cancelAction == true) {

                    clearInterval(interval);
                    this.loadingDelay = false;
                    this.displayMessage = 'Cancelling prescription...';
                    if (this.cancelAction == true) {
                        console.log('CANCELLING THE ACTION', this.cancelAction)
                    }
                    if (this.cancelAction == null) {
                        console.log('Cancel action should be null', this.cancelAction)
                        if (this.activePresChart && this.activePresChart.chart_payload.prescription_id) {
                            console.log('should be submitting cancel prescription request', this.activePresChart);
                            if (this.chartReview) {
                                this.$emit('addToReview', { prescription_id: this.activePresChart.chart_payload.prescription_id, status: 'cancelled' });
                            }
                            axios.post(`/prescription/cancel`, { prescription_id: this.activePresChart.chart_payload.prescription_id, no_erx: !this.activePresChart.prescription_payload.signed }, this.$store.state.header).then(res => {
                                console.log('cancelled the prescription', res.data);
                                this.displayMessage = 'Successfully cancelled prescription.';
                                this.$store.dispatch('getRefreshToken');
                                if(!this.chartReview){
                                    console.log('testing retrieveallcharts --- refreshlist - isSuccessful -- cancelPrescription');
                                    this.$emit('isSuccessful', this.activePresChart.chart_payload.prescription_id);
                                }
                                
                            }).catch(err => {
                                this.loading = false;
                                this.error_message = err.response.data;
                                
                                this.displayMessage = 'Error: ' + this.error_message;
                                console.log('HITTING CREATE ERROR', this.error_message);
                                this.signature = false;
                            })
                        } else {
                            console.log('no active chart to cancel from', this.activePresChart)
                        }
                        this.clearForm();
                    }
                    this.cancelAction = null;
                }

            }, 1000)
            timer = 1;
            
        },
        clearForm() {
            this.$store.dispatch('getRefreshToken');
            this.form = {
                chart_payload: {
                    prescription_id: '',
                    chart_form: {
                        type: '',
                        route: '',
                        indication: '',
                        tags: [],
                        schedule_quantity: []
                    }
                },
                prescription_payload:{
                    paper_prescription_number:'',
                    patient_id:'',
                    prescriber:'',
                    drug_uuid:'',
                    sliding_scale:[
                    ],
                    use_pbs:false,
                    pbs_manufacturer_code:'',
                    pbs_code:'',
                    pbs_authority_code:'',
                    pbs_type: '',
                    permit_number:'',
                    authority_prescription_number:'',
                    prescribed_quantity:0,
                    max_times_per_day: 0,
                    unusual_dose:false,
                    dose_unit:'',
                    substitution_not_permitted: false,
                    dose: '',
                    max_dose: '',
                    max_daily_admin: '',
                    max_admin_dose:'',
                    start_date: '',
                    stop_date: '',
                    date_added:'',
                    indication:'',
                    is_paper: false,
                    frequency_shorthand:'',
                    interval: 1,
                    frequency: '',
                    dose_times: [],
                    route: '',
                    reason: '',
                    notes: '-------Please write any additional notes below this line------- \n',
                    instructions:'',
                    additional_regulatory_checks: '',
                    num_repeats_authorised:0,
                    unusual_qty:false,
                    repeat_interval:0,
                    privacy_statement: true,
                    aslr_consent: false,
                    private_prescription: false,
                    emergency_supply: false,
                    ctg: false,
                    script_ctg_annotation: '',
                    dispense_all_repeats: false,
                    old_prescription_id:''
                }
            }
            this.formTimes = {
                time1: '',
            }
            this.selectedDrug = {};
            this.searchedDrugName = '';
            this.packed = '';
            this.now_or_next_pack = '';
            this.single_multi = '';
            this.error_message = '';
            this.sliding = false;
            this.signature = false;
            this.prescription_sign_payload.prescription_id = null;
            this.prescriptionType = '';


        },
        searchDrugs() {
            this.$store.dispatch('getRefreshToken');
            // Limit search to only every 200ms
            clearTimeout(this.medicationTimer);
            this.medicationTimer = setTimeout(function () {
                console.log("SEARCHING DRUGS");
                if (this.searchedDrugName) {
                    this.loadingDrugs = true;
                    axios.post('/get-drug-aliases', { drug: this.searchedDrugName, generics: parseInt(this.drugSearchFilter) }, this.$store.state.header).then(res => {
                        console.log('this is the res data', res.data);
                        this.$store.dispatch('getRefreshToken');
                        res.data.drug_aliases.forEach(item => {
                            item.full_name = `(${item.generic_name}) ${item.name}`
                        })
                        this.drugList = res.data.drug_aliases;

                        this.loadingDrugs = false;
                        // document.getElementsByClassName('p-dropdown-panel')[0].style.display = 'block';
                    }).catch(err => {
                        console.log('this is the err', err);
                        this.loadingDrugs = false;
                        if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                            console.log('yes, it does include expired')
                            this.$router.push('/login');
                        }
                    })
                }
            }.bind(this), 200);
        },
        algoliaDrugSearch(searchedDrugName) {
            var searchName = '';
            // Check if the search term is the full object or just a string
            if (typeof searchedDrugName === 'object') {
                searchName = searchedDrugName.full_name;
            } else {
                searchName = searchedDrugName;
            }

            let headers = {
                "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
            }

            // Check if there is brand/generic searching
            // If searching for all meds
            if (this.drugSearchFilter == 0) {
                axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`,
                    { params: `typoTolerance=false&query=${searchName}` },
                    { headers })
                .then(res => {
                    console.log('this is the algolia res', res.data.hits);
                    this.drugList = sortMedicationList(res.data.hits);
                    // state.commit('setDrugNames', res.data.hits);
                })
            } else {
                if(this.drugSearchFilter == 1 || this.drugSearchFilter == 2){
                    var is_generic = true;
                    // If searching for brands
                    if (this.drugSearchFilter == 2) {
                        is_generic = false;
                    }

                    axios.post(`https://${this.$store.state.VUE_APP_ALGOLIA_DNS}-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_drugs/query`,
                        { params: `typoTolerance=false&query=${searchName}&facetFilters=is_generic:${is_generic}` },
                        { headers })
                    .then(res => {
                        this.drugList = sortMedicationList(res.data.hits);
                        // state.commit('setDrugNames', res.data.hits);
                    })
                }
                
            }
        },
        drugSelect(item) {
            this.generateCode = false;
            this.$store.dispatch('getRefreshToken');
            this.loadingDrugs = true;
            this.pbsFilter = '';
            this.$refs.med.hide();
            console.log('this is the drug item selected', item);
            this.selectedDrug.drug_uuid = item.alias_id;
            this.loading_pbs = true;
            if (item.tags.includes('s8') || item.tags.includes('S8')) {
                console.log('includes s8 med, setting schedule to true', item.tags)
                this.scheduledMedicine = true;
            } else {
                console.log('not an s8 med, setting schedule to false', item.tags)
                this.scheduledMedicine = false;
            }
            this.form.prescription_payload.indication = '';
            this.form.prescription_payload.pbs_code = '';
            this.form.prescription_payload.pbs_authority_code = '';
            this.form.prescription_payload.authority_prescription_number = '';
            this.form.prescription_payload.prescribed_quantity = 0;
            this.selected_pbs_code = '';
            this.hasAuthCode = false;
            axios.post('/get-pbs', { alias_id: item.alias_id }, this.$store.state.header).then(res => {
                console.log('THESE ARE THE PBS DETAILS');
                this.loading_pbs = false;
                this.pbs_codes = res.data;
                this.flattenPBSCodes();
                // if (this.pbs_codes.length == 1) {
                //     this.form.prescription_payload.pbs_code = this.pbs_codes[0].pbs_code;
                //     this.selected_pbs_code = this.pbs_codes[0];
                //     this.updatePBSPrescription();
                // }
            })
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.form[0].toUpperCase() + item.form.slice(1);
            this.selectedDrug.strength = `${item.strength}`;
            this.selectedDrug.measurement_unit = `${item.measurement_unit}`;
            this.selectedDrug.name = item.name[0].toUpperCase() + item.name.slice(1);
            this.selectedDrug.tags = item.tags;
            this.loadingDrugs = false;
            if (this.selectedDrug.form == 'Patch') {
                this.doseTypes = ['Patch']
            } else if(this.selectedDrug.name.includes('drop')){
                this.doseTypes = ['drop']
            }else if(this.selectedDrug.name.includes('cream') || this.selectedDrug.name.includes('ointment') || this.selectedDrug.name.includes('gel')){
                this.doseTypes = ['application']
            }else {
                this.doseTypes = [
                    this.selectedDrug.form,
                    this.selectedDrug.measurement_unit.split('/')[0]
                ]

                if (this.selectedDrug.measurement_unit.split('/')[1] != 'each') {
                    this.doseTypes.push(this.selectedDrug.measurement_unit.split('/')[1]);
                }
            }
            
            this.date_added = new Date().toLocaleString("en-au", { timeZone: "Australia/Sydney" });
            // this.drugList = [];
            // this.searchedDrugName = item.name;
            // this.clearDrug = true;
        },
        async updateChart(sign) {
            if (!this.prescriptionType) {
                // note that even though nurses may choose a script type in a saved draft,
                // prescribers will still need to verify this with their on choice
                // only signed i.e. scripts will have their script type already labelled
                this.displayPhoneOrderModal = false; // because this modal tends to obscure this important error message
                this.errorTitle = 'Form Error';
                this.displayErrorModal = true;
                this.errorMessage = 'Please select a prescription type for this chart item (Private or PBS/RPBS).';
                return;
            }
            this.$store.dispatch('getRefreshToken');
            this.error_message = '';
            this.calculated_stop_date = null;
            
            this.loading = true;
            this.displaySuccessModal = true;
            let payload = {};
            console.log('this is the  drug', this.selectedDrug, this.form.prescription_payload.start_date);
            // payload.dose = parseFloat(this.form.prescription_payload.dose).toFixed(2);
            // payload.max_dose = parseFloat(this.form.prescription_payload.max_dose).toFixed(2);
            // payload.drug_uuid = this.selectedDrug.alias_id;
            // new Date(this.form.prescription_payload.start_date).toLocaleDateString('en-AU')
            payload.calculate_stop_date = this.selectedDrug?.tags?.includes('S8') || this.selectedDrug?.tags?.includes('s8') || this.draftDrug?.tags?.includes('S8') || this.draftDrug?.tags?.includes('s8') || this.form.prescription_payload.authority_prescription_number ? true : false;
            payload.start_date = this.form.prescription_payload.start_date && this.form.prescription_payload.start_date.includes('/') ? this.form.prescription_payload.start_date : '';
            payload.stop_date = this.form.prescription_payload.stop_date && this.form.prescription_payload.stop_date.includes('/') ? this.form.prescription_payload.stop_date : '';
            payload.prescription_id = this.form.chart_payload.prescription_id ? this.form.chart_payload.prescription_id : this.activePresChart.chart_payload.prescription_id;
            // payload.days_of_week = this.form.prescription_payload.days_of_week;
            payload.interval = this.form.prescription_payload.interval ? parseInt(this.form.prescription_payload.interval) : 1;
            payload.frequency = this.form.prescription_payload.frequency ? this.form.prescription_payload.frequency.period.toLowerCase() : this.activePresChart.prescription_payload.frequency ? this.activePresChart.prescription_payload.frequency.toLowerCase() : '';
            payload.frequency_shorthand = this.form.prescription_payload.frequency && this.form.prescription_payload.frequency.frequency_name ? this.form.prescription_payload.frequency.frequency_name :  this.activePresChart && this.activePresChart.prescription_payload.frequency_shorthand ? this.activePresChart.prescription_payload.frequency_shorthand : '';
            payload.dose_unit = this.form.prescription_payload.dose_unit ? this.form.prescription_payload.dose_unit : '';
            payload.indication = this.form.prescription_payload.indication ? this.form.prescription_payload.indication : '';
            payload.is_paper = this.form.prescription_payload.is_paper ? true : false;
            payload.max_daily_admin = this.form.prescription_payload.max_daily_admin ? this.form.prescription_payload.max_daily_admin : '';
            payload.old_prescription_id = this.form.prescription_payload.old_prescription_id ? this.form.prescription_payload.old_prescription_id : '';
            payload.patient_id = this.patientId;
            payload.pbs_authority_code = this.form.prescription_payload.pbs_authority_code ? this.form.prescription_payload.pbs_authority_code : '';
            payload.pbs_code = this.form.prescription_payload.pbs_code ? this.form.prescription_payload.pbs_code : '';
            payload.pbs_manufacturer_code = this.form.prescription_payload.pbs_manufacturer_code ? this.form.prescription_payload.pbs_manufacturer_code : '';
            payload.substitution_not_permitted = this.form.prescription_payload.substitution_not_permitted ? true : false;
            payload.authority_prescription_number = this.form.prescription_payload.authority_prescription_number ? this.form.prescription_payload.authority_prescription_number : '';
            payload.date_added = this.form.prescription_payload.date_added ? this.form.prescription_payload.date_added : '';
            payload.aslr_consent = this.form.prescription_payload.aslr_consent ? this.form.prescription_payload.aslr_consent : true;
            payload.additional_regulatory_checks = this.form.prescription_payload.additional_regulatory_checks ? this.form.prescription_payload.additional_regulatory_checks : '';

            payload.prescribed_quantity = this.form.prescription_payload.prescribed_quantity ? parseFloat(this.form.prescription_payload.prescribed_quantity).toFixed(2) : '0';
            payload.chart_type = this.selectedChart.chart_name ? this.selectedChart.chart_name : '';
            payload.dose = parseFloat(this.form.prescription_payload.dose).toFixed(2);
            payload.max_admin_dose = this.form.prescription_payload.max_admin_dose ? parseFloat(this.form.prescription_payload.max_admin_dose).toFixed(2) : '';
            payload.max_dose = this.form.prescription_payload.max_dose ? parseFloat(this.form.prescription_payload.max_dose).toFixed(2) : '';
            payload.max_times_per_day = this.form.prescription_payload.max_times_per_day ? parseInt(this.form.prescription_payload.max_times_per_day) : 0;
            payload.prescriber = JSON.parse(localStorage.getItem('user_id'));
            payload.drug_uuid = this.draftDrug && this.draftDrug.alias_id && !this.selectedDrug.drug_uuid ? this.draftDrug.alias_id : this.selectedDrug.drug_uuid;
            // this.form.prescription_payload.drug_uuid = this.selectedDrug && this.selectedDrug.alias_id ? this.selectedDrug.alias_id : this.draftDrug.alias_id;
            payload.reason = this.form.prescription_payload.reason ? this.form.prescription_payload.reason : '';
            payload.notes = this.form.prescription_payload.notes ? this.form.prescription_payload.notes : '';
            payload.use_pbs = this.form.prescription_payload.pbs_code ? true : false;
            payload.sliding_scale = this.form.prescription_payload.sliding_scale.length && this.form.prescription_payload.sliding_scale[0].min ? this.form.prescription_payload.sliding_scale : [];
            payload.instructions = this.form.prescription_payload.notes ? this.form.prescription_payload.notes : '';
            payload.permit_number = this.form.prescription_payload.permit_number ? this.form.prescription_payload.permit_number : '';
            payload.num_repeats_authorised = this.form.prescription_payload.num_repeats_authorised ? this.form.prescription_payload.num_repeats_authorised.toString() : '0';
            payload.period = this.form.prescription_payload.frequency.period ? this.form.prescription_payload.frequency.period.toLowerCase() : '';
            payload.route = this.form.prescription_payload.route ? this.form.prescription_payload.route : '';
            payload.paper_prescription_number = this.form.prescription_payload.paper_prescription_number ? this.form.prescription_payload.paper_prescription_number : '';
            payload.unusual_dose = this.form.prescription_payload.unusual_dose ? this.form.prescription_payload.unusual_dose : false;
            payload.unusual_qty = this.form.prescription_payload.unusual_qty ? this.form.prescription_payload.unusual_qty : false;
            payload.repeat_interval = this.form.prescription_payload.repeat_interval ? parseInt(this.form.prescription_payload.repeat_interval) : 0;
            payload.privacy_statement = this.form.prescription_payload.privacy_statement ? this.form.prescription_payload.privacy_statement : true;
            payload.private_prescription = this.form.prescription_payload.pbs_code ? false : true;
            payload.emergency_supply = this.form.prescription_payload.emergency_supply ? this.form.prescription_payload.emergency_supply : false;
            payload.ctg = this.form.prescription_payload.ctg ? this.form.prescription_payload.ctg : false;
            payload.script_ctg_annotation = this.form.prescription_payload.script_ctg_annotation ? this.form.prescription_payload.script_ctg_annotation : '';
            payload.dispense_all_repeats = this.form.prescription_payload.dispense_all_repeats ? this.form.prescription_payload.dispense_all_repeats : false;

            if(this.pbs_type != ''){
                payload.pbs_type = this.pbs_type;
                this.pbs_type = '';
            }

            payload.chart_form = {
                type: this.activePresChart && this.activePresChart.chart_payload ? this.activePresChart.chart_payload.chart_form.type : this.form.chart_payload.chart_form.type,
                route: this.form.prescription_payload.route,
                max_dose: this.form.prescription_payload.max_dose ? parseFloat(this.form.prescription_payload.max_dose).toFixed(2) : '0'
            };
            console.log('this is the payload', payload, sign)
            if ((payload.period == 'daily' || payload.period == 'monthly') && this.form.prescription_payload.frequency.frequency_name != 'Stat') {
                let updated_dose_times = [];
                // Strip the date out of the dose time
                for (let i = 0; i < this.schedule_quantity.length; i++) {
                    updated_dose_times.push({
                        time: this.schedule_quantity[i].time,
                        qty: this.schedule_quantity[i].qty.toString(),
                        date: '',
                        sliding_scale: this.schedule_quantity[i].sliding_scale
                    })
                }
                payload.dose_times = [
                    {
                        day: null,
                        times: updated_dose_times
                    }
                ]
            } else {
                if(this.form.prescription_payload.frequency.frequency_name != 'Stat'){
                    // payload.dose_times = this.weekly_schedule
                    payload.dose_times = []
                    
                    this.weekly_schedule.forEach(day=>{
                        if(day.times.length){
                            payload.dose_times.push(day)
                        }
                    })
                } else{
                    if(!this.statDoseTime[0].times[0].date.toString().includes('/')){
                        this.statDoseTime[0].times[0].date = new Date(this.statDoseTime[0].times[0].date).toLocaleDateString('en-AU');
                    }
                    payload.stop_date = '';
                    payload.dose_times = [];
                    payload.dose_times.push(
                        this.statDoseTime[0]
                    )
                }
                
            }

            
            if (sign && !this.chartReview) {
                this.signature = true;
            }

            let canProceedUpdate = true;
            if((this.selectedDrug?.tags?.includes('S8') || this.selectedDrug?.tags?.includes('s8') || this.draftDrug?.tags?.includes('S8') || this.draftDrug?.tags?.includes('s8') || this.form.prescription_payload.authority_prescription_number) && this.selectedChart.chart_name == 'regular'){
                if(payload.private_prescription != ""){
                    let stopPayload = {
                        prescribed_qty: parseFloat(this.form?.prescription_payload?.prescribed_quantity).toFixed(2),
                        repeats: parseInt(this.form?.prescription_payload?.num_repeats_authorised),
                        dose: this.form?.prescription_payload?.dose?.toString(),
                        frequency:  this.activePresChart?.prescription_payload ? this.activePresChart?.prescription_payload?.frequency : this.form?.prescription_payload?.frequency.period,
                        interval: this.form?.prescription_payload?.interval,
                        "dose_times": this.form?.prescription_payload?.dose_times,
                    }
                    await axios.post(`/calculate-stop-date`, stopPayload, this.$store.state.header).then(res=>{
                        console.log('saved stop date', res.data);
                        if(res.data.hard_limit_exceed !== undefined && res.data.hard_limit_exceed) {
                            canProceedUpdate = false;
                            this.displaySuccessModal = false;
                            this.displayErrorModal = true;
                            this.errorTitle = `Prescription Limit`;
                            this.errorMessage = `Please note, the prescription intended has a calculated end date of (${res.data.date}). This prescription has a duration that extends beyond the legislated maximum allowable duration and end date (${res.data.hard_limit_date}). Please amend the prescription accordingly.`;
                        } else {
                            this.calculated_stop_date = res.data;
                        }
                    }).catch(err=>{
                        console.log('this is the err', err.response);
                        this.displayMessage = 'Error: '+err.response?.data;
                    })
                } else {
                    payload.calculate_stop_date = false;
                }
            } else{
                this.calculate_stop_date = null;
            }

            if(!canProceedUpdate) {
                return;
            }
            
            await axios.post('/prescription/edit', payload, this.$store.state.header).then(res => {
                console.log('this is the res', res);
                this.prescription_id = res.data.uuid;
                this.$store.dispatch('getRefreshToken');
                this.loading = false;
                this.prescription_sign_payload.prescription_id = this.prescription_id;
                let tagArray = [];
                this.getFirstAdminRoundTime();
                if (this.selected_administration_options) {
                    this.selected_administration_options.forEach(tag => {
                        tagArray.push(tag.value.toLowerCase())
                    })
                }
                if (Object.keys(this.selected_packing).length != 0) {
                        tagArray.push(this.selected_packing.value.toLowerCase())
                }
                if(tagArray.length){
                    axios.post(`/chart/${this.activePresChart.prescription_payload.chart_id}/add-tags`,{tags: tagArray},this.$store.state.header).then(res3=>{
                        console.log('added tags to chart', res3.data);
                    })
                }
                if (sign && !this.chartReview) {
                    this.displayMessage2 = `Click the ${this.scheduledMedicine ||  this.form.prescription_payload.authority_prescription_number ? 'Sign' : 'Complete'} button to sign off on prescription.`;
                } else {
                    this.displayMessage = 'Successfully updated prescription draft.';
                    if (this.chartReview) {
                        this.$emit('addToReview', { prescription_id: this.prescription_id, status: 'edited' });
                    } else {
                        console.log('testing retrieveallcharts --- refreshlist - isSuccessful -- editPrescription');
                        this.$emit('isSuccessful', true);
                    }
                }
            }).catch(err => {
                this.loading = false;
                this.error_message = err.response.data.message;
                this.displayMessage = 'Error: ' + this.error_message;
                this.displayMessage2 = err.response.data.errors;
                console.log('HITTING CREATE ERROR', this.error_message);
                this.signature = false;

                this.displaySuccessModal = false;
                this.displayErrorModal = true;
                this.errorTitle = err.response.data.title ? err.response.data.title : "Form Error";
                this.errorMessage = err.response.data.message ? err.response.data.message : err.response.data;
            })
        },
        initateChartCreation() {
            // if med == Anti-Infective then show the prompt 
            if (this.selectedDrug?.tags?.includes('Anti-infective agent')) {
                this.displayQuestionnaire = true
            } else {
                this.createChart(true, false)
            }
        },
        setQ(questionsSubmission) {
            this.prescription_answers = questionsSubmission
        },
        async createChart(sign, renew, generateapn, phoneOrder = false) {
            if (!this.prescriptionType) {
                this.displayPhoneOrderModal = false; // because this modal tends to obscure this important error message
                this.displayErrorModal = true;
                this.errorTitle = 'Form Error';
                this.errorMessage = 'Please select a prescription type for this chart item (Private or PBS/RPBS).';
                return;
            }

            if (this.selectedChart.chart_name == 'prn') {
                if ((this.form.prescription_payload.max_admin_dose <= 0 || this.form.prescription_payload.max_admin_dose == '') ||
                    (this.form.prescription_payload.max_dose <= 0 || this.form.prescription_payload.max_dose == '') ||
                    (this.form.prescription_payload.max_times_per_day <= 0 || this.form.prescription_payload.max_times_per_day == ''))
                {
                    this.displayPhoneOrderModal = false;
                    this.displayErrorModal = true;
                    this.errorTitle = 'Form Error';
                    this.errorMessage = 'PRN maximum parameters (max admin dose, 24 hrs max dose, max times perday) are required and values must be greater than 0.';
                    return;
                }
            }
            
            this.calculated_stop_date = null;
            this.$store.dispatch('getRefreshToken');
            this.parseDoseTimes();
            this.formatDate();
            let timer = 1;
            this.endpoint = '/prescription/create';
            
            
            this.displaySuccessModal = true;
            this.displayMessage2 = '';
            let interval = setInterval(async () => {
                timer--;
                this.displayMessage = `${timer}`;
                this.loadingDelay = true;
                if (timer == 0 || this.cancelAction == true) {

                    clearInterval(interval);
                    this.loadingDelay = false;
                    if (this.cancelAction == true) {
                        console.log('CANCELLING THE ACTION', this.cancelAction)
                    }
                    if (this.cancelAction == null) {
                        console.log('Cancel action should be null', this.cancelAction)
                        this.displayMessage = 'Submitting request...'
                        this.error_message = '';

                        // FUNCTION HERE
                        // FUNCTION HERE
                        console.log('this is the  date', this.form.prescription_payload.start_date);
                        this.form.prescription_payload.patient_id = this.patientId;
                        delete this.form.prescription_payload.days_of_week;
                        this.form.prescription_payload.interval = this.form.prescription_payload.interval ? parseInt(this.form.prescription_payload.interval) : 1;
                        // this.form.prescription_payload.frequency_shorthand = 'BD';
                        if (this.selected_administration_options.length) {
                            this.selected_administration_options.forEach(option => {
                                if (option.label == 'Unusual Dose') {
                                    this.form.prescription_payload.unusual_dose = true;
                                }
                                // if (option.label == 'Unusual Quantity') {
                                //     this.form.prescription_payload.unusual_dose = true;
                                // }
                                this.form.chart_payload.chart_form.tags.push(option);
                            })
                        }

                        if (this.form
                            && this.form.prescription_payload
                            && this.form.prescription_payload.frequency
                            && this.form.prescription_payload.frequency.period
                            && this.form.prescription_payload.frequency.period.toLowerCase() != 'daily' && this.form.prescription_payload.frequency.period.toLowerCase() != 'monthly') {
                            console.log('this is the payload dose times and weekly schedule', this.form.prescription_payload.dose_times, this.weekly_schedule);
                            if(!this.form.prescription_payload.dose_times.length && this.form.prescription_payload.frequency.frequency_name != 'Stat'){
                                this.weekly_schedule.forEach(day => {
                                    if (day.times.length) {
                                        day.times.forEach(time=>{
                                            if(time.qty){
                                                time.qty = time.qty.toString()
                                            }
                                        })
                                        this.form.prescription_payload.dose_times.push(day);
                                    }
                                })
                            }
                        } 

                        if(this.form
                            && this.form.prescription_payload
                            && this.form.prescription_payload.frequency && this.form.prescription_payload.frequency.frequency_name == 'Stat'){
                            this.form.prescription_payload.dose = this.statDoseTime[0].times[0].qty;
                            if(!this.statDoseTime[0].times[0].date.toString().includes('/')){
                                this.statDoseTime[0].times[0].date = new Date(this.statDoseTime[0].times[0].date).toLocaleDateString('en-AU');
                            }
                            this.form.prescription_payload.stop_date = '';
                            this.form.prescription_payload.dose_times = [];
                            this.form.prescription_payload.dose_times.push(
                                this.statDoseTime[0]
                            )
                        }
                        this.form.prescription_payload.calculate_stop_date = this.selectedDrug?.tags?.includes('S8') || this.selectedDrug?.tags?.includes('s8') || this.draftDrug?.tags?.includes('S8') || this.draftDrug?.tags?.includes('s8') || this.form.prescription_payload.authority_prescription_number ? true : false;
                        this.form.prescription_payload.authority_prescription_number = this.form.prescription_payload.authority_prescription_number ? this.form.prescription_payload.authority_prescription_number : '';
                        this.form.prescription_payload.old_prescription_id = renew && !this.editingPrescription? this.activePresChart.chart_payload.prescription_id : renew && this.editingPrescription ? this.form.chart_payload.prescription_id : '';
                        this.form.prescription_payload.max_daily_admin = this.form.prescription_payload.max_daily_admin ? this.form.prescription_payload.max_daily_admin : '';
                        this.form.prescription_payload.prescribed_quantity = this.form.prescription_payload.prescribed_quantity ? parseFloat(this.form.prescription_payload.prescribed_quantity).toFixed(2) : '0';
                        this.form.prescription_payload.chart_type = this.selectedChart.chart_name ? this.selectedChart.chart_name : '';
                        this.form.prescription_payload.dose = parseFloat(this.form.prescription_payload.dose).toFixed(2);
                        this.form.prescription_payload.max_admin_dose = this.form.prescription_payload.max_admin_dose !== '' ? parseFloat(this.form.prescription_payload.max_admin_dose).toFixed(2) : '';
                        this.form.prescription_payload.max_dose = this.form.prescription_payload.max_dose ? parseFloat(this.form.prescription_payload.max_dose).toFixed(2) : '';
                        this.form.prescription_payload.max_times_per_day = this.form.prescription_payload.max_times_per_day ? parseInt(this.form.prescription_payload.max_times_per_day) : 0;
                         // ------------------------------------------------------------------------------------------------------------------------
                        // checking if user is a prescriber, if prescriber use own uuid and phone_order=false
                        // if non prescriber check if order is it's a phone_order and use the selected prescriber uuid from previous dropdown
                        // else don't include prescriber datapoint at all
                         // ------------------------------------------------------------------------------------------------------------------------
                        if(this.$store.state.user.type == 'prescriber'){
                            this.form.prescription_payload.prescriber = JSON.parse(localStorage.getItem('user_id'))
                        }else if(phoneOrder){
                            this.form.prescription_payload.prescriber =  this.selectedPrescriber?.uuid;
                        }
                        this.form.prescription_payload.phone_order = this.$store.state.user.type != 'prescriber' && phoneOrder ? true : false;
                        // ------------------------------------------------------------------------------------------------------------------------
                        // ------------------------------------------------------------------------------------------------------------------------
                        this.form.prescription_payload.aslr_consent = this.form.prescription_payload.aslr_consent ? this.form.prescription_payload.aslr_consent : false;
                        if(this.customMedication.uuid){
                            this.form.prescription_payload.drug_uuid = this.customMedication.uuid
                        } else{
                            this.form.prescription_payload.drug_uuid = this.draftDrug && this.draftDrug.alias_id && !this.selectedDrug.drug_uuid ? this.draftDrug.alias_id : this.selectedDrug.drug_uuid;
                        }
                        // check if prescription answers have to be appended
                        if(this.prescription_answers != null) {
                            this.form.prescription_payload.prescription_answers = this.prescription_answers
                        }
                        // this.form.prescription_payload.drug_uuid = this.selectedDrug && this.selectedDrug.alias_id ? this.selectedDrug.alias_id : this.draftDrug.alias_id;
                        this.form.prescription_payload.reason = this.form.prescription_payload.reason ? this.form.prescription_payload.reason : '';
                        this.form.prescription_payload.notes = this.form.prescription_payload.notes ? this.form.prescription_payload.notes : '';
                        this.form.prescription_payload.use_pbs = this.form.prescription_payload.pbs_code ? true : false;
                        this.form.prescription_payload.sliding_scale = this.form.prescription_payload.sliding_scale.length && this.form.prescription_payload.sliding_scale[0].min ? this.form.prescription_payload.sliding_scale : [];
                        this.form.prescription_payload.instructions = this.form.prescription_payload.notes ? this.form.prescription_payload.notes : '';
                        this.form.prescription_payload.permit_number = this.form.prescription_payload.permit_number ? this.form.prescription_payload.permit_number : '';
                        this.form.prescription_payload.num_repeats_authorised = this.form.prescription_payload.num_repeats_authorised ? this.form.prescription_payload.num_repeats_authorised.toString() : '0';
                        this.form.prescription_payload.period = this.form.prescription_payload.frequency.period ? this.form.prescription_payload.frequency.period.toLowerCase() : '';
                        this.form.prescription_payload.frequency_shorthand = this.form.prescription_payload.frequency ? this.form.prescription_payload.frequency.frequency_name : '';
                        this.form.prescription_payload.route = this.form.prescription_payload.route ? this.form.prescription_payload.route : '';
                        this.form.prescription_payload.paper_prescription_number = this.form.prescription_payload.paper_prescription_number ? this.form.prescription_payload.paper_prescription_number : '';
                        this.form.prescription_payload.unusual_dose = this.form.prescription_payload.unusual_dose ? this.form.prescription_payload.unusual_dose : false;
                        this.form.prescription_payload.unusual_qty = this.form.prescription_payload.unusual_qty ? this.form.prescription_payload.unusual_qty : false;
                        this.form.prescription_payload.repeat_interval = this.form.prescription_payload.repeat_interval ? this.form.prescription_payload.repeat_interval : 0;
                        this.form.prescription_payload.privacy_statement = this.form.prescription_payload.privacy_statement ? this.form.prescription_payload.privacy_statement : true;
                        this.form.prescription_payload.private_prescription = this.form.prescription_payload.pbs_code ? false : true;
                        this.form.prescription_payload.emergency_supply = this.form.prescription_payload.emergency_supply ? this.form.prescription_payload.emergency_supply : false;
                        this.form.prescription_payload.ctg = this.form.prescription_payload.ctg ? this.form.prescription_payload.ctg : false;
                        this.form.prescription_payload.script_ctg_annotation = this.form.prescription_payload.script_ctg_annotation ? this.form.prescription_payload.script_ctg_annotation : '';
                        this.form.prescription_payload.dispense_all_repeats = this.form.prescription_payload.dispense_all_repeats ? this.form.prescription_payload.dispense_all_repeats : false;
                        this.form.prescription_payload.substitution_not_permitted = this.form.prescription_payload.substitution_not_permitted ? true : false;

                        if(this.pbs_type != ''){
                            this.form.prescription_payload.pbs_type = this.pbs_type;
                            this.pbs_type = '';
                        }

                        // this.form.prescription_payload.start_date =  new Date(this.form.prescription_payload.start_date).toLocaleDateString();
                        // this.form.prescription_payload.stop_date = this.form.prescription_payload.stop_date.length == 10 ? this.form.prescription_payload.stop_date : new Date(this.form.prescription_payload.start_date).toLocaleDateString();

                        // this.form.prescription_payload.times = [];
                        // for (let key in this.formTimes) {
                        //     console.log('this is a key', key);
                        //     if (this.formTimes[key]) {
                        //         this.form.prescription_payload.times.push(this.formTimes[key].split(':').join(''))
                        //     }
                        // }
                        this.form.chart_payload.chart_form.tags = [];
                        if (this.single_multi) {
                            this.form.chart_payload.chart_form.tags.push(this.single_multi);
                        }
                        if (this.now_or_next_pack) {
                            this.form.chart_payload.chart_form.tags.push(this.now_or_next_pack);
                        }
                        if (this.packed) {
                            this.form.chart_payload.chart_form.tags.push(this.packed);
                        }
                        if(this.expired){
                            this.form.prescription_payload.start_date = new Date().toLocaleString("en-au", { timeZone: "Australia/Sydney" }).slice(0,10);
                            this.form.prescription_payload.stop_date =  this.nextReviewDate.toLocaleString("en-au", { timeZone: "Australia/Sydney" }).slice(0,10);
                        }
                        if (this.selectedChart.chart_name == 'intermittent' || this.selectedChart.chart_name == 'non_prescription') {
                            console.log('this chart is a stat chart!')
                            // this.form.prescription_payload.frequency = 'daily';
                            this.form.prescription_payload.stop_date = this.form.prescription_payload.start_date;
                        }

                        // if (this.form.prescription_payload.frequency == 'QiD' || this.form.prescription_payload.frequency == 'TDS' ||
                        //     this.form.prescription_payload.frequency == 'BD') {
                        //     this.form.prescription_payload.frequency = 'daily';
                        // }
                        this.loading = true;
                        this.displaySuccessModal = true;

                        let newPayload = { ...this.form.prescription_payload };
                        if (this.selectedChart.chart_name == 'prn') {
                            // newPayload.dose_times = [];
                            console.log('this is a prn order therefore dose times is', newPayload.dose_times)
                        }
                        delete newPayload.times;
                        newPayload.frequency = newPayload.period;
                        
                        let canProceedCreate = true;
                        if((this.selectedDrug?.tags?.includes('S8') || this.selectedDrug?.tags?.includes('s8') || this.draftDrug?.tags?.includes('S8') || this.draftDrug?.tags?.includes('s8') || this.form?.prescription_payload?.authority_prescription_number) && this.selectedChart.chart_name == 'regular'){
                            if(!newPayload.private_prescription){
                                let stopPayload = {
                                    prescribed_qty: this.form?.prescription_payload?.prescribed_quantity,
                                    repeats: parseInt(this.form?.prescription_payload?.num_repeats_authorised),
                                    dose: this.form?.prescription_payload?.dose.toString(),
                                    frequency:  this.activePresChart?.prescription_payload ? this.activePresChart?.prescription_payload?.frequency : this.form?.prescription_payload?.frequency.period,
                                    interval: this.form?.prescription_payload?.interval,
                                    "dose_times": this.form?.prescription_payload?.dose_times,
                                };
                                
                                await axios.post(`/calculate-stop-date`, stopPayload, this.$store.state.header).then(res => {
                                    console.log('saved stop date', res.data);

                                    if(res.data.hard_limit_exceed !== undefined && res.data.hard_limit_exceed) {
                                        canProceedCreate = false
                                        this.displaySuccessModal = false;
                                        this.displayErrorModal = true;
                                        this.errorTitle = `Presscription Limit`;
                                        this.errorMessage = `Please note, the prescription intended has a calculated end date of (${res.data.date}). This prescription has a duration that extends beyond the legislated maximum allowable duration and end date (${res.data.hard_limit_date}). Please amend the prescription accordingly.`;
                                    } else {
                                        this.calculated_stop_date = res.data;
                                    }
                                }).catch(err=>{
                                    console.log('this is the err', err.response);
                                    this.displayMessage = err.response;
                                })
                            } else {
                                newPayload.calculate_stop_date = false;
                            }
                        } else{
                            this.calculated_stop_date = null
                        }

                        if(!canProceedCreate) {
                            return;
                        }

                        if (renew && !this.renewingCancelledScript && !this.expired) {
                            let prescriptionToCancel = this.editingPrescription ? this.form.chart_payload.prescription_id : this.activePresChart.chart_payload.prescription_id;
                            this.form.prescription_payload.old_prescription_id = prescriptionToCancel;
                            this.$store.dispatch('getRefreshToken');
                            await axios.post('/prescription/check', newPayload, this.$store.state.header).then(re1=>{
                                console.log('checked prescription', re1);
                                axios.post(`/prescription/cancel`, { prescription_id: prescriptionToCancel, no_erx: false }, this.$store.state.header).then(ress => {
                                    console.log("cancelled prescription", prescriptionToCancel, ress.data);
                                    this.form.chart_payload.prescription_id = '';
                                    axios.post(this.endpoint, newPayload, this.$store.state.header).then(res => {
                                        console.log('this is the res', res);
                                        console.log('this is the payload variable', newPayload);
                                        this.form.chart_payload.prescription_id = res.data;
                                        this.form.chart_payload.chart_form.route = newPayload.route;
                                        this.form.chart_payload.chart_form.type = this.selectedChart.chart_name ? this.selectedChart.chart_name : this.selectedChart.name;
                                        if (this.selectedDrug.tags) {
                                            this.form.chart_payload.chart_form.tags = [];
                                            this.selectedDrug.tags.forEach(tag => {
                                                this.form.chart_payload.chart_form.tags.push(tag.toLowerCase())
                                            })
                                        }
                                        if(generateapn){
                                            axios.post('/apn/create', {prescription_id: res.data, prescriber: JSON.parse(localStorage.getItem('user_id'))}, this.$store.state.header)
                                            .then(response=>{
                                                // Do error handling here 
                                                console.log(response)
                                                this.form.prescription_payload.authority_prescription_number = response.data.authority_prescription_number;
                                                this.hasAuthCode = true;
                                                this.displaySuccessModal = false;
                                            })
                                            .catch(error => {
                                                this.displayPBSAlertModal = false
                                                this.loading = false
                                                this.error_message = error.response.data.message
                                                this.displayMessage2 = error.response.data.errors
                                                this.displayMessage = 'Error: ' + this.error_message + '; '+ error;
                                                console.log('HITTING CREATE ERROR', this.error_message)

                                                this.displaySuccessModal = false;
                                                this.displayErrorModal = true;
                                                this.errorTitle = error.response.data.title ? error.response.data.title : "Form Error";
                                                this.errorMessage = error.response.data.message ? error.response.data.message : error.response.data;
                                            })
                                        }
                                        this.$emit('addToReview', { prescription_id: this.form.prescription_payload.old_prescription_id, status: 'cancelled' });
                                        console.log('this is the chart payload', this.form.chart_payload);
                                    }).then(() => {
                                        console.log('the create chart endpoint is being processed now');
                                        axios.post('/chart', this.form.chart_payload, this.$store.state.header).then(res2 => {
                                            this.clearCustomMedForm();
                                            console.log('submitted chart create, this is res', res2);
                                            this.$store.dispatch('getRefreshToken');
                                            this.loading = false;
                                            this.displayMessage = 'Your prescription and chart have been successfully created.';
                                            let tagArray = [];
                                            this.getFirstAdminRoundTime();
                                            if (this.selected_administration_options) {
                                                this.selected_administration_options.forEach(tag => {
                                                    tagArray.push(tag.value.toLowerCase())
                                                })
                                            }
                                            if (Object.keys(this.selected_packing).length != 0) {
                                                    tagArray.push(this.selected_packing.value.toLowerCase())
                                            }
                                            if(tagArray.length){
                                                axios.post(`/chart/${res2.data.uuid}/add-tags`,{tags: tagArray},this.$store.state.header).then(res3=>{
                                                    console.log('added tags to chart', res3.data);
                                                })
                                            }
                                            
                                            // UNCOMMENT WHEN REINTRODUCING IHI
                                            // if (sign) {
                                            //     this.preChartCheck();
                                            // }
                                            if (sign && !this.chartReview) {
                                                
                                                this.prescription_sign_payload.prescription_id = this.form.chart_payload.prescription_id;
                                                if(!this.displayPhoneOrderModal){
                                                    this.displayMessage2 = `Click the ${this.scheduledMedicine ? 'Sign' : 'Complete'} button to sign off on prescription.`;
                                                }
                                                
                                                this.signature = true;
                                            }
                                            if (this.chartReview) {
                                                this.displayMessage2 = 'Prescription has been added to the list of prescriptions that will be signed off at the end of the chart review.';
                                                this.$emit('addToReview', { prescription_id: this.form.chart_payload.prescription_id, status: 'continue', new: true });
                                            }
                                        })
                                    }).catch(err => {
                                        this.loading = false;
                                        this.error_message = err.response.data.message ? err.response.data.message : err.response.data;
                                        this.displayMessage2 = err.response.data.errors ? err.response.data.errors : err;
                                        this.displayMessage = 'Error: ' + this.error_message + ', '+ err;
                                        console.log('HITTING CREATE ERROR', this.error_message);
                                        this.signature = false;

                                        this.displaySuccessModal = false;
                                        this.displayErrorModal = true;
                                        this.errorTitle = err.response.data.title ? err.response.data.title : "Form Error";
                                        this.errorMessage = err.response.data.message ? err.response.data.message : err.response.data;
                                    })
                                    console.log('this is the prescrption/chart form', this.form, this.patientId);
                                })
                            }).catch(err => {
                                this.loading = false;
                                this.error_message = err.response.data.message ? err.response.data.message : err.response.data;
                                this.displayMessage2 = err.response.data.errors ? err.response.data.errors : err;
                                this.displayMessage = 'Error: ' + this.error_message + ', '+ err;
                                console.log('HITTING CREATE ERROR', this.error_message);
                                this.signature = false;
                            })
                            
                        } else {
                            this.$store.dispatch('getRefreshToken');
                            await axios.post(this.endpoint, newPayload, this.$store.state.header).then(res => {
                                console.log('this is the res', res);
                                console.log('this is the payload variable', newPayload);
                                this.form.chart_payload.prescription_id = res.data;
                                if(this.generateCode){
                                    axios.post('/apn/create', {prescription_id: res.data, prescriber: JSON.parse(localStorage.getItem('user_id'))}, this.$store.state.header)
                                    .then(response=>{
                                        this.hasAuthCode = true;
                                        this.form.prescription_payload.authority_prescription_number = response.data.authority_prescription_number;
                                    })
                                    .catch(error => {
                                        // Handle error
                                        this.displayPBSAlertModal = false
                                        this.loading = false
                                        this.error_message = error.response.data.message ? error.response.data.message : error.response.data;
                                        this.displayMessage2 = error.response.data.errors
                                        this.displayMessage = 'Error: ' + this.error_message
                                        console.log('HITTING CREATE ERROR', this.error_message)
                                    })
                                }else if(this.createStreamlinedApn){
                                    axios.post('/dispensing-apn', {prescription_id: res.data}, this.$store.state.header).then(res5=>{
                                        console.log('this is the dispensing apn', res5.data.number);
                                        this.form.prescription_payload.authority_prescription_number = res5.data.number;
                                    });
                                    this.createStreamlinedApn = false;
                                }

                                this.form.chart_payload.chart_form.route = newPayload.route;
                                this.form.chart_payload.chart_form.type = this.selectedChart.chart_name;
                                if (this.selectedDrug.tags) {
                                    this.form.chart_payload.chart_form.tags = [];
                                    this.selectedDrug.tags.forEach(tag => {
                                        this.form.chart_payload.chart_form.tags.push(tag.toLowerCase())
                                    })
                                }
                                console.log('this is the chart payload', this.form.chart_payload);
                            }).then(() => {
                                this.$store.dispatch('getRefreshToken');
                                console.log('the create chart endpoint is being processed now');
                                axios.post('/chart', this.form.chart_payload, this.$store.state.header).then(res2 => {
                                    
                                    
                                    console.log('submitted chart create, this is res', res2);
                                    this.$store.dispatch('getRefreshToken');
                                    this.loading = false;
                                    this.getFirstAdminRoundTime();
                                    this.displayMessage = 'Your prescription and chart have been successfully created.';
                                    if(this.selected_administration_options.length){
                                        let tags = []
                                        this.selected_administration_options.forEach(item=>{
                                            tags.push(item.value)
                                        })
                                        if (Object.keys(this.selected_packing).length != 0) {
                                            tags.push(this.selected_packing.value.toLowerCase())
                                        }
                                        if(tags.length){
                                            axios.post(`/chart/${res2.data.uuid}/add-tags`,{tags: tags},this.$store.state.header).then(res3=>{
                                                console.log('added tags to chart', res3.data);
                                            })
                                        }
                                    }
                                    if(this.generateCode){
                                        this.displaySuccessModal = false;
                                    }
                                    // UNCOMMENT WHEN REINTRODUCING IHI CHECKING
                                    // if (sign) {
                                    //     this.preChartCheck();
                                    // }
                                    if(!sign && !this.activePresChart && !this.hasAuthCode){
                                        this.clearForm();
                                        this.clearCustomMedForm();
                                    }
                                    if (sign && !this.chartReview) {

                                        this.prescription_sign_payload.prescription_id = this.form.chart_payload.prescription_id;
                                        if(!this.displayPhoneOrderModal){
                                            this.displayMessage2 = `Click the ${this.scheduledMedicine ? 'Sign' : 'Complete'} button to sign off on prescription.`;
                                        }
                                        this.signature = true;
                                    }
                                    // DISMISS EXPIRED PRESCRIPTION AFTER RX AND CHART RENEWAL
                                    axios.post('/dismiss-expired-prescriptions', {prescription_ids: [this.activePresChart.chart_payload.prescription_id]}, this.$store.state.header).then(resD=>{
                                        console.log('dismissed all expired meds', resD);
                                        
                                    })
                                    if (this.chartReview) {
                                        this.displayMessage2 = 'Prescription has been added to the list of prescriptions that will be signed off at the end of the chart review.';
                                        this.$emit('addToReview', { prescription_id: this.form.chart_payload.prescription_id, status: 'edited' });
                                    }
                                    this.drugSearchFilter = 1
                                })
                            }).catch(err => {
                                this.loading = false;
                                this.error_message = err.response.data.message ? err.response.data.message : err.response.data;
                                this.displayMessage2 = err.response.data.errors ? err.response.data.errors : err;
                                this.displayMessage = 'Error: ' + this.error_message + ', '+ err;
                                console.log('HITTING CREATE ERROR', this.error_message);
                                this.signature = false;

                                this.displaySuccessModal = false;
                                this.displayErrorModal = true;
                                this.errorTitle = err.response.data.title ? err.response.data.title : "Form Error";
                                this.errorMessage = err.response.data.message ? err.response.data.message : err.response.data;
                            })
                            console.log('this is the prescrption/chart form', this.form, this.patientId);
                            // this.clearForm();
                        }
                        
                        this.$emit('prescriptionPayload',  this.form.prescription_payload);
                        
                        // FUNCTION END
                        // FUNCTION END
                        // FUNCTION END

                    }
                    this.cancelAction = null;

                }

            }, 1000)
            timer = 1;
            this.displayMessage = '';

        },
        preChartCheck() {
            this.$store.dispatch('getRefreshToken');
            this.displayMessage = 'Validating IHI...';
            console.log('doing pre chart check')
            let ihiDetails = '';
            // CHECKING PATIENT IHI NUMBER AND PRESCRIBERS HPI-I NUMBER
            this.$store.dispatch('getRefreshToken');
            axios.post(`/person/${this.patientId}/ihi`, {}, this.$store.state.header).then(res => {
                ihiDetails = res.data;
                // if(res.data.conflicted){
                //     this.displayIHIConflictModal = true;
                // }
                let payload = {
                    type: 'ihi',
                    number: ihiDetails.number ? ihiDetails.number : '',
                    patient_id: this.patientId
                }
                axios.post(`/validate-ihi`, payload, this.$store.state.header).then(res => {
                    console.log('this is the response for validate IHI', res.data);
                    this.validIHI();
                }).catch(err => {
                    console.log('/validate-ihi failed with the following error:', err, err.response);
                    if (err.response && err.response.data && err.response.data.error && err.response.data.error.includes("duplicate")) {
                        console.log('Resolved and different IHI number returned', err.response.data.data);
                        payload = {
                            type: 'ihi',
                            number: err.response.data.data.ihi_number,
                            patient_id: this.patientId
                        }
                        axios.post('/validate-ihi', payload, this.$store.state.header).then(response => {
                            console.log("This is the response for the second validate IHI", response.data);
                            this.validIHI();
                        }).catch(err => {
                            this.invalidIHI(err);
                        })
                    }
                    this.invalidIHI(err);
                })
            }).catch(err => {
                console.log("This is the response for /ihi", err.response, err.response.data);
                if (err && err.response && err.response.data) {
                    this.displayMessage = err.response.data;
                    this.displayMessage2 = 'A draft chart has been created - please close this page and return to the resident profile to review.'
                }
            })
        },
        validIHI() {
            this.$store.dispatch('getRefreshToken');
            this.displayMessage = 'IHI validated. Your prescription and chart have been successfully created.';
            if (!this.chartReview) {
                this.signature = true;
            }
        },
        invalidIHI(err) {
            this.$store.dispatch('getRefreshToken');
            this.errorMessage = err.response.data.error;
            if (this.errorMessage.includes('failure')) {
                this.displayMessage = '';
            }
            this.errorTitle = "Form Error";
            this.displayErrorModal = true;
            if (!this.chartReview) {
                this.signature = true;
            }
        },
        insertNewTimeField() {
            this.$store.dispatch('getRefreshToken');
            //  if(this.selectedChart.inputs.includes('frequency') && this.form.prescription_payload.frequency && ( this.form.prescription_payload.frequency !='BD' && this.form.prescription_payload.frequency !='TDS' && this.form.prescription_payload.frequency !='QiD')){
            let numOfTimeInputs = 0;
            for (let key in this.formTimes) {
                console.log('this is a key', key);
                numOfTimeInputs++;
            }
            this.formTimes[`time${numOfTimeInputs + 1}`] = '';
            this.readHeightMeasurer();
            //  }

            // this.updateFrequencyBasedOnTimeFields();


        },
        getFrequencies() {
            this.$store.dispatch('getRefreshToken');
            axios.post('/prescription/frequencies', {facility_id: this.patient.physical_location.location.facility.uuid}, this.$store.state.header).then(res => {
                console.log('THESE ARE THE PRESCRIPTION FREQUENCIES', res.data);
                this.frequencyTypes = res.data;
                // this.frequencyTypes.push({
                //     frequency_name: "Variable",
                //     interval: 1,
                //     period: 'variable',
                //     times: ['0600', '0800', '1000', '1200', '1700', '1800', '2000', '2200'],
                //     days_of_week: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
                // })
                // this.frequencyTypes.push({
                //     frequency_name: "Custom",
                //     interval: 1,
                //     period: 'weekly',
                //     times: ['0600', '0800', '1000', '1200', '1700', '1800', '2000', '2200'],
                //     days_of_week: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
                // })
            })
        },
        updateForm(newData) {
            this.$store.dispatch('getRefreshToken');
            if (this.new) {
                this.form = newData;
                this.form.prescription_payload.patient_id = this.patientId ? this.patientId : this.$route.params.id;
                this.form.prescription_payload.reason = this.form.prescription_payload.reason ? this.form.prescription_payload.reason : '';
                this.form.prescription_payload.drug_uuid = this.draftDrug.alias_id;

                console.log('THIS IS THE FORM', this.form, newData);
                this.start_date = this.form.prescription_payload.start_date;
                this.stop_date = this.form.prescription_payload.stop_date;

                this.formTimes = {};

                let chartTypes = this.$store.getters.getChartTypes;
                console.log('update form has been hit', this.form, this.draftDrug);
                chartTypes.forEach(chartType => {
                    if (chartType.chart_name == this.form.chart_payload.chart_form.type) {
                        this.selectedChart = chartType;
                    }
                })

                this.selectedDrug = this.draftDrug;
                this.selectedDrug.full_name = `(${this.selectedDrug.generic_name}) ${this.selectedDrug.name}`
                console.log('THIS ITHE NEW DATA', newData.chart_payload.chart_form.tags);
                if (newData.chart_payload.chart_form.tags.length) {
                    if (newData.chart_payload.chart_form.tags.includes('packed')) {
                        console.log('this contains packed');
                        this.packed = 'packed';
                    }
                    if (newData.chart_payload.chart_form.tags.includes('non-packed')) {
                        console.log('this contains non-packed');
                        this.packed = 'non-packed';
                    }
                    if (newData.chart_payload.chart_form.tags.includes('single')) {
                        console.log('this contains single');
                        this.single_multi = 'single';
                    }
                    if (newData.chart_payload.chart_form.tags.includes('multi')) {
                        console.log('this contains multi');
                        this.single_multi = 'multi';
                    }
                    if (newData.chart_payload.chart_form.tags.includes('now')) {
                        console.log('this contains now');
                        this.now_or_next_pack = 'now';
                    }
                    if (newData.chart_payload.chart_form.tags.includes('next_pack')) {
                        console.log('this contains next_pack');
                        this.now_or_next_pack = 'next_pack';
                    }
                }

                let numOfTimes = this.form.prescription_payload.times.length;
                let times = [];
                this.form.prescription_payload.times.forEach(time => {
                    time = time.split('');
                    time = `${time[0]}${time[1]}:${time[2]}${time[3]}`;
                    times.push(time);
                })

                let num = 1;
                setTimeout(() => {
                    console.log('this is the payload times', times)
                    while (numOfTimes > 0) {
                        console.log('numof times', num);
                        this.formTimes[`time${num}`] = times.shift();
                        if (num > 4) {
                            console.log('ITS GREATER THAN 4', num);
                        }
                        numOfTimes -= 1;
                        num += 1;
                    }
                }, 500)
            }
        },
        unique(array, propertyName) {
            return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
        },
        renewChart(arg){
            console.log('triggerRenew - calling the child trigger renew', this.renewExpired);
            return arg
        }
        
    },
    computed: {
        frequencies() {
            return this.frequencyOptions ? this.frequencyOptions : this.frequencyTypes
        },
        formattedDrugList() {
            let formattedDrugList = [...this.drugList];
            formattedDrugList.forEach(drug => {
                drug.generic_name = drug.generic_name.charAt(0).toUpperCase() + drug.generic_name.slice(1);
                drug.name = drug.name.charAt(0).toUpperCase() + drug.name.slice(1);
                drug.full_name = "(" + drug.generic_name + ") " + drug.name;
            })
            return formattedDrugList;
        },
        
    },
    watch: {
        emptyDrugList() {
            console.log('EMPTYING DRUG LIST, BLUR');
            this.drugList = []
        },
        // WATCH MIGHT BE INEFFICIENT, MAY NEED A NEW SOLUTION, ALSO UNDEFINED COUNTS AS NEW DATA FOR SOME REASON SO HAD TO FILTER THAT OUT IN 972
        activePresChart(newData, oldData) {
            if (newData != oldData && newData != undefined && this.displayDropDown) {
                this.updateForm(newData);
                console.log('change has taken place', newData, oldData);
            }
        },
        checkHeight() {
            this.readHeightMeasurer();
        },
        renewExpired(newData, oldData){
            if(newData != oldData)
            return this.createChart(true, true);
        }
        // formTimes(newData, oldData){
        //     if(newData != oldData){
        //         console.log('form times have been updated');
        //         this.updateFrequencyBasedOnTimeFields();
        //     }
        // }
    },
    mounted() {
        this.$store.dispatch('getRefreshToken');
        if (!this.displayDropDown) {
            let frequency = {
                frequency_name: '',
                interval: this.activePresChart.prescription_payload.interval,
                period: this.activePresChart.prescription_payload.frequency,
                days_of_week: [],
                times: []
            }
            this.schedule_quantity = [];
            this.updateForm(this.activePresChart ? this.activePresChart : '');
            // IF WEEKLY SCHEDULE
            if (this.activePresChart.prescription_payload.dose_times.length
                && (this.activePresChart.prescription_payload.dose_times[0].day
                    || this.activePresChart.prescription_payload.dose_times[0].date)) {
                this.weekly_schedule.forEach(day => {
                    this.activePresChart.prescription_payload.dose_times.forEach(item => {
                        if (item.day == day.day) {
                            frequency.days_of_week.push(item.day);
                            day.times = item.times;
                            item.times.forEach(time => {
                                this.schedule_quantity.push(time);
                            })
                        }
                    })
                })
            } else {
                // IF DAILY SCHEDULE
                if (this.activePresChart.prescription_payload.dose_times.length) {
                    this.schedule_quantity = this.activePresChart.prescription_payload.dose_times[0].times;
                }
            }
            setTimeout(() => {
                let sched = this.unique(this.schedule_quantity, 'time');
                console.log('getting unique values now', sched)
                this.schedule_quantity = sched;
                this.schedule_quantity.forEach(time => {
                    frequency.times.push(time.time);
                })
                console.log('this is the custom frequency created', frequency);
                // if(frequency.period == 'weekly'){
                //     this.populateWeeklySchedule()
                // }

            }, 300)

        } else {
            this.getFrequencies();
        }
        if (this.activeSelectedChart) {
            this.selectedChart = this.activeSelectedChart;
        }

        console.log("this is the active prescription chart:" + JSON.stringify(this.activePresChart));
        if (this.activePresChart && this.activePresChart.prescription_payload && this.activePresChart.prescription_payload.start_date){
            this.scheduledMedicine = this.activePresChart.prescription_payload.scheduled; 
            this.form.prescription_payload.start_date = this.dashboardRenew ? new Date().toLocaleString("en-au", { timeZone: "Australia/Sydney" }).slice(0,10) : this.activePresChart.prescription_payload.start_date;
            this.form.prescription_payload.stop_date = this.dashboardRenew ? '' : this.activePresChart.prescription_payload.stop_date;
            
            this.start_date = this.dashboardRenew ? new Date().toLocaleString("en-au", { timeZone: "Australia/Sydney" }).slice(0,10) : this.activePresChart.prescription_payload.start_date;
            this.stop_date = this.dashboardRenew || this.expired ? '' : this.activePresChart.prescription_payload.stop_date;
            this.date_added = this.dashboardRenew ? new Date().toLocaleString("en-au", { timeZone: "Australia/Sydney" }).slice(0,10) : this.activePresChart.prescription_payload.created_date;
            this.form.prescription_payload.route = this.activePresChart.prescription_payload.route;
            this.form.prescription_payload.dose_times = this.activePresChart.prescription_payload.dose_times;
            this.form.prescription_payload.dose = this.activePresChart.prescription_payload.dose;
            this.form.prescription_payload.aslr_consent = this.activePresChart.prescription_payload.aslr_consent ? this.activePresChart.prescription_payload.aslr_consent  : false;
            this.form.prescription_payload.paper_prescription_number = this.activePresChart.prescription_payload.paper_prescription_number ? this.activePresChart.prescription_payload.paper_prescription_number : this.activePresChart.prescription_payload.is_paper ? '1' : '';
            this.form.prescription_payload.is_paper = this.activePresChart.prescription_payload.is_paper ? true : false;
            this.form.prescription_payload.dose_unit = this.activePresChart.prescription_payload.dose_unit;
            this.form.prescription_payload.max_dose = this.activePresChart.prescription_payload.max_dose;
            this.form.prescription_payload.pbs_manufacturer_code = this.activePresChart.prescription_payload.pbs_manufacturer_code ? this.activePresChart.prescription_payload.pbs_manufacturer_code : '';
            this.form.prescription_payload.max_admin_dose = this.activePresChart.prescription_payload.max_admin_dose;
            this.form.prescription_payload.substitution_not_permitted = this.activePresChart.prescription_payload.substitution_not_permitted ? true : false;
            this.form.prescription_payload.max_times_per_day = this.activePresChart.prescription_payload.max_daily_admin;
            this.last_administration = this.activePresChart.prescription_payload.last_administration ? this.activePresChart.prescription_payload.last_administration : [];

            this.form.prescription_payload.interval = parseInt(this.activePresChart.prescription_payload.interval);
            this.form.prescription_payload.repeat_interval = parseInt(this.activePresChart.prescription_payload.repeat_interval);
            this.form.prescription_payload.num_repeats_authorised = parseInt(this.activePresChart.prescription_payload.num_repeats_authorised);
            this.form.prescription_payload.prescribed_quantity = this.activePresChart.prescription_payload.prescribed_quantity;
            this.form.prescription_payload.notes = this.activePresChart.prescription_payload.notes;
            this.form.prescription_payload.pbs_authority_code = this.activePresChart.prescription_payload.pbs_authority_code;
            this.form.prescription_payload.authority_prescription_number = this.activePresChart.prescription_payload.authority_prescription_number ? this.activePresChart.prescription_payload.authority_prescription_number : '';
            this.form.prescription_payload.pbs_code = this.activePresChart.prescription_payload.pbs_code ? this.activePresChart.prescription_payload.pbs_code : '';
            this.form.prescription_payload.pbs_type = this.activePresChart.prescription_payload.pbs_type ?? '';
            this.doseTypes.push(this.form.prescription_payload.dose_unit);
            this.prescriptionType = this.activePresChart?.prescription_payload?.pbs_code ? 'pbs/rpbs' : 'private';
            
            console.log('this is the else - stat frequency before outside');
            this.form.prescription_payload.frequency = this.statFrequency[0];
            
            console.log('this is the else - stat frequency after', this.form.prescription_payload.frequency, this.statFrequency[0])

            if (this.activePresChart.prescription_payload.frequency_shorthand == 'Stat') {
                this.statDoseTime = this.activePresChart.prescription_payload.dose_times;
                this.form.prescription_payload.frequency_shorthand = this.statFrequency[0].frequency_name;
            }

            if (this.activePresChart.prescription_payload.chart_tags && this.activePresChart.prescription_payload.chart_tags.length) {
                // radio button tags
                this.activePresChart.prescription_payload.chart_tags.forEach(tag=>{
                    this.administration_options.forEach(option=>{
                        if(option.value.toLowerCase() == tag.name.toLowerCase()){
                            this.selected_administration_options.push(option)
                        }
                    })
                })
                // dropdown packed/non-pack tag
                this.activePresChart.prescription_payload.chart_tags.forEach(tag=>{
                    this.packing_options.forEach(option=>{
                        if(option.value.toLowerCase() == tag.name.toLowerCase()){
                            this.selected_packing = {label: option.label, value: option.value}
                        }
                    })
                })
            }
            
            axios.post('/get-pbs', { alias_id: this.activePresChart.prescription_payload.drug_uuid }, this.$store.state.header).then(res => {
                console.log('THESE ARE THE PBS DETAILS');
                this.loading_pbs = false;
                this.pbs_codes = res.data;
                this.flattenPBSCodes();
            })

            if (this.activePresChart.prescription_payload.pbs_code) {
                this.pbsFilter = 'pbs';
            }

            if (this.activePresChart.prescription_payload.pbs_code && this.activePresChart.prescription_payload.pbs_authority_code && !this.activePresChart.prescription_payload.authority_prescription_number) {
                axios.post('/dispensing-apn', {prescription_id: this.activePresChart.chart_payload.prescription_id}, this.$store.state.header).then(res5=>{
                    console.log('this is the dispensing apn', res5.data.number);
                    this.dispensing_apn = res5.data.number;

                })
            }
            // this.form.prescription_payload.frequency = this.activePresChart.prescription_payload.frequency;
            console.log('this is the frequency options and shortname', this.frequencyOptions.length, this.activePresChart.prescription_payload, this.activePresChart.prescription_payload.frequency_shorthand);
            if (this.frequencyOptions.length && this.activePresChart.prescription_payload.frequency_shorthand) {
                // this.form.prescription_payload.frequency = this.activePresChart.prescription_payload.frequency_shorthand;
                if (this.activePresChart.chart_payload.chart_form.type != 'Stat') {
                    this.frequencyOptions.forEach(option => {
                        if (option.frequency_name.toLowerCase() == this.activePresChart.prescription_payload.frequency_shorthand.toLowerCase()) {
                            this.form.prescription_payload.frequency = option;
                            this.form.prescription_payload.frequency_shorthand = this.activePresChart.prescription_payload.frequency_shorthand;
                            console.log('matched frequency name and shortname');
                        } else {
                            console.log('no match on frequency shortname and name');
                        }
                    })
                    if (this.activePresChart.chart_payload.chart_form.type == 'insulin' || this.activePresChart.chart_payload.chart_form.type == 'prn') {
                        this.displayPRNSliding = true;
                        if (this.activePresChart.prescription_payload.sliding_scale && this.activePresChart.prescription_payload.sliding_scale.length) {
                            this.sliding = true;
                            this.form.prescription_payload.sliding_scale = this.activePresChart.prescription_payload.sliding_scale;
                        }
                    }
                } else {
                    console.log('this is the else - stat frequency before');
                    this.form.prescription_payload.frequency = this.statFrequency[0];
                    console.log('this is the else - stat frequency after', this.form.prescription_payload.frequency, this.statFrequency[0]);
                }
            }
        }

        if (this.activePresChart && this.activePresChart.chart_payload && this.activePresChart.chart_payload.chart_form && this.activePresChart.chart_payload.chart_form.indication) {
            this.form.prescription_payload.indication = this.activePresChart.chart_payload.chart_form.indication.text;
        }

        this.formTimes = {
            time1: '',
        }

        this.searchedDrugName = '';
        this.prescription_sign_payload.prescription_id = this.form.chart_payload.prescription_id
        console.log('this is the active pres chart', this.displayDropDown);
        // console.log('this is the height', this.$refs['height-measurer' + this.parentIndex].clientHeight);
        console.log('IS IN THE REVIEW', this.reviewed);
        console.log('THIS IS THE FORM BEING MOUNTED', this.form)
        this.addedToReview = this.reviewed;
        this.getSunday();

        switch (JSON.parse(localStorage.getItem('currentLocation')).state) {
            case 'WA':
                this.authorisationReferenceNumberLabel = 'Authority Number';
                break;
            case 'TAS':
                this.authorisationReferenceNumberLabel = 'Authority Number';
                break;
            case 'NSW':
                this.authorisationReferenceNumberLabel = 'Authorisation Number';
                break;
            case 'NT':
                this.authorisationReferenceNumberLabel = 'Authorisation Number';
                break;
            case 'ACT':
                this.authorisationReferenceNumberLabel = 'Approval Number';
                break;
            case 'QLD':
                this.authorisationReferenceNumberLabel = 'Approval Number';
                break;
            case 'VIC':
                this.authorisationReferenceNumberLabel = 'Warrant Number';
                break;
            case 'SA':
                this.authorisationReferenceNumberLabel = 'Permit Number';
                break;
        }

        if(this.renewExpired){
            this.createChart(true, true);
        }

        // this.$parent.$on('triggerRenew', () => {
        //     console.log('Child received event from parent');
        // });
    }
}
</script>


<style lang="scss">
@import "../assets/css/main.scss";

.prescriber-options-container{
  display: flex;
  margin-top: 10px !important;
//   padding: 0 10px ;
}

.first-chart-row{
    margin-top: 10px;
}

.prescription-chart {
    .chart-row-item {
        border: none;
        flex-wrap: wrap;
        justify-items: flex-start;
        min-width: 697px;
        align-items: flex-end;
        input {
            border: 1px solid #BDC4C4;
            background-color: white;
            // width: 14em;
            // min-width: 140px;
            width: 160px;
        }

        .p-dropdown,
        .p-multiselect {
            margin-left: 10px;
            width: 160px;
            text-align: left;
        }
        p{
            font-size: 1em; 
            font-weight: 500;
        }
    }

    .toggle-switch {
        padding: 0px;
    }
}

.p-dropdown-label {
    padding: 5px;
}

.p-dropdown,
.p-multiselect {
    padding: 0px !important;
}

#reason-input-container {
    .p-inputtext {
        width: 25em;
    }
}

.active-selected-chart {

    .p-dropdown-label,
    .p-dropdown-trigger {
        color: white !important;
        font-weight: bold;
    }
}

.dynamic-number-input {
    .p-inputnumber-input {
        min-width: 50px !important;
        width: 50px;
    }
}

.expansionFormat {
    .chart-form-select {
        background-color: transparent !important;
    }

    display: grid !important;
    grid-template-columns: 1fr 1fr;

    input {
        background-color: white !important;
    }
}

.toggle-switch-item {
    margin-top: 0 !important;
}

.schedule-table {
    // border: 1px solid grey;
    border-collapse: collapse;
    border-radius: 4px;
    padding: 0;

    th,
    td {
        text-align: center;
        font-size: 0.9em;
        border-right: 1px solid #BDC4C4;
    }

    input {
        min-width: 50px !important;
        width: 60px !important;
        margin: 0 6px !important;
    }

    th:nth-last-child(1) {
        border-right: none !important;
    }

    td:nth-last-child(1) {
        border-right: none !important;
    }

}

.schedule-input {
    .p-inputnumber-input {
        background-color: white;
    }
}

.blue-input {
    background-color: $strongblue !important;
    color: white !important;
    font-weight: bold !important;
    text-align: center;
}

.administration-select-buttons {
    display: flex;
    border: none !important;

    .p-button.p-component {
        margin-right: 8px !important;
        border: 1px solid #BDC4C4 !important;
        border-right: 1px solid #BDC4C4 !important;
        border-radius: 7px !important;
        padding: 6px !important;
        width: 25%;
        height: 38px !important;
    }
    .p-button.p-component:last-child{
        margin-right: 0 !important;
    }

}

.drug-image {
    padding-left: 15px;

    img {
        max-height: 100px;
        max-width: 120px;
        width: auto;
        height: auto;
    }
}

.p-buttonset {
    border: 1px solid #BDC4C4;
    border-radius: 6px;

    .p-button.p-component {
        border: none;
    }

    .p-highlight {
        background-color: #0870DC !important;
        font-weight: bold !important;
        border-radius: 5px !important;
    }
}

.p-togglebutton {
    border: 1px solid #BDC4C4 !important;
    border-right: 1px solid #BDC4C4 !important;
    border-radius: 7px !important;
    padding: 10px !important;
    width: fit-content;
    min-width: 190px !important;
    height: 38px !important;
}

.p-togglebutton.p-button.p-highlight {
    background-color: #0870DC !important;
    font-weight: bold !important;
}

.p-component.p-disabled{
    opacity: 1 !important;
}

#selectchart{
    width: 698px;
}

.sign-off-item {
    h4 {
        margin-bottom: 0;
        text-align: left;
    }
    button {
        width: 100%;
    }
}
.approval-container {
    display: flex;
    flex-direction: column;
    margin-left: 3em;
    p{
        font-size: 1em !important; 
        font-weight: 500 !important
    }
    h2 {
        margin-bottom: 0;
    }
}
.chart-approval {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    width: 707px;
}
.valid-prescription-notes {
    margin-top: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 12px;
    h4 {
        margin-top: 0;
    }
}
.prescriber-options-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    // margin: 10px;
    justify-content: space-between;
    width: 697px;
}
.prescriber-options-item {
    border: 2px solid $strongblue;
    width: fit-content;
    margin: 0;
    p {
        width: fit-content !important;
        margin: 0;
        border-radius: 6px;
        padding: 5px 25px;
        font-weight: bold;
        background-color: white;
    }
}
.chart-form-select {
    background-color: white !important;
    border: none !important;
    width: 90%;
    min-width: 10em;
}
.toggle-switch {
    // padding: 5px 10px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    align-self: center;
    margin: 5px;
}
.toggle-switch-item {
    font-weight: bold;
    width: 115px;
    margin: 0;
    padding: 5px;
    cursor: pointer;
}
.chart-form-container {
    h4 {
        color: white;
        font-size: 1.3em;
        margin: 0;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 10px 25px;
        height: 43px;
    }
    border-radius: 12px;
    min-width: 707px;
}
.first-chart-row {
    height: 107px;
}
.chart-row {
    display: flex;
    width: 100%;
}
.first-chart-row-item {
    border-top: none !important;
    display: flex;
    align-items: center !important;
    input::placeholder {
        color: black !important;
        font-weight: normal !important;
    }
    input {
        width: 100%;
    }
}
.chart-row-item {
    border-right: 1px solid $bordergrey;
    border-top: 1px solid $bordergrey;
    display: flex;
    width: 100%;
    flex: 1;
    min-height: 60px;
    align-items: flex-end;
    // justify-content: center;
    input {
        min-width: 150px;
        background-color: white;
        margin: 0 10px;
    }
    input::placeholder {
        color: #101820;
        font-weight: 300;
    }
}
.chart-row-item:last-child {
    border-right: none !important;
}
.chart-form {
    background-color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.create-chart-container {
    margin-top: 4em;
}
.chart-form-approval-container {
    display: flex;
}
.charts-menu-container {
    display: flex;
    flex-wrap: wrap;
}
.charts-menu-item {
    color: white;
    width: fit-content;
    min-width: 255px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin: 10px;
    height: 50px;
    padding: 5px 35px;
    border-radius: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    h4,
    p {
        margin: 0;
    }
    h4 {
        font-size: 0.9em;
    }


}

div.p-dropdown-items-wrapper:has(#selectchart_list) {
    max-height: 700px !important;
}

</style>
